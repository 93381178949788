<template>
  <section class="log-in-fields">
    <header v-if="showHeader" class="flex-row">
      <h5>Log in to continue</h5>
      <forgot-password-button :email="email.$model" />
    </header>
    <email-field
      :email="email"
      :placeholder="emailPlaceholder"
      :disabled="disabled"
      @change="emitChange"
    />
    <alert-section v-if="showPasswordError" class="row"
      >An account already exists with that email. Please enter your password to
      complete your purchase.</alert-section
    >
    <password-field
      :password="password"
      :disabled="disabled"
      @change="emitChange"
    />
    <p>
      Don't have an account?
      <a @click="onSignUp">Sign up</a>
    </p>
  </section>
</template>
<script>
import ForgotPasswordButton from "@/components/ForgotPasswordButton";
import EmailField from "@/components/EmailField";
import PasswordField from "@/components/PasswordField";
import AlertSection from "@/components/AlertSection";

export default {
  name: "LogInFields",
  components: { ForgotPasswordButton, AlertSection, EmailField, PasswordField },
  props: {
    email: { type: Object, default: () => {} },
    emailPlaceholder: { type: String, default: "Email address" },
    password: { type: Object, default: () => {} },
    showPasswordError: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    showHeader: { type: Boolean, default: true },
  },
  methods: {
    onSignUp() {
      this.$emit("switchToSignUp");
    },
    emitChange(field, value) {
      this.$emit("change", field, value);
    },
  },
};
</script>
<style lang="scss" scoped>
.log-in-fields {
  header {
    justify-content: space-between;

    * {
      align-self: flex-start;
    }
  }
}
</style>
