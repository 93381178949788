var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'bordered-page': true,
    'has-promo': _vm.promoBannerShown,
    'flex-column': true,
  }},[(_vm.promoBannerShown)?_c('promo-code-banner',{staticClass:"promo",on:{"hide":_vm.hidePromo}}):_vm._e(),_c('light-nav-bar',{staticClass:"nav",attrs:{"show-back":_vm.showBack,"show-skip":_vm.showSkip},on:{"skip":_vm.skipHandler}}),(_vm.showProgress)?_c('quiz-progress-bar',{attrs:{"thin":true,"percent-complete":_vm.progressPercent}}):_vm._e(),_c('section',{class:{
      'inner-block': _vm.useInnerBlock,
      'flex-column': !_vm.useInnerBlock,
    }},[_c('router-view',{staticClass:"flex-column"})],1),_c('portal-target',{staticClass:"sticky-footer",attrs:{"name":"sticky-footer"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }