<template>
  <section class="payment checkout">
    <div class="content inner-block">
      <header class="main-header">
        <offer-badge />
        <h4>Start your 7-day free trial</h4>
        <h5>No commitment - cancel any time.</h5>
      </header>
      <payment-promo-invoice class="invoice" :showPromo="true" :plan="plan" />
      <alert-section v-show="error" ref="alertSection" class="alert">{{
        error
      }}</alert-section>
      <form class="login" v-if="requirePassword">
        <header class="flex-row">
          <h5>Log in to continue</h5>
          <forgot-password-button class="forgot-password" :email="email" />
        </header>
        <email-field
          :email="$v.email"
          placeholder="Email address"
          :disabled="isLoading"
          @change="modelFieldUpdated"
        />
        <password-field
          class="password"
          :password="$v.password"
          placeholder="Password"
          :disabled="isLoading"
          @change="modelFieldUpdated"
        />
      </form>
      <section class="browser-pay" v-show="showBrowserPay">
        <browser-pay
          :stripe="stripeKey"
          :plan="plan"
          @token="onBrowserPayTokenCreated"
          @payment-loaded="onPaymentLoaded"
        />
      </section>
      <loading-spinner v-if="browserPayLoading" class="spinner" />
      <p v-if="!browserPayLoading && browserPaySupported" class="charge">
        You won't be charged until <b>after your trial</b>
      </p>
      <use-credit-card-button
        v-if="showCreditCardToggle"
        @click="toggleCreditCard"
        class="row"
      />
      <form v-if="showCreditCard" @submit.prevent="submit">
        <section class="information">
          <header v-if="showCreditCardHeader" class="flex-row">
            <h5>Payment information</h5>
          </header>
          <h5 v-if="showCreditCardOptionHeader" class="flex-row pay-with-card">
            Or pay with card
          </h5>
          <credit-card-fields
            ref="creditCardFields"
            :is-loading="isLoading"
            :model="$v.creditCard"
            @change="onCardChange"
            @blur="onCardBlur"
          />
          <email-field
            v-if="
              !requirePassword &&
              (!browserPaySupported ||
                (browserPaySupported && creditCardChosen && !browserPayEvent))
            "
            :email="$v.email"
            placeholder="Email address (for your receipt)"
            :disabled="isLoading"
            @change="modelFieldUpdated"
          />
        </section>
        <quiz-button :solid="true" type="submit" :loading="isLoading"
          >Start free trial</quiz-button
        >
      </form>
      <p v-if="!browserPayLoading && !browserPaySupported" class="charge">
        You won't be charged until <b>after your trial</b>
      </p>
    </div>
  </section>
</template>

<script>
import QuizButton from "@/components/QuizButton";
import BrowserPay from "@/components/BrowserPay";
import LoadingSpinner from "@/components/LoadingSpinner";
import PaymentPromoInvoice from "@/components/PaymentPromoInvoice";
import AlertSection from "@/components/AlertSection";
import CreditCardFields from "@/components/CreditCardFields";
import ForgotPasswordButton from "@/components/ForgotPasswordButton";
import UseCreditCardButton from "@/components/UseCreditCardButton";
import OfferBadge from "@/components/OfferBadge";
import EmailField from "@/components/EmailField";
import PasswordField from "@/components/PasswordField";

import nextRouteMixin from "@/mixins/nextRoute";
import submitPaymentMixin, { GENERIC_FORM_ERROR } from "@/mixins/submitPayment";
import scrollToErrorMixin from "@/mixins/scrollToError";
import { getPlan } from "@/plans";
import { validStripeField } from "@/validators";

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "PaymentView",
  components: {
    QuizButton,
    BrowserPay,
    LoadingSpinner,
    AlertSection,
    CreditCardFields,
    PaymentPromoInvoice,
    ForgotPasswordButton,
    UseCreditCardButton,
    OfferBadge,
    EmailField,
    PasswordField,
  },
  mixins: [
    nextRouteMixin,
    validationMixin,
    submitPaymentMixin,
    scrollToErrorMixin,
  ],
  props: { store: { type: Object, default: () => {} } },
  data() {
    return {
      email: this.$store.state.meta.email || "",
      plan: {
        ...getPlan(),
      },
      requirePassword: false,
    };
  },
  validations() {
    const rules = {};
    if (
      !this.browserPaySupported ||
      (this.browserPaySupported &&
        this.creditCardChosen &&
        !this.usingBrowserPay)
    ) {
      rules.creditCard = {
        cardNumber: { validStripeField },
        cardExpiry: { validStripeField },
        cardCvc: { validStripeField },
      };
      rules.email = { required, email };
    }

    if (this.requirePassword) {
      rules.password = { required };
      rules.email = { required, email };
    }

    return rules;
  },
  mounted() {
    this.$tracker.viewedCheckout();
  },
  methods: {
    submit() {
      return this.requirePassword
        ? this.validateForm(this.startExistingUserPayment)
        : this.validateForm(this.startNewUserPayment);
    },
    onComplete() {
      if (this.requirePassword) {
        this.$store.state.meta.existingLogin = this.email;
      }
      return this.navigateToNextPage();
    },
    onBrowserPayTokenCreated(event) {
      this.browserPayEvent = event;

      if (this.browserPayEvent?.payerEmail && !this.email)
        this.email = this.browserPayEvent.payerEmail;

      if (event.error) {
        throw event.error;
      } else {
        this.usingBrowserPay = true;
        this.$v.$touch();

        if (this.$v.$invalid) {
          this.browserPayEvent.complete("fail");
          this.browserPayEvent = null;
          this.usingBrowserPay = false;
          this.error = GENERIC_FORM_ERROR;
          return;
        }

        this.usingBrowserPay = true;
        this.stripeToken = event.token;
        this.onStripeTokenSet(event, "payment: onBrowserPayTokenCreated");
        return this.requirePassword
          ? this.startExistingUserPayment()
          : this.startNewUserPayment();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/mixins.scss";
.payment {
  min-height: 100%;

  .alert {
    margin: var(--spacing-md) 0 0;
  }

  form {
    .row {
      margin-top: var(--form-spacing);
    }

    input,
    .stripe-element {
      border: 1px solid var(--light-gray);
      border-radius: 3px;
      padding: 20px;
      width: 100%;
    }
  }

  .main-header {
    h4 {
      margin-bottom: 0;
      margin-top: 23px;
    }

    h5 {
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;
      font-size: 14px;
      line-height: 23px;
    }
  }

  .invoice {
    margin-top: 22px;
  }

  .browser-pay {
    margin-top: 25px;
  }

  .charge {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
  }

  h5 {
    text-align: left;
    font-size: 16.5px;
    margin-bottom: 16px;

    &.sub {
      text-align: center;
      font-size: 15.5px;
      font-weight: normal;
      margin-top: 10px;
    }

    &.pay-with-card {
      color: var(--medium-gray);
      font-size: 16px;
      font-weight: normal;
      @include flex-row;
      margin: var(--spacing-sm) 25%;

      &:before,
      &:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid var(--medium-gray);
        margin: auto;
      }

      &:before {
        margin-right: 10px;
      }

      &:after {
        margin-left: 10px;
      }
    }
  }

  form {
    text-align: left;

    &.login {
      padding-bottom: 20px;
      margin-top: 40px;

      header {
        justify-content: space-between;
      }

      h5 {
        margin: 0;
      }
    }

    input,
    .stripe-element,
    select {
      padding: 20px;
      border: 1px solid var(--light-gray);
      width: 100%;
    }

    .quiz-button {
      margin-top: 32px;
      margin-bottom: 0;
    }

    .forgot-password {
      justify-content: flex-end;
      margin-bottom: 5px;
    }
  }

  .password {
    .row {
      margin-top: 3px;
    }
  }

  .information {
    header {
      justify-content: flex-start;
      margin-top: 40px;

      &.bordered {
        border-top: 1px solid var(--light-gray);
        padding-top: 28px;
        margin-top: 32px;
      }

      h5 {
        margin: 0;
        font-weight: bold;
      }

      .button {
        padding-left: 6px;
      }

      > * {
        align-self: center;
      }
    }
  }

  label {
    font-size: 17.5px;
    text-align: left;
  }

  .cc-meta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 33px;

    & > div {
      width: 45%;
    }
  }

  .spinner {
    text-align: center;
    display: block;
    margin: 20px auto;
  }
}

@media only screen and (max-width: $breakpoint) {
  .payment {
    .row {
      margin-top: 16px;
    }

    form {
      flex: 1;
      @include flex-column;

      h4 {
        margin-bottom: 8px;
      }
    }

    .method {
      h4 {
        margin-bottom: 0;
      }

      p {
        margin-top: 8px;
        margin-bottom: 0;
      }
    }

    .cc-meta {
      & > div {
        width: 48%;
        align-self: flex-start;
      }
    }

    .footer {
      margin-bottom: 50px;
    }
  }
}
</style>
