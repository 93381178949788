<template>
  <section class="magic-link-complete checkout">
    <div class="content">
      <h4>You have {{ countdown }} left</h4>
      <p>
        We've sent a magic login link to
        <span class="b">{{ email }}</span
        >. Download Lasting and use the link in your email to log in.
      </p>
      <download-app-buttons class="buttons" />
    </div>
  </section>
</template>

<script>
import DownloadAppButtons from "@/components/DownloadAppButtons";
const MAGIC_LINK_EXP_MIN = 15;
export default {
  name: "MagicLinkSentView",
  components: { DownloadAppButtons },
  data: function () {
    return {
      countdownInterval: null,
      secondsLeft: MAGIC_LINK_EXP_MIN * 60,
      email: this.$user.user.email || "your email address",
    };
  },
  methods: {
    decrementCountdown() {
      this.secondsLeft -= 1;
      if (this.secondsLeft <= 0) {
        window.clearInterval(this.countdownInterval);
        this.countdownInterval = null;
      }
    },
  },
  computed: {
    countdown() {
      const min = Math.floor(this.secondsLeft / 60);
      const sec = this.secondsLeft % 60;
      return `${min}:${sec < 10 ? "0" : ""}${sec}`;
    },
  },
  mounted() {
    this.$store.clearData();
    this.$user.logOut();
    this.countdownInterval = window.setInterval(this.decrementCountdown, 1000);
  },
  unmounted() {
    if (this.countdownInterval) window.clearInterval(this.countdownInterval);
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variables.scss";
.magic-link-complete {
  .content {
    text-align: left;
  }

  .buttons {
    margin-top: 20px;
  }

  p {
    line-height: 25px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: $breakpoint) {
  .magic-link-complete {
    .content {
      padding-top: 25px;
    }

    h4 {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 21px;
    }
  }
}
</style>
