<template>
  <section class="sign-up-fields">
    <h5 v-if="showHeader">Sign up</h5>
    <email-field
      :email="email"
      :placeholder="emailPlaceholder"
      :disabled="disabled"
      @change="emitChange"
    />
    <alert-section v-if="showPasswordError" class="row"
      >An account already exists with that email. Please enter your password to
      complete your purchase.</alert-section
    >
    <password-field
      v-if="showPassword"
      :password="password"
      :disabled="disabled"
      @change="emitChange"
    />
    <p v-if="showLogInLink">
      Already have an account?
      <a @click="onLogIn">Log in</a>
    </p>
  </section>
</template>
<script>
import EmailField from "@/components/EmailField";
import PasswordField from "@/components/PasswordField";
import AlertSection from "@/components/AlertSection";

export default {
  name: "SignUpFields",
  components: { AlertSection, EmailField, PasswordField },
  props: {
    email: { type: Object, default: () => {} },
    emailPlaceholder: { type: String, default: "Email address" },
    showPassword: { type: Boolean, default: true },
    showLogInLink: { type: Boolean, default: true },
    password: { type: Object, default: () => {} },
    showPasswordError: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    showHeader: { type: Boolean, default: true },
  },
  methods: {
    onLogIn() {
      this.$emit("switchToLogIn");
    },
    emitChange(field, value) {
      this.$emit("change", field, value);
    },
  },
};
</script>
<style scoped>
.sign-up-fields {
}
</style>
