export const PLAN_39_99_MONTHLY_NAME = "39.99-monthly";

export const PLAN_39_99_MONTHLY = {
  id: process.env.VUE_APP_STRIPE_39_99_ONE_MONTH_TRIAL,
  price: 39.99,
  monthlyCost: "39.99",
  originalMonthlyCost: "59.99",
  savingsPercentage: 30,
  savings: "Save $20.00 every month",
  note: "*Auto-renewed monthly",
  promoNote: "Auto-renews monthly, cancel anytime.",
  browserPayAmount: 0,
  browserPayDescription: "FOR MY FREE LASTING TRIAL",
  costPerMonthAfterTrial: "Your cost per month after trial",
};

export const PLANS = {
  [PLAN_39_99_MONTHLY_NAME]: PLAN_39_99_MONTHLY,
};

export const DEFAULT_PLAN_NAME = PLAN_39_99_MONTHLY_NAME;

export const DEFAULT_PLAN = PLANS[DEFAULT_PLAN_NAME];

export const getPlan = (name) => {
  const namedPlan = PLANS[name];
  if (namedPlan) {
    return namedPlan;
  }
  return DEFAULT_PLAN;
};

export default PLANS;
