<template>
  <section class="progress-bar">
    <div class="bar">
      <div class="progress" :style="barStyles"></div>
    </div>
    <div class="label">
      <span class="text">{{ label }}</span>
      <span class="percent">{{ percentComplete }}%</span>
    </div>
  </section>
</template>

<script>
const SPEED = {
  MIN_TRANSITION_MS: 125,
  MIN_PERCENTAGE_BUMP: 12,
  MAX_PERCENTAGE_BUMP: 21,
  BUMP_VARIATION_MS: 200,
  DURATION_MS: 2000,
};

export default {
  name: "ProgressBar",
  props: { label: { type: String, default: "" }, active: Boolean },
  data: function () {
    return {
      percentComplete: 0,
      timer: null,
      elapsedDuration: 0,
    };
  },
  computed: {
    barStyles() {
      return {
        width: `${this.percentComplete}%`,
      };
    },
  },
  watch: {
    active() {
      this.startTimeout();
    },
  },
  mounted() {
    this.startTimeout();
  },
  unmounted() {
    if (this.timer) {
      this.clearTimeout();
    }
  },
  methods: {
    startTimeout() {
      if (this.active && !this.timer)
        this.$nextTick(function () {
          this.generateNewTimeout();
        });
    },
    generateNewTimeout() {
      let duration =
        Math.random() * SPEED.BUMP_VARIATION_MS + SPEED.MIN_TRANSITION_MS;
      let percentageBump = Math.max(
        SPEED.MIN_PERCENTAGE_BUMP,
        Math.round(Math.random() * SPEED.MAX_PERCENTAGE_BUMP)
      );

      if (duration + this.elapsedDuration > SPEED.DURATION_MS) {
        duration = SPEED.DURATION_MS - this.elapsedDuration;
        percentageBump = 100 - this.percentComplete;
      }

      if (this.percentComplete + percentageBump >= 100) {
        duration = SPEED.DURATION_MS - this.elapsedDuration;
      }

      this.timer = setTimeout(
        function () {
          this.elapsedDuration += duration;
          this.increment(percentageBump);
        }.bind(this),
        duration
      );
    },
    clearTimeout() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = null;
    },
    increment(percent) {
      this.percentComplete = Math.min(100, this.percentComplete + percent);
      if (this.percentComplete === 100) {
        setTimeout(
          function () {
            this.clearTimeout();
            this.$emit("complete");
          }.bind(this),
          500
        );
      } else {
        this.generateNewTimeout();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/variables";

.progress-bar {
  width: 100%;

  .bar {
    border-radius: 100px;
    width: 100%;
    background-color: var(--light-teal);
    height: 25px;
    overflow: hidden;
  }

  .progress {
    background-color: var(--teal);
    height: 25px;
    border-radius: 100px;
    transition: width 0.5s;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .label {
    font-size: 14px;
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }

  .text {
    align-self: center;
  }

  .percent {
    color: var(--teal);
    font-size: 25px;
    font-weight: bold;
    line-height: 33px;
    padding: 0 0 0 10px;
    text-align: right;
  }
}
@media only screen and (max-width: $breakpoint) {
  .progress-bar {
    .label {
      margin-top: 20px;
    }
  }
}
</style>
