import VueRouter from "vue-router";

import user from "@/user";
import * as store from "@/store";
let initialLoad = true;

const router = new VueRouter({
  props: { store: { type: Object, default: () => {} } },
  routes: [
    {
      path: "*",
      redirect: { name: "workshopPayment" },
    },
    {
      path: "/payment/",
      name: "workshopPayment",
      meta: {
        nextRoute: { name: "workshopAccount" },
        prereq: ["noPayment", "workshopDetails"],
        quiz: false,
        showPromoBanner: false,
        useInnerBlock: false,
        hideBack: true,
      },
      component: () => import("@/pages/workshop/PaymentView.vue"),
    },
    {
      path: "/not-found",
      name: "workshopNotFound",
      meta: {
        quiz: false,
        showPromoBanner: false,
        hideBack: true,
      },
      component: () => import("@/pages/workshop/NotFoundView.vue"),
    },
    {
      path: "/account",
      name: "workshopAccount",
      meta: {
        nextRoute: { name: "workshopBooked" },
        quiz: false,
        showPromoBanner: false,
        hideBack: true,
        useInnerBlock: false,
        prereq: ["loggedInAndPaid", "noExistingLogin", "noPassword"],
      },
      component: () => import("@/pages/workshop/AccountView.vue"),
    },
    {
      path: "/book",
      name: "workshopBooked",
      meta: {
        quiz: false,
        showPromoBanner: false,
        hideBack: true,
        useInnerBlock: false,
        prereq: ["workshopInfo", "loggedInAndPaid"],
      },
      component: () => import("@/pages/workshop/BookingConfirmationView.vue"),
    },
    {
      path: "/error",
      name: "workshopBookingError",
      meta: {
        quiz: false,
        showPromoBanner: false,
        hideBack: true,
        useInnerBlock: true,
        prereq: ["loggedInAndPaid"],
      },
      component: () => import("@/pages/workshop/BookingErrorView.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  let hasNavigated = false;
  if (initialLoad) {
    let queryString = window.location.search;
    // Hack bc the query params are always empty, so manually parse from the URL
    const { ws_event_id, ws_title, ws_start_time, login, state } = Object.keys(
      to.query
    ).length
      ? to.query
      : decodeURI(window.location.search)
          .replace("?", "")
          .split("&")
          .map((param) => param.split("="))
          .reduce((values, [key, value]) => {
            values[key] = value;
            return values;
          }, {});
    store.setMeta({
      queryString,
      workshop: {
        id: ws_event_id ? decodeURIComponent(ws_event_id) : null,
        title: ws_title ? decodeURIComponent(ws_title) : null,
        // Start time will be in seconds, so convert to milliseconds
        startTime: ws_start_time
          ? parseInt(decodeURIComponent(ws_start_time), 10) * 1000 || null
          : null,
      },
    });

    window.history.pushState({}, document.title, window.location.pathname);

    if (state === "signup") {
      store.setMeta({
        email: login ? decodeURIComponent(login) : store.state.meta.email,
        existingLogin: null,
      });
    } else {
      store.setMeta({
        existingLogin: login
          ? decodeURIComponent(login)
          : store.state.meta.existingLogin,
        email: null,
      });
    }
    initialLoad = false;
  }

  if (!hasNavigated && to.meta.prereq) {
    let done;
    for (const req of to.meta.prereq) {
      let { id, title, startTime } = store.state.meta.workshop;
      switch (req) {
        case "noPayment":
          done = !store.state.meta.paymentComplete;
          if (!done) {
            hasNavigated = true;
            next({ name: "workshopBooked" });
          }
          break;
        case "workshopDetails":
          done = title && title.length >= 4;
          done = done && id && id.length;
          done = done && startTime;
          if (!done) {
            hasNavigated = true;
            next({ name: "workshopNotFound" });
          }
          break;
        case "loggedInAndPaid":
          done = user.isLoggedIn();
          if (!done) {
            store.state.meta.paymentComplete = false;
            next({ name: "workshopPayment" });
            return true;
          }
          if (!user.user) {
            user.get().then(() => {
              next();
            });
            return true;
          }
          break;
        case "payment":
          done = store.state.meta.paymentComplete;
          if (!done) {
            next({ name: "workshopPayment" });
            return true;
          }
          break;
        case "noExistingLogin":
          done = store.state.meta.existingLogin;
          if (done) {
            if (!store.state.meta.paymentComplete)
              next({ name: "workshopPayment" });
            else next({ name: "workshopBooked" });
            return true;
          }
          break;
        case "noPassword":
          done = store.state.meta.passwordSet;
          if (done) {
            next({ name: "workshopBooked" });
            return true;
          }
          break;
      }
    }
  }
  if (!hasNavigated) {
    next();
  }
});

export default router;
