<template>
  <section class="user-payment checkout">
    <div class="content inner-block">
      <header class="main-header">
        <offer-badge />
        <h4>Start your 7-day free trial</h4>
        <h5>No commitment - cancel any time.</h5>
      </header>
      <payment-promo-invoice class="invoice" :showPromo="true" :plan="plan" />
      <user-payment-form
        class="payment-form"
        :plan="plan"
        cta="Start free trial"
        :show-why-now="true"
        @payment-complete="goToNextRoute"
      />
      <p class="charge">You won't be charged until <b>after your trial</b></p>
    </div>
  </section>
</template>

<script>
import UserPaymentForm from "@/components/UserPaymentForm";
import PaymentPromoInvoice from "@/components/PaymentPromoInvoice";
import OfferBadge from "@/components/OfferBadge";
import nextRouteMixin from "@/mixins/nextRoute";
import { getPlan } from "@/plans";

export default {
  name: "UserPaymentView",
  components: {
    UserPaymentForm,
    OfferBadge,
    PaymentPromoInvoice,
  },
  mixins: [nextRouteMixin],
  props: { store: { type: Object, default: () => {} } },
  data() {
    return {
      plan: {
        ...getPlan(),
      },
    };
  },
  mounted() {
    this.$tracker.viewedCheckout();
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/mixins.scss";
.user-payment {
  .payment-form {
    margin-top: 16px;
  }

  &.has-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  h4 {
    font-size: 20px;
    margin-bottom: 28px;
    margin-top: 23px;
  }

  .main-header {
    h4 {
      margin-bottom: 0;
      margin-top: 23px;
    }

    h5 {
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;
      font-size: 14px;
      line-height: 23px;
    }
  }

  .invoice {
    margin-top: 22px;
  }

  h5 {
    font-size: 16.5px;
    margin-bottom: 16px;
    text-align: left;

    &.sub {
      text-align: center;
      font-size: 15.5px;
      font-weight: normal;
      margin-top: 10px;
    }
  }

  .charge {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
  }
}
</style>
