<template>
  <a :href="url" class="download-app-btn flex-row">
    <span class="img-wrapper">
      <img
        v-if="isApple"
        src="@/assets/apple_icon.svg"
        width="44"
        height="54"
        alt="Apple"
      />
      <img
        v-if="!isApple"
        src="@/assets/google_play_icon.png"
        width="33"
        height="33"
        alt="Google Play"
      />
    </span>
    <span class="text">{{ text }}</span>
  </a>
</template>

<script>
const APPLE_STORE_LINK =
  "https://apps.apple.com/us/app/lasting-marriage-health-app/id1225049619";
const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.lasting.lasting";

export default {
  name: "DownloadAppButton",
  props: {
    isApple: { type: Boolean, default: false },
    text: { type: String, default: "Download" },
  },
  computed: {
    url() {
      return this.isApple ? APPLE_STORE_LINK : GOOGLE_PLAY_LINK;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.download-app-btn {
  border: 1.6px solid var(--light-gray);
  border-radius: 3px;
  justify-content: flex-start;
  padding: 20px 25px;
  margin-top: 20px;
  height: 100px;
  text-align: left;
  color: var(--black);

  .img-wrapper {
    width: 44px;
    text-align: center;
  }

  .img-wrapper,
  .text {
    align-self: center;
    display: block;
  }

  .text {
    margin-left: 30px;
    font-size: 14px;
    color: var(--black);
  }
}
</style>
