<template>
  <section class="partner-share checkout">
    <div class="content">
      <h4>Invite your partner</h4>
      <img
        src="@/assets/clipboard.svg"
        width="160"
        height="160"
        alt="Clipboard Graphic"
      />
      <p class="blurb">
        People who invite their partner are 3x more likely to see improvement.
      </p>
      <invite-partner-button
        class="share-button"
        :component="component"
        :text="shareText"
        :title="shareTitle"
        @shared="inviteShared"
      />
      <a class="skip-button" @click="skipInvite">Skip for now</a>
    </div>
  </section>
</template>

<script>
import nextRouteMixin from "@/mixins/nextRoute";
import submitPaymentMixin from "@/mixins/submitPayment";
import sharingMixin from "@/mixins/sharing";
import QuizButton from "@/components/QuizButton";
import InvitePartnerButton from "@/components/InvitePartnerButton";

export default {
  name: "InvitePartnerView",
  mixins: [nextRouteMixin, submitPaymentMixin, sharingMixin],
  // eslint-disable-next-line vue/no-unused-components
  components: { InvitePartnerButton, QuizButton },
  props: {
    store: { type: Object, default: () => {} },
  },
  data() {
    return {
      shareTitle: "Join me on Lasting!",
      component: QuizButton,
      shareText: null,
    };
  },
  created() {
    this.shareText = `Hey! I just joined Lasting (a couples counseling app). If you take the first assessment today, we can compare our answers. It takes only 4 minutes.\n\nUse this code to pair our apps: ${this.$user.user["partner-code"]}\n\n${this.invitePartnerUrl}`;
  },
  mounted() {
    this.$tracker.trackEvent("Partner Invitation Page Viewed");
  },
  methods: {
    inviteShared() {
      this.goToNextRoute();
    },
    skipInvite() {
      this.$tracker.trackEvent("Message to Invite Partner Skipped");
      this.goToNextRoute();
    },
  },
};
</script>
<style lang="scss">
@import "@/style/variables.scss";
.partner-share {
  .content {
    display: flex;
    flex-direction: column;
    > * {
      align-self: center;
    }
  }

  img {
    display: block;
  }

  .blurb {
    margin-top: 22px;
    text-align: left;
    font-size: 16px;
  }

  .share-button {
    margin: 26px auto 0;

    .quiz-button {
      margin: 0 auto;
    }
  }

  .skip-button {
    margin-top: 32px;
    font-size: 13.5px;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
