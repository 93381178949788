<template>
  <transition-group class="route-fade-container" name="fade" tag="div">
    <router-view :key="key"></router-view>
  </transition-group>
</template>

<script>
export default {
  name: "FadingRouterView",
  data() {
    return {
      key: 0,
    };
  },
};
</script>

<style scoped>
.route-fade-container {
  position: relative;
}
</style>
