<template>
  <section class="plan checkout">
    <div class="inner-block">
      <div class="content">
        <h4>
          Take this step toward a healthier, happier relationship with
          {{ savingsPercentage }}% off
        </h4>
        <plan-highlights :show-border="true" />
        <div class="testimonials-container">
          <testimonials />
        </div>
      </div>
    </div>
    <div class="button-container">
      <quiz-button :solid="true" @click="submit()">Continue</quiz-button>
    </div>
  </section>
</template>

<script>
import QuizButton from "@/components/QuizButton";
import PlanHighlights from "@/components/PlanHighlights";
import Testimonials from "@/components/Testimonials";
import nextRouteMixin from "@/mixins/nextRoute";
import { getPlan } from "@/plans";

export default {
  name: "CheckoutPlan",
  components: { QuizButton, PlanHighlights, Testimonials },
  mixins: [nextRouteMixin],
  data() {
    const { savingsPercentage } = getPlan();
    return { savingsPercentage };
  },
  mounted() {
    this.$tracker.viewedPlanPage();
  },
  methods: {
    submit() {
      // this.$tracker.track("Trial Price Selected", {
      //   Price: this.selectedPlan.price,
      // });
      this.goToNextRoute();
    },
  },
};
</script>

<style lang="scss">
@import "@/style/variables.scss";
@import "@/style/mixins.scss";

.plan {
  justify-content: space-between;
  flex: 1;
  @include flex-column;

  h4 {
    font-weight: normal;
    line-height: 30px;
    margin: 0;
  }

  p {
    line-height: 25px;
  }

  h5 {
    text-align: center;
  }

  .button-container {
    flex: 0;
    background-color: var(--white);
    margin-top: 0;
    width: 100%;
    margin-bottom: 32px;

    .quiz-button {
      margin: 0 auto;
      max-width: 480px;
    }
  }
}

@media only screen and (max-width: $breakpoint) {
  .plan {
    h4 {
      margin-bottom: 55px;
    }

    .button-container {
      position: sticky;
      bottom: 0px;
      background-color: var(--white);
      box-shadow: 1px 0px 4px 0px rgba(181, 181, 181, 0.5);
      padding: 25px;
      margin-bottom: 0;
      z-index: 1;

      .quiz-button {
        margin: 0 auto;
        max-width: 480px;
      }
    }
  }
}
</style>
