<template>
  <alert-modal class="add-to-calendar-modal" @overlay-click="$emit('close')">
    <section class="container">
      <button type="button" class="close" @click="$emit('close')">
        <img width="14" height="14" alt="Close" src="../assets/close_x.svg" />
      </button>
      <h2>Would you like to add this workshop to your calendar?</h2>
      <quiz-button :solid="true" @click="saveFile">Add to calendar</quiz-button>
    </section>
  </alert-modal>
</template>

<script>
import QuizButton from "@/components/QuizButton";
import AlertModal from "@/components/AlertModal";
import fileMixin from "@/mixins/file";

export default {
  name: "AddToCalendarModal",
  components: {
    AlertModal,
    QuizButton,
  },
  mixins: [fileMixin],
  props: {
    id: { type: String, default: null },
    title: { type: String, default: null },
    description: { type: String, default: null },
    location: { type: String, default: null },
    startTime: { type: String, default: null },
    endTime: { type: String, default: null },
  },
  methods: {
    saveFile() {
      const file = this.generateICSBlob({
        uid: this.id,
        startTime: this.startTime,
        endTime: this.endTime,
        location: this.location,
        summary: this.generateWorkshopTitle(this.title),
        description: this.generateWorkshopDescription(this.description),
      });
      this.saveBlob({
        content: file,
        name: `event-${this.id}.ics`,
        type: "text/calendar;charset=UTF-8",
      });
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixins.scss";
@import "@/style/variables.scss";

.add-to-calendar-modal {
  .container {
    height: 275px;
    @include flex-column;

    & > * {
      align-self: center;
    }
  }

  .close {
    align-self: flex-end;
    @include link-button;
  }

  h2 {
    font-size: 16.5px;
    line-height: 26px;
    margin: 35px 0 0;
  }

  .quiz-button {
    margin: 30px 0 0;
    border-radius: 25px;
    width: auto;
    padding: 10px 32px;
    line-height: 20px;
  }
}
</style>
