<script>
import ApplePayButton from "@/components/ApplePayButton";

const DEFAULT_PAYMENT_REQUEST_OPTIONS = {
  country: "US",
  currency: "usd",
  requestPayerName: true,
  requestPayerEmail: true,
};
export default {
  name: "BrowserPay",
  components: {
    ApplePayButton,
  },
  props: {
    buttonText: {
      type: String,
      default: null,
    },
    stripe: {
      type: String,
      required: true,
    },
    plan: {
      type: Object,
      required: true,
    },
  },
  data() {
    const stripeInstance = Stripe(this.stripe);
    const total = {
      total: {
        amount: this.plan.browserPayAmount,
        label: this.plan.browserPayDescription,
      },
    };

    return {
      loading: true,
      canApplePay: false,
      canGooglePay: false,
      elements: stripeInstance.elements({ locale: this.$root.locale }),
      paymentRequest: stripeInstance.paymentRequest({
        ...DEFAULT_PAYMENT_REQUEST_OPTIONS,
        ...total,
      }),
    };
  },
  computed: {
    canMakePayment() {
      return this.canApplePay || this.canGooglePay;
    },
  },
  mounted() {
    if (this.paymentRequest) {
      // Check the availability of the Payment Request API first.
      this.paymentRequest.canMakePayment().then(this.init);

      // Notify the parent component when we receive a token.
      this.paymentRequest.on("token", (event) => this.$emit("token", event));
    }
  },
  methods: {
    init(canMakePayment) {
      this.$emit("payment-loaded", canMakePayment);
      this.loading = false;
      this.canApplePay = canMakePayment && canMakePayment.applePay;
      this.canGooglePay = canMakePayment && canMakePayment.googlePay;
      this.$nextTick(this.createPaymentRequestButton);
    },
    createPaymentRequestButton() {
      if (
        !this.canMakePayment ||
        !this.$refs.element ||
        this.$refs.button ||
        this.buttonText
      )
        return;

      this.elements
        .create("paymentRequestButton", {
          paymentRequest: this.paymentRequest,
        })
        .mount(this.$refs.element);
    },
  },
  render(createElement) {
    // Render a loading slot if we are waiting for canMakePayment.
    if (this.loading) {
      return this.$slots.loading && this.$slots.loading[0];
    }

    // Render a warning slot if payment request isn't available.
    if (!this.canMakePayment) {
      return this.$slots.unavailable && this.$slots.unavailable[0];
    }

    // Render scoped slot if provided.
    if (this.$scopedSlots.default) {
      return this.$scopedSlots.default({
        listeners: { click: () => this.paymentRequest.show() },
        canApplePay: this.canApplePay,
        canGooglePay: this.canGooglePay,
      });
    }

    // Render custom Stripe button.
    if (this.buttonText && this.canApplePay) {
      const paymentRequestHandler = this.paymentRequest;
      return createElement("apple-pay-button", {
        on: {
          click: () => paymentRequestHandler.show(),
        },
        props: {
          text: this.buttonText,
        },
        ref: "button",
      });
    }

    // Otherwise render default Stripe Element button.
    return createElement("div", { ref: "element" });
  },
};
</script>
