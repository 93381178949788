import { render, staticRenderFns } from "./Testimonials.vue?vue&type=template&id=4940e645&scoped=true&"
import script from "./Testimonials.vue?vue&type=script&lang=js&"
export * from "./Testimonials.vue?vue&type=script&lang=js&"
import style0 from "./Testimonials.vue?vue&type=style&index=0&id=4940e645&lang=scss&scoped=true&"
import style1 from "./Testimonials.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4940e645",
  null
  
)

export default component.exports