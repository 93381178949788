<template>
  <button class="quiz-option" :class="{ selected }" @click="$emit('click')">
    <div class="label">
      <span v-if="tag" class="tag">{{ tag }}</span>
      <span class="option-label"><slot /></span>
    </div>
    <div v-if="showCheck" class="circle">
      <img
        v-if="selected"
        src="@/assets/checkmark.svg"
        width="32"
        height="32"
        alt="Checked"
      />
    </div>
  </button>
</template>

<script>
export default {
  name: "QuizOption",
  props: {
    selected: { type: Boolean, default: false },
    tag: { type: String, default: null },
    showCheck: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixins.scss";
@import "@/style/variables.scss";

.quiz-option {
  background: var(--white);
  border: 1px solid var(--light-gray);
  border-radius: 3px;
  @include noto;
  font-size: 18px;
  padding: 35px 25px;
  text-align: left;
  cursor: pointer;
  outline: none;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--light-black);

  .label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .circle {
    width: 32px;
    height: 32px;
    border: 1px solid var(--light-gray);
    border-radius: 32px;
    align-self: center;
  }

  .tag {
    color: var(--tag-color);
    font-size: 12px;
    margin-top: -15px;
    font-weight: 700;
    padding-bottom: 3px;
    display: block;
    text-transform: capitalize;
  }

  &.selected {
    background-color: #f0f0f0;
    border-color: #f0f0f0;

    .circle {
      border: none;
    }
  }
}

@media (hover: hover) {
  .quiz-option:hover {
    background: #f4f4f4;
  }
}

.quiz-option:active {
  background: #e3e3e3;
}

@media only screen and (max-width: $breakpoint) {
  .quiz-option {
    width: 100%;
    padding: 35px 32px;
    margin: 0;
    margin-bottom: 8px;
  }
}
</style>
