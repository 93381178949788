<template>
  <section class="quiz-questions flex-column">
    <transition-group name="fade" tag="div">
      <quiz-question
        v-for="nextQuestion in ondeck"
        :key="nextQuestion.question"
        :question="nextQuestion"
        @selected-answer="onAnswer"
      />
    </transition-group>
  </section>
</template>

<script>
import QuizQuestion from "@/components/QuizQuestion";

export default {
  name: "QuizSection",
  components: { QuizQuestion },
  props: {
    questions: { type: Array, default: () => [] },
    index: { type: Number, default: 0 },
    onAnswer: { type: Function, default: () => {} },
  },
  computed: {
    ondeck() {
      return [this.questions[this.index]];
    },
    question() {
      return this.questions[this.index];
    },
    length() {
      return this.questions.length;
    },
  },
};
</script>

<style scoped>
.quiz-questions {
  position: relative;
  padding-top: 35px;
}
</style>
