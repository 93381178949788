<template>
  <button class="apple-pay-button" @click="emitClick">
    <span class="text">{{ text }}</span>
    <img
      class="logo"
      src="../assets/apple_pay_logo_white.svg"
      alt="Apple Pay Logo"
    />
  </button>
</template>
<script>
export default {
  name: "ApplePayButton",
  components: {},
  props: {
    text: { type: String, default: "" },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/mixins.scss";
.apple-pay-button {
  width: 100%;
  background-color: var(--black);
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: none;

  .logo {
    height: 25px;
    margin: 0 0 0 4px;
    align-self: center;
    padding-top: 3px;
  }

  .text {
    font-size: 21px;
    font-weight: 400;
    font-family: Arial, sans-serif;
    color: var(--white);
    align-self: center;
    -webkit-font-smoothing: antialiased;
  }
}
</style>
