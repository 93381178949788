<template>
  <section class="workshop-booking-error workshop-checkout">
    <loading-spinner v-if="isLoading" />
    <template v-if="!isLoading">
      <img
        src="../../assets/bird_noping.svg"
        width="140"
        height="140"
        alt="Problem booking workshop"
      />
      <h1>We had trouble booking that workshop for you</h1>
      <template v-if="upcomingEvents.length">
        <p>Try booking again with one of these upcoming workshops.</p>
        <ul>
          <workshop-error-list-item
            v-for="event in upcomingEvents"
            :id="event.id"
            :key="event.id"
            class="workshop"
            :title="event.workshop.attributes.title"
            :description="event.workshop.attributes['preview-text']"
            :start-time="event.attributes['start-time']"
          >
            {{ event.message }}
          </workshop-error-list-item>
        </ul>
        <template v-if="!upcomingEvents.length">
          <p>
            We're sorry, there are no upcoming workshops available for you at
            this time.
          </p>
        </template>
      </template>
    </template>
  </section>
</template>

<script>
import dayjs from "dayjs";

import WorkshopErrorListItem from "@/components/WorkshopErrorListItem";
import LoadingSpinner from "@/components/LoadingSpinner";
import nextRouteMixin from "@/mixins/nextRoute";

export default {
  name: "WorkshopBookingErrorView",
  components: {
    WorkshopErrorListItem,
    LoadingSpinner,
  },
  mixins: [nextRouteMixin],
  props: {
    store: { type: Object, default: () => {} },
    saveAnswers: { type: Boolean, default: false },
  },
  data() {
    return {
      upcomingEvents: [],
      purchasedEvents: [],
      isLoading: true,
    };
  },
  created() {
    window.addEventListener("beforeunload", this.unloadHandler);
  },
  mounted() {
    this.$user
      .getPurchasedWorkshops()
      .then((event) => {
        this.purchaseEvents = event.data.data;
      })
      .then(this.$user.getUpcomingWorkshops)
      .then((event) => {
        let workshops = event.data.data;
        this.upcomingEvents = event.data.included
          .filter(
            (event) =>
              event.type === "workshop-events" &&
              event.attributes["start-time"] !== null &&
              dayjs(event.attributes["start-time"]) > dayjs() &&
              !this.purchasedEvents.find(
                (purchasedEvent) =>
                  purchasedEvent.id === event.attributes["workshop-id"]
              )
          )
          .sort((a, b) =>
            a.attributes["start-time"] < b.attributes["start-time"] ? -1 : 1
          )
          .slice(0, 3)
          .map((event) => ({
            ...event,
            reserved: false,
            workshop: workshops.find(
              (workshop) => workshop.id == event.attributes["workshop-id"]
            ),
          }));

        this.isLoading = false;
      })
      .catch(() => {
        this.upcomingEvents = [];
        this.isLoading = false;
      });
  },
  methods: {
    unloadHandler() {
      this.$store.clearData();
      this.$user.logOut();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/mixins.scss";
.workshop-booking-error {
  @include flex-column;
  align-items: flex-start;
  text-align: center;

  h4 {
    font-size: 20px;
  }

  img {
    align-self: center;
  }

  h1 {
    font-size: 20px;
    line-height: 30px;
    padding: 0;
    margin: 40px 0 0;
    width: 100%;
  }

  p {
    font-size: 16px;
    padding: 0;
    margin: 24px 0 0;
    align-self: center;
  }

  ul {
    padding-left: 0;
    width: 100%;

    li {
      &:first-child {
        margin-top: 26px;
      }
      margin-top: 16px;
    }
  }
}
</style>
