import axios from "axios";
import Vue from "vue";
import tracker from "./tracker";
import Cookies from "js-cookie";

axios.defaults.baseURL = process.env.VUE_APP_REMOTE_URL;

const underscoreToDashes = (e) => e.replace(/_/gi, "-");
// Account for the fact that the user data may have props or underscores depending on whether it's on the token or the profile
const mapUserProperties = (orig) => {
  const mappedObj = {};
  for (const [key, value] of Object.entries(orig)) {
    mappedObj[underscoreToDashes(key)] = value;
  }
  return mappedObj;
};

class User {
  constructor() {
    this.user = null;
    this.jwt = localStorage.getItem("jwt");

    if (this.jwt && Cookies.get("session")) {
      this.setJwt(this.jwt);
    } else {
      localStorage.removeItem("jwt");
    }
  }

  isLoggedIn() {
    return this.jwt != null;
  }

  canInvitePartner() {
    return (
      this.isLoggedIn() &&
      this.user &&
      this.user["partner-code"] &&
      !this.user["partner-code-used"] &&
      this.user["is-primary-user"]
    );
  }

  logOut() {
    this.user = null;
    this.jwt = null;
    localStorage.removeItem("jwt");
    Cookies.remove("session");
  }

  createAccount(email, password) {
    return axios
      .post("/users", {
        user: { email, password },
      })
      .then(this.accountCreated.bind(this));
  }

  accountCreated(response) {
    const data = response.data;
    this.user = mapUserProperties(data.user);
    this.jwt = data.jwt;
    this.setJwt(this.jwt);
    tracker.aliasMixpanel(this.user.id);
    tracker.identifyCustomerio({
      id: this.user.id,
      email: this.user.email,
    });
    return Promise.resolve(this.user);
  }

  updateAccount(props) {
    return axios
      .put(`/users/${this.user.id}`, {
        id: this.user.id,
        user: { ...props },
      })
      .then(this.accountUpdated.bind(this));
  }

  accountUpdated(response) {
    const data = response.data;
    this.user = {
      ...mapUserProperties(data.data.attributes),
      id: data.data.id,
    };
    tracker.identify(data.data.id, this.user.email, this.user["created-at"]);
    return Promise.resolve(this.user);
  }

  setJwt(jwt) {
    localStorage.setItem("jwt", jwt);
    axios.defaults.headers.common["Authorization"] = jwt;
    Cookies.set("session", new Date(), { expires: 2 });
    if (!this.user) {
      this.get();
    }
  }

  get() {
    return axios.get("/users/profile").then(this.userLoaded.bind(this));
  }

  userLoaded({ data }) {
    this.user = {
      ...mapUserProperties(data.data.attributes),
      id: data.data.id,
    };
    tracker.identify(this.user.id, this.user.email, this.user["created-at"]);
    return Promise.resolve(this.user);
  }

  logIn(email, password) {
    return axios
      .post("/user_token", {
        auth: { email, password },
      })
      .then(this.logInComplete.bind(this));
  }

  logInComplete(response) {
    const data = response.data;
    this.user = mapUserProperties(data.user);
    this.jwt = data.jwt;
    this.setJwt(this.jwt);
    tracker.identify(this.user.id, this.user.email, this.user["created-at"]);
    return Promise.resolve(this.user);
  }

  resetPassword(email) {
    return axios.post("/passwords", {
      email,
    });
  }

  batchSubmit(user_batch_answers) {
    return axios.post("/user_quiz_answers/batch_update", {
      user_batch_answers,
      mark_session_completed: true,
    });
  }

  subscribe(token, plan) {
    return axios.post("/ecommerce/subscribe", {
      stripe_token: token.id,
      subscription_type: plan,
    });
  }

  markWebTrialStarted() {
    return axios.put("/users/web_trial_started");
  }

  getUpcomingWorkshops() {
    return axios.get("/workshops", { params: { include_events: true } });
  }

  getPurchasedWorkshops() {
    return axios.get("/purchased_workshops");
  }

  purchaseWorkshop(id) {
    return axios.post("/purchased_workshops", {
      purchased_workshop: { workshop_event_id: id },
    });
  }

  sendMagicLink(email) {
    return axios.post("/magic_links", {
      magic_link: { email },
    });
  }
}

const user = new User();
user.install = function () {
  Object.defineProperty(Vue.prototype, "$user", {
    get() {
      return user;
    },
  });
};

export default user;
