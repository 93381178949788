import dayjs from "dayjs";
const workshopsMixin = {
  computed: {
    workshopInProgress() {
      if (!this.workshop || !this.workshop.startTime) return false;
      const now = dayjs();
      const eventDate = dayjs(this.workshop.startTime);
      const maxTime = eventDate.add(30, "minute");
      if (now.isAfter(eventDate) && now.isBefore(maxTime)) {
        return true;
      }
      return false;
    },
    workshopPassed() {
      if (!this.workshop || !this.workshop.startTime) return false;

      const now = dayjs();
      const eventDate = dayjs(this.workshop.startTime);
      const maxTime = eventDate.add(30, "minute");
      if (now.isAfter(maxTime)) {
        return true;
      }
      return false;
    },
  },
};

export default workshopsMixin;
