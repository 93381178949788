const CACHE_VERSION = "0.0.6";

export const cached = (name, matchCurrCacheVersion = true) => {
  let vals = localStorage.getItem(name);
  vals = (vals && JSON.parse(vals)) || {};
  if (matchCurrCacheVersion) {
    if (!vals.cacheVersion || vals.cacheVersion !== CACHE_VERSION) {
      vals.data = {};
    }
  }
  return vals.data ? vals.data : vals;
};

export const cache = (name, vals) => {
  return localStorage.setItem(
    name,
    JSON.stringify({ data: { ...vals }, cacheVersion: CACHE_VERSION })
  );
};

export const clear = (name) => {
  localStorage.removeItem(name);
};

export default { cached, cache, clear };
