const sharingMixin = {
  data() {
    return {
      shareSupported: navigator.canShare,
      invitePartnerUrl: "https://getlasting.onelink.me/Vjbg/invitepartner",
    };
  },
  methods: {
    openShareDialog({ text, title, url }) {
      const props = { text, title, url };
      Object.keys(props).forEach((key) => {
        if (props[key] === null || props[key] === undefined) {
          delete props[key];
        }
      });
      return navigator.share(props);
    },
    generateShareEmailLink({ title, text }) {
      return `mailto:?subject=${encodeURIComponent(
        title
      )}&body=${encodeURIComponent(text)}`;
    },
  },
};

export default sharingMixin;
