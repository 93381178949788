<template>
  <transition-group name="fade" tag="div">
    <div v-for="i in [index]" :key="i">
      <p>{{ currentText }}</p>
    </div>
  </transition-group>
</template>

<script>
const DEFAULT_DURATION_MS = 3000;

export default {
  name: "TextSlideshow",
  props: {
    items: { type: Array, default: () => [] },
    duration: { type: Number, default: DEFAULT_DURATION_MS },
  },
  data() {
    return {
      index: 0,
      timer: null,
    };
  },
  computed: {
    currentText: function () {
      return this.items[Math.abs(this.index) % this.items.length];
    },
  },
  mounted() {
    this.startSlide();
  },
  unmounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    startSlide: function () {
      this.timer = setInterval(this.next, this.duration);
    },
    next: function () {
      this.index++;
    },
  },
};
</script>

<style scoped>
.fade-item {
  display: inline-block;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  display: none;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
