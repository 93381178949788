<template>
  <div class="testimonial-item-wrapper">
    <div class="avatar-wrapper">
      <img :src="testimonial.image" :alt="testimonial.author + '-avatar'" />
    </div>
    <img src="@/assets/quote.svg" alt="quote-mark" />
    <div class="content">
      {{ testimonial.content }}
    </div>
    <div class="author">-{{ testimonial.author }}.</div>
  </div>
</template>

<script>
export default {
  name: "TestimonialItem",
  props: {
    testimonial: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.testimonial-item-wrapper {
  background: #fff3ed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 28px;
  min-height: 295px;
  padding: 74px 24px 24px 24px;

  @media (max-width: 767.98px) {
    min-height: 343px;
  }

  &:hover {
    cursor: pointer;
  }

  .avatar-wrapper {
    padding: 12px;
    display: block;
    width: 100px;
    height: 100px;
    background-color: var(--white);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);

    img {
      border-radius: 50%;
      box-shadow: 1px 3px 12px 0px rgba(0, 0, 0, 0.25);
    }
  }

  .content {
    margin-top: 16px;
    text-align: center;
  }

  .author {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}
</style>
