<template>
  <div :class="{ 'quiz-question': true, multiselect: question.multiselect }">
    <h4 class="question-text">{{ interpolatedQuestion }}</h4>
    <h5 v-if="question.multiselect">Select any that apply</h5>
    <div class="question-options">
      <quiz-option
        v-for="(option, index) in question.answers"
        :key="option.label"
        :selected="optionSelected(option)"
        :tag="getTag(question.page_id, index)"
        :show-check="question.multiselect"
        @click="onClick(option)"
      >
        {{ option.label }}
      </quiz-option>
    </div>
    <alert-section v-if="errors.length">
      {{ errors[0] }}
    </alert-section>
    <portal to="sticky-footer" v-if="showNext">
      <div id="submit-button-container">
        <quiz-button :solid="true" @click="submit()">Next</quiz-button>
      </div>
    </portal>
  </div>
</template>

<script>
import QuizOption from "@/components/QuizOption";
import QuizButton from "@/components/QuizButton";
import AlertSection from "@/components/AlertSection";
import {
  getAgeDescriptor,
  getGenderDescriptor,
  getChildrenDescriptor,
} from "@/formatUtils";

export default {
  name: "QuizQuestion",
  components: { QuizOption, QuizButton, AlertSection },
  props: {
    question: {
      type: Object,
      default: () => {},
    },
    store: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      answer: this.getInitialAnswer(),
      errors: [],
      factorsTag: "Common",
    };
  },
  computed: {
    showNext() {
      return this.question.multiselect && this.answer && this.answer.length;
    },
    interpolatedQuestion() {
      let question = this.question.question;
      const meta = this.$store.state.meta;
      const age = getAgeDescriptor(meta.age);
      const gender = getGenderDescriptor(meta.gender);
      const children = getChildrenDescriptor(meta.children);

      question = question.replace(/\{{2}\s*age\s*\}{2}/gi, age);
      question = question.replace(/\{{2}\s*gender\s*\}{2}/gi, gender);
      question = question.replace(/\{{2}\s*children\s*\}{2}/gi, children);

      return question;
    },
  },
  methods: {
    getInitialAnswer() {
      const answer = this.$store.getAnswer(
        this.question.page_id || this.question.meta_name
      );
      return answer ? answer : this.question.multiselect ? [] : null;
    },
    optionSelected(option) {
      if (this.question.multiselect) {
        const stringOption = JSON.stringify(option);
        return (
          Array.isArray(this.answer) &&
          this.answer.findIndex((o) => JSON.stringify(o) === stringOption) >= 0
        );
      }
      return (
        this.answer && JSON.stringify(this.answer) === JSON.stringify(option)
      );
    },
    submit() {
      this.errors = [];
      if (this.answer && this.answer.length) {
        this.$emit("selected-answer", {
          answer: this.answer,
          question: {
            text: this.interpolatedQuestion,
            id: this.question.page_id || this.question.meta_name,
          },
        });
      } else {
        this.errors = ["At least one selection is required"];
      }
    },
    onClick(option) {
      if (this.question.multiselect) {
        const stringOption = JSON.stringify(option);
        const optionIndex = this.answer.findIndex(
          (o) => JSON.stringify(o) === stringOption
        );
        if (optionIndex >= 0) {
          // Deselect the answer by removing from array
          this.answer.splice(optionIndex, 1);
        } else {
          this.errors = [];
          this.answer.push(option);
        }
        return;
      }
      this.$emit("selected-answer", {
        answer: option,
        question: {
          text: this.interpolatedQuestion,
          id: this.question.page_id || this.question.meta_name,
        },
      });
    },
    getTag(questionId, index) {
      if (this.factorsTag && questionId === 12395 && index < 3) {
        return this.factorsTag;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

@keyframes slideUp {
  0% {
    opacity: 0;
    bottom: -250px;
  }

  100% {
    opacity: 1;
    bottom: 0;
  }
}

.quiz-question {
  width: 100%;

  .question-options {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin: 30px auto;
  }

  &.multiselect .question-options {
    margin-top: 25px;
  }

  h5 {
    color: var(--lighter-black);
    margin-top: 25px;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
  }
}

#submit-button-container {
  background-color: var(--white);
  box-shadow: 1px 0 4px 0 rgba(181, 181, 181, 0.5);
  padding: 24px 20px;

  .quiz-button {
    margin: 0 auto;
    animation: slideUp 0.25s;
    width: 480px;
    max-width: 100%;
  }
}

@media only screen and (max-width: $xs-breakpoint) {
  #submit-button-container {
    .quiz-button {
      width: 100%;
    }
  }
}
</style>
