import Vue from "vue";
import cache, { clear } from "./cache";

let questionFileName = null;
let cacheName = "quiz";

const defaultMeta = {
  existingLogin: null,
  email: null,
  selectedPlan: null,
  paymentComplete: false,
};

export const state = Vue.observable({
  questions: null,
  answers: { ...(cache.cached("answers") || {}) },
  meta: Vue.observable({ ...(cache.cached("meta") || { ...defaultMeta }) }),
  quizStarted: false,
  quizCompleted: false,
});

const loadQuestions = () => {
  const questions = require(`./${questionFileName}`);
  return questions;
};

export const setQuestionFile = (fileName) => {
  questionFileName = fileName;
  state.questions = loadQuestions();
};

const answersCacheName = () => `${cacheName}-answers`;

const metaCacheName = () => `${cacheName}-meta`;

export const setCacheName = (name) => {
  cacheName = name;
  state.answers = Vue.observable({
    ...(cache.cached(answersCacheName()) || {}),
  });
  state.meta = Vue.observable({
    ...(cache.cached(metaCacheName()) || { ...defaultMeta }),
  });
};

export const getTotalQuestionsCount = () => {
  let count = 0;
  for (const section of Object.keys(state.questions)) {
    count += state.questions[section].length;
  }
  return count;
};

export const getQuestionCountBefore = (sectionName, index) => {
  let count = parseInt(index, 10);
  for (const section of Object.keys(state.questions)) {
    if (section === sectionName) {
      break;
    }
    count += state.questions[section].length;
  }
  return count;
};

export const nextUp = (sectionName, currIndex) => {
  if (currIndex < state.questions[sectionName].length - 1) return currIndex + 1;
  return null;
};

export const getQuestions = (sectionName) => {
  return state.questions[sectionName];
};

export const getAnswer = (id) => {
  return state.answers[id];
};

export const setMeta = (value) => {
  if (value) {
    Object.keys(value).forEach((key) => {
      Vue.set(state.meta, key, value[key]);
    });
  }
  cache.cache(metaCacheName(), { ...state.meta });
};

export const sectionComplete = (section) => {
  const questions = state.questions[section];
  let allAnswered = true;
  for (const question of questions) {
    if (question.optional) {
      continue;
    }
    if (!state.answers[question.page_id || question.meta_name]) {
      allAnswered = false;
      return;
    }
  }
  return allAnswered;
};

export const clearData = () => {
  clear(answersCacheName());
  state.answers = [];
  clear(metaCacheName());
  state.meta = Vue.observable({ ...defaultMeta });
};

export const answer = (section, index, answer) => {
  const question = state.questions[section][index];
  const id = question.page_id || question.meta_name;
  state.answers[id] = answer;
  cache.cache(answersCacheName(), state.answers);

  if (answer.meta) {
    setMeta(answer.meta);
    cache.cache(metaCacheName(), state.meta);
  } else if (question.meta_name) {
    setMeta({ [question.meta_name]: answer.value });
    cache.cache(metaCacheName(), state.meta);
  }
};

export const batchAnswers = () => {
  const answers = [];
  for (const section of Object.keys(state.questions)) {
    for (const question of state.questions[section]) {
      const answer = state.answers[question.page_id || question.meta_name];
      if (!answer) return;
      const answerArray = Array.isArray(answer)
        ? answer.filter((option) => !option.meta).map((option) => option.value)
        : [answer.value];
      if (answerArray.length && question.page_id) {
        answers.push({
          quiz_type: "Selection",
          selections: answerArray,
          page_id: question.page_id,
        });
      }
    }
  }
  return answers;
};

export const install = function () {
  Object.defineProperty(Vue.prototype, "$store", {
    get() {
      return {
        state,
        getTotalQuestionsCount,
        getQuestionCountBefore,
        nextUp,
        getQuestions,
        getAnswer,
        setMeta,
        sectionComplete,
        clearData,
        answer,
        batchAnswers,
      };
    },
  });
};

export default state;
