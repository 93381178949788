<template>
  <div class="offer-badge">{{ text }}</div>
</template>
<script>
export default {
  name: "OfferBadge",
  props: { text: { type: String, default: "Special Offer" } },
};
</script>
<style lang="scss" scoped>
@import "@/style/mixins.scss";
.offer-badge {
  color: var(--white);
  border: 1.5px solid var(--green);
  background-color: var(--green);
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 2px;
  font-weight: bold;
  display: inline-block;
}
</style>
