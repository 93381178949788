<template>
  <form class="payment-form" @submit.prevent="submit">
    <alert-section ref="alertSection" v-show="error" class="row">{{
      error
    }}</alert-section>
    <section v-if="!browserPayLoading" class="log-in">
      <header class="flex-row">
        <h5>Log in to continue</h5>
        <forgot-password-button class="button" :email="email" />
      </header>
      <email-field
        :email="$v.email"
        placeholder="Email address"
        :disabled="isLoading"
        @change="modelFieldUpdated"
      />
      <password-field
        :password="$v.password"
        placeholder="Password"
        :disabled="isLoading"
        @change="modelFieldUpdated"
      />
    </section>
    <section v-if="showBrowserPay" class="browser-pay">
      <browser-pay
        :stripe="stripeKey"
        :plan="plan"
        :cta="cta"
        @token="onBrowserPayTokenCreated"
        @payment-loaded="onPaymentLoaded"
      />
      <loading-spinner v-if="browserPayLoading" class="spinner" />
      <!-- eslint-disable -->
      <p
        v-if="!browserPayLoading && this.footnote"
        :class="{ footnote: true, 'light-note': lightNote }"
        v-html="footnote"
      >
        <!-- eslint-enable -->
      </p>
    </section>
    <use-credit-card-button
      v-if="showCreditCardToggle"
      @click="toggleCreditCard"
      class="use-cc-btn"
    />
    <div v-if="showCreditCard">
      <section class="information">
        <header v-if="showCreditCardHeader" class="flex-row">
          <h5>Payment information</h5>
          <why-now-button v-if="showWhyNow" class="button" />
        </header>
        <h5
          v-if="showCreditCardOptionHeader"
          class="flex-row pay-with-card center"
        >
          Or pay with card
        </h5>
        <credit-card-fields
          ref="creditCardFields"
          :is-loading="isLoading"
          :model="$v.creditCard"
          @change="onCardChange"
          @blur="onCardBlur"
        />
      </section>
      <quiz-button :solid="true" type="submit" :loading="isLoading">{{
        cta
      }}</quiz-button>
      <!-- eslint-disable -->
      <p
        :class="{ footnote: true, 'light-note': lightNote }"
        v-if="footnote"
        v-html="footnote"
      ></p>
      <!-- eslint-enable -->
    </div>
  </form>
</template>

<script>
import QuizButton from "@/components/QuizButton";
import BrowserPay from "@/components/BrowserPay";
import LoadingSpinner from "@/components/LoadingSpinner";
import WhyNowButton from "@/components/WhyNowButton";
import ForgotPasswordButton from "@/components/ForgotPasswordButton";
import AlertSection from "@/components/AlertSection";
import CreditCardFields from "@/components/CreditCardFields";
import UseCreditCardButton from "@/components/UseCreditCardButton";
import EmailField from "@/components/EmailField";
import PasswordField from "@/components/PasswordField";

import submitPaymentMixin, { GENERIC_FORM_ERROR } from "@/mixins/submitPayment";
import scrollToErrorMixin from "@/mixins/scrollToError";
import { validStripeField } from "@/validators";

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "UserPaymentForm",
  components: {
    QuizButton,
    BrowserPay,
    LoadingSpinner,
    WhyNowButton,
    ForgotPasswordButton,
    CreditCardFields,
    AlertSection,
    UseCreditCardButton,
    EmailField,
    PasswordField,
  },
  mixins: [validationMixin, submitPaymentMixin, scrollToErrorMixin],
  props: {
    store: { type: Object, default: () => {} },
    plan: { type: Object, default: () => {} },
    footnote: { type: String, default: null },
    lightNote: { type: Boolean, default: false },
    cta: { type: String, default: "Submit" },
    showWhyNow: { type: Boolean, default: true },
  },
  data() {
    return {
      email: this.$store.state.meta.existingLogin || "",
      password: null,
    };
  },
  validations() {
    const rules = { email: { required, email }, password: { required } };

    if (
      !this.browserPaySupported ||
      (this.browserPaySupported &&
        this.creditCardChosen &&
        !this.browserPayEvent)
    ) {
      rules.creditCard = {
        cardNumber: { validStripeField },
        cardExpiry: { validStripeField },
        cardCvc: { validStripeField },
      };
    }

    return rules;
  },
  methods: {
    onBrowserPayTokenCreated(event) {
      this.browserPayEvent = event;

      if (this.browserPayEvent?.payerEmail && !this.email)
        this.email = this.browserPayEvent.payerEmail;

      if (event.error) {
        throw event.error;
      } else {
        this.usingBrowserPay = false;
        this.$v.$touch();

        if (this.$v.$invalid) {
          this.browserPayEvent.complete("fail");
          this.browserPayEvent = null;
          this.usingBrowserPay = false;
          this.error = GENERIC_FORM_ERROR;
          return;
        }
        this.usingBrowserPay = true;
        this.stripeToken = event.token;
        this.onStripeTokenSet(
          event,
          "user payment form: onBrowserPayTokenCreated"
        );
        return this.startExistingUserPayment();
      }
    },
    onComplete() {
      this.$store.state.meta.existingLogin = this.email;
      this.$emit("payment-complete");
      return Promise.resolve();
    },
    submit() {
      return this.validateForm(this.startExistingUserPayment);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/mixins.scss";

.payment-form {
  text-align: left;
  display: flex;
  flex-direction: column;

  input,
  .stripe-element,
  select {
    padding: 20px;
    border: 1px solid var(--light-gray);
    width: 100%;
  }

  .row {
    margin-top: var(--form-spacing);
  }
  .use-cc-btn {
    margin-top: 16px;
  }

  .quiz-button {
    margin-top: 32px;
    margin-bottom: 0;
  }

  .browser-pay {
    margin-top: 25px;

    .alert {
      margin: 10px 0 32px;
    }
  }

  .use-credit-card {
    margin: 12px auto 0;
  }

  .footnote {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;

    &.light-note {
      font-weight: 400;
      color: var(--medium-gray-2);
      font-size: 14px;
    }
  }

  h5.pay-with-card {
    color: var(--medium-gray);
    font-size: 16px;
    font-weight: normal;
    @include flex-row;
    margin: var(--spacing-sm) 25%;
    text-align: center;

    &:before,
    &:after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid var(--medium-gray);
      margin: auto;
    }

    &:before {
      margin-right: 10px;
    }

    &:after {
      margin-left: 10px;
    }
  }

  .information,
  .log-in {
    header {
      justify-content: space-between;
      padding-top: 28px;

      h5 {
        margin: 0;
        font-weight: bold;
        flex: 0;
        white-space: nowrap;
        font-size: 16.5px;
      }

      .button {
        padding: 0 0 0 12px;
      }
    }
  }

  label {
    font-size: 17.5px;
    text-align: left;
  }

  .cc-meta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 33px;

    & > div {
      width: 45%;
    }
  }

  .spinner {
    text-align: center;
    display: block;
    margin: 20px auto;
  }
}

@media only screen and (max-width: $xs-breakpoint) {
  .payment-form {
    .information,
    .log-in {
      header {
        .button {
          padding: 0 0 0 6px;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint) {
  .payment-form {
    flex: 1;
    @include flex-column;

    .cc-meta {
      & > div {
        width: 48%;
        align-self: flex-start;
      }
    }
  }
}
</style>
