<template>
  <div class="light-nav-bar">
    <a v-if="showBack" class="back" href="#" @click.prevent="$router.go(-1)" />
    <div class="nav-bar-logo"></div>
    <a v-if="showSkip" class="skip" href="#" @click.prevent="onSkip">Skip</a>
  </div>
</template>

<script>
export default {
  name: "LightNavBar",
  props: {
    showBack: {
      type: [Boolean, Function],
      default: true,
    },
    showSkip: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSkip() {
      this.$emit("skip", this.$router);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.light-nav-bar {
  width: 100%;
  color: var(--black);
  padding: 5px 20px;
  min-height: 70px;
  z-index: 999;
  border-top: rgba(177, 177, 177, 0.25) 1px solid;
  box-shadow: 0px 2px 3px rgba(177, 177, 177, 0.25);
  position: relative;
  z-index: 0;

  .back {
    background-image: url("../assets/back_arrow_black.svg");
    background-repeat: no-repeat;
    background-position: center left;
    position: absolute;
    left: 0px;
    top: 0px;
    display: block;
    color: var(--black);
    font-size: 20px;
    text-decoration: none;
    width: 50px;
    height: 50px;
    margin: 12px 25px;
    transition: all 0.5s ease-in-out;
    z-index: 2;
  }

  .skip {
    position: absolute;
    right: 0px;
    top: 0px;
    display: block;
    color: var(--black);
    font-size: 14px;
    text-decoration: none;
    width: 50px;
    padding: 15px 10px;
    margin: 12px 25px;
    transition: all 0.5s ease-in-out;
    z-index: 2;
  }

  .nav-bar-logo {
    background: url("../assets/lasting_from_ts_logo_black.svg");
    background-size: 80px auto;
    background-repeat: no-repeat;
    background-position: center center;
    width: 80px;
    height: 60px;
    margin: 0 auto 0;
  }
}

@media only screen and (max-width: $breakpoint) {
  .nav-bar {
    .back {
      margin: 5px 15px;
    }

    .skip {
      margin: 5px 15px;
    }
  }
}
</style>
