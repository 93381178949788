<template>
  <section v-if="percentComplete" class="quiz-progress-bar" :class="{ thin }">
    <div class="bar" :style="barStyles"></div>
  </section>
</template>

<script>
export default {
  name: "QuizProgressBar",
  props: {
    percentComplete: { type: Number, default: 0 },
    thin: { type: Boolean, default: false },
  },
  computed: {
    barStyles() {
      return {
        width: `${this.percentComplete}%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

section {
  width: 100%;
  height: 12px;

  &.thin {
    height: 7.5px;
  }
}

.bar {
  background-color: var(--orange);
  border-radius: 0 100px 100px 0;
  transition: width 0.5s;
  height: 100%;
}

@media only screen and (max-width: $breakpoint) {
}
</style>
