<template>
  <section class="customize-plan interstitial">
    <div class="content">
      <h4>
        Great! Let's continue customizing your plan. Tell us a bit about your
        relationship dynamics currently.
      </h4>
    </div>
    <quiz-button @click="goToNextRoute()">Continue</quiz-button>
  </section>
</template>

<script>
import QuizButton from "../../components/QuizButton";
import nextRouteMixin from "../../mixins/nextRoute";

export default {
  name: "CustomizePlan",
  components: { QuizButton },
  mixins: [nextRouteMixin],
};
</script>
