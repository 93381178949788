<template>
  <div class="create-account workshop-checkout">
    <workshop-banner :workshop="workshop" />
    <div class="content inner-block">
      <h4>One last thing!</h4>
      <p>
        You'll use these credentials to log into the app and manage your
        account.
      </p>
      <form novalidate @submit.prevent="submit">
        <alert-section ref="alertSection" class="alert" v-show="error">{{
          error
        }}</alert-section>
        <email-field
          class="email"
          :email="$v.email"
          placeholder="Email address"
          :disabled="isLoading"
          @change="modelFieldUpdated"
        />
        <password-field
          :password="$v.password"
          placeholder="Password (6+ characters)"
          :disabled="isLoading"
          autocomplete="new-password"
          @change="modelFieldUpdated"
        />
        <quiz-button
          type="submit"
          :solid="true"
          :loading="isLoading"
          @click="submit()"
          >Create Account</quiz-button
        >
      </form>
    </div>
  </div>
</template>

<script>
import WorkshopBanner from "@/components/WorkshopBanner";
import AlertSection from "@/components/AlertSection";
import EmailField from "@/components/EmailField";
import PasswordField from "@/components/PasswordField";
import QuizButton from "@/components/QuizButton";

import nextRouteMixin from "@/mixins/nextRoute";
import scrollToErrorMixin from "@/mixins/scrollToError";
import submitPaymentMixin, { GENERIC_FORM_ERROR } from "@/mixins/submitPayment";

import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  name: "WorkshopAccountView",
  components: {
    QuizButton,
    AlertSection,
    WorkshopBanner,
    EmailField,
    PasswordField,
  },
  mixins: [
    nextRouteMixin,
    validationMixin,
    submitPaymentMixin,
    scrollToErrorMixin,
  ],
  props: {
    store: { type: Object, default: () => {} },
    saveAnswers: { type: Boolean, default: false },
  },
  data() {
    return {
      workshop: this.$store.state.meta.workshop,
      password: null,
      email: this.getEmail(),
      isLoading: false,
      error: null,
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.$tracker.trackRegistrationComplete();
    });
  },
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(6) },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = GENERIC_FORM_ERROR;
        return;
      }

      this.isLoading = true;
      this.updateAccount()
        .then(this.accountUpdated)
        .then(this.markWebTrialStarted)
        .then(this.accountComplete)
        .catch(this.handleError);
    },
    updateAccount() {
      return this.$user.updateAccount({
        email: this.email,
        password: this.password,
      });
    },
    accountUpdated() {
      this.$store.setMeta({ email: this.email });
      return Promise.resolve();
    },
    markWebTrialStarted() {
      return this.$user.markWebTrialStarted();
    },
    accountComplete() {
      this.isLoading = false;
      this.goToNextRoute();
    },
    getEmail() {
      return this.$store.state.meta.email;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/mixins.scss";

.content {
  text-align: center;
}

.inner-block {
  padding-top: 60px;
}

h4 {
  margin: 0;
  padding: 0;
  font-size: 22px;
}

p {
  line-height: 24px;
  margin: 16px 0 0;
  padding: 0;
  font-size: 16px;
}

form {
  margin-top: var(--spacing-sm);
}

.alert {
  margin-bottom: var(--spacing-sm);
}

.email {
  margin-bottom: var(--form-spacing);
}

.quiz-button {
  margin-top: var(--spacing-sm);
}
</style>
