<template>
  <section class="download-app-btns">
    <h5>Get the app</h5>
    <download-app-button
      :is-apple="true"
      text="Download Lasting on the App Store"
    />
    <download-app-button
      :is-apple="false"
      text="Download Lasting on Google Play"
    />
  </section>
</template>

<script>
import DownloadAppButton from "@/components/DownloadAppButton";

export default {
  name: "DownloadAppButtons",
  components: { DownloadAppButton },
};
</script>
<style lang="scss" scoped>
@import "@/style/variables.scss";
.download-app-btns {
  h5 {
    text-align: left;
    margin-bottom: 0;
  }
}
</style>
