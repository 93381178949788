<template>
  <div class="nav-bar">
    <a v-if="showBack" class="back" href="#" @click.prevent="$router.go(-1)" />
    <div class="nav-bar-logo"></div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    showBack: {
      type: [Boolean, Function],
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.nav-bar {
  background: var(--black);
  width: 100%;
  color: #fff;
  padding: 8px 20px;
  min-height: 75px;
  z-index: 999;
}
.nav-bar .back {
  background-image: url("../assets/back_arrow_white.svg");
  background-repeat: no-repeat;
  background-position: center left;
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  color: var(--white);
  font-size: 20px;
  text-decoration: none;
  width: 50px;
  height: 50px;
  margin: 12px 25px;
  transition: all 0.5s ease-in-out;
  z-index: 2;
}
.nav-bar .skip {
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  width: 50px;
  padding: 15px 10px;
  margin: 12px 25px;
  transition: all 0.5s ease-in-out;
  z-index: 2;
}

.nav-bar .nav-bar-logo {
  background: url("../assets/logo_white.svg");
  background-size: 80px auto;
  background-repeat: no-repeat;
  background-position: center center;
  width: 80px;
  height: 60px;
  margin: 0 auto 0;
}

@media only screen and (max-width: $breakpoint) {
  .nav-bar {
    .back {
      margin: 5px 15px;
    }

    .skip {
      margin: 5px 15px;
    }
  }
}
</style>
