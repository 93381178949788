<template>
  <section class="repair interstitial">
    <div class="content">
      <h4 class="light-margin">
        Lasting helps you repair and nurture emotional connection.
      </h4>
      <img
        src="@/assets/couple.png"
        srcset="@/assets/couple.png 2x"
        alt="Map"
        width="170"
        height="170"
      />
      <p>
        "It's been a great relief finding the right words to say and changing my
        habits and perspective on my relationship with myself and my partner."
      </p>
      <small>
        * People who use Lasting and adopt healthy relationship habits can
        expect to see positive changes every week.
      </small>
    </div>
    <quiz-button @click="goToNextRoute()">Continue</quiz-button>
  </section>
</template>

<script>
import QuizButton from "../../components/QuizButton";
import nextRouteMixin from "../../mixins/nextRoute";

export default {
  name: "RepairAndNuture",
  components: { QuizButton },
  mixins: [nextRouteMixin],
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/mixins.scss";

p {
  margin-top: 35px;
  text-align: left;
  line-height: 25px;
}

h4 {
  margin-bottom: 25px;
}

small {
  display: block;
  text-align: left;
  line-height: 25px;
  margin-top: 35px;
}

.quiz-button {
  padding: 0;
  align-self: center;
}

@media only screen and (max-width: $xs-breakpoint) {
  p {
    margin-top: 20px;
  }
}
</style>
