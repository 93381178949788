import user from "@/user";
import tracker from "@/tracker";
import * as store from "@/store";

export const ROUTES = [
  {
    path: "/quiz",
    redirect: "/quiz/demographics",
    name: "quiz",
    beforeEnter: function (to, from, next) {
      tracker.trackQuizLoaded();
      next();
    },
    component: () => import("@/pages/FadingRouterView.vue"),
    meta: {
      quiz: true,
    },
    children: [
      {
        path: "demographics",
        redirect: "demographics/0",
        name: "demographicsQuiz",
        component: () => import("@/pages/FadingRouterView.vue"),
        children: [
          {
            path: ":index",
            name: "demographicsQuestion",
            meta: {
              questionRoute: { name: "demographicsQuestion" },
              nextRoute: { name: "motivationsQuiz" },
              quizPrereq: [],
              hideBack: (route) => route.params && route.params.index == 0,
              showProgress: true,
              showPromoBanner: true,
              sectionName: "demographics",
            },
            component: () => import("@/pages/quiz/QuizView.vue"),
            props: (route) => {
              return {
                index: parseInt(route.params.index) || 0,
                sectionName: route.meta.sectionName,
              };
            },
          },
        ],
      },
      {
        path: "motivations",
        redirect: "motivations/0",
        name: "motivationsQuiz",
        component: () => import("@/pages/FadingRouterView.vue"),
        children: [
          {
            path: ":index",
            name: "motivationsQuestion",
            meta: {
              questionRoute: { name: "motivationsQuestion" },
              nextRoute: { name: "dynamics1Quiz" },
              quizPrereq: ["demographics"],
              sectionName: "motivations",
              showPromoBanner: true,
              showProgress: true,
            },
            component: () => import("@/pages/quiz/QuizView.vue"),
            props: (route) => {
              return {
                index: parseInt(route.params.index) || 0,
                sectionName: "motivations",
              };
            },
          },
        ],
      },
      {
        path: "dynamics-1",
        redirect: "dynamics-1/0",
        name: "dynamics1Quiz",
        component: () => import("@/pages/FadingRouterView.vue"),
        children: [
          {
            path: ":index",
            name: "dynamics1Question",
            meta: {
              questionRoute: { name: "dynamics1Question" },
              nextRoute: { name: "repair" },
              quizPrereq: ["demographics", "motivations"],
              sectionName: "dynamics1",
              showProgress: true,
              showPromoBanner: true,
            },
            component: () => import("@/pages/quiz/QuizView.vue"),
            props: (route) => {
              return {
                index: parseInt(route.params.index) || 0,
                sectionName: route.meta.sectionName,
              };
            },
          },
        ],
      },
      {
        path: "repair",
        name: "repair",
        meta: {
          nextRoute: { name: "dynamics2Quiz" },
          quizPrereq: ["demographics", "motivations", "dynamics1"],
          showPromoBanner: true,
        },
        component: () => import("@/pages/quiz/RepairAndNutureView.vue"),
      },
      {
        path: "dynamics-2",
        name: "dynamics2Quiz",
        redirect: "dynamics-2/0",
        component: () => import("@/pages/FadingRouterView.vue"),
        children: [
          {
            path: ":index",
            name: "dynamics2Question",
            meta: {
              questionRoute: { name: "dynamics2Question" },
              nextRoute: { name: "createProgram" },
              quizPrereq: ["demographics", "motivations", "dynamics1"],
              sectionName: "dynamics2",
              showProgress: true,
              showPromoBanner: true,
            },
            component: () => import("@/pages/quiz/QuizView.vue"),
            props: (route) => {
              return {
                index: parseInt(route.params.index) || 0,
                sectionName: route.meta.sectionName,
              };
            },
          },
        ],
      },
      {
        path: "create-program",
        name: "createProgram",
        meta: {
          nextRoute: { name: "quizPlan" },
          quizPrereq: ["demographics", "motivations", "dynamics1", "dynamics2"],
          hideBack: true,
          showPromoBanner: true,
        },
        component: () => import("@/pages/quiz/CreateProgramView.vue"),
      },
      {
        path: "plan",
        name: "quizPlan",
        meta: {
          nextRoute: { name: "quizPayment" },
          quizPrereq: [
            "demographics",
            "motivations",
            "dynamics1",
            "dynamics2",
            "noPayment",
          ],
          hideBack: true,
          useInnerBlock: false,
          showPromoBanner: true,
          autoApplyPromo: true,
        },
        component: () => import("@/pages/quiz/PlanView.vue"),
      },

      {
        path: "payment",
        name: "quizPayment",
        meta: {
          nextRoute: { name: "quizAccount" },
          quizPrereq: [
            "demographics",
            "motivations",
            "dynamics1",
            "dynamics2",
            "noPayment",
            "noExistingLogin",
          ],
          hideBack: true,
          useInnerBlock: false,
        },
        component: () => import("@/pages/PaymentView.vue"),
      },
      {
        path: "user-payment",
        name: "quizUserPayment",
        meta: {
          nextRoute: { name: "invitePartner" },
          quizPrereq: [
            "demographics",
            "motivations",
            "dynamics1",
            "dynamics2",
            "noPayment",
            "existingLogin",
          ],
          hideBack: true,
          useInnerBlock: false,
        },
        component: () => import("@/pages/UserPaymentView.vue"),
      },
      {
        path: "account",
        name: "quizAccount",
        meta: {
          nextRoute: { name: "invitePartner" },
          quizPrereq: [
            "demographics",
            "motivations",
            "dynamics1",
            "dynamics2",
            "payment",
            "noExistingLogin",
            "loggedInAndPaid",
          ],
          hideBack: true,
        },
        props: () => {
          return {
            saveAnswers: true,
          };
        },
        component: () => import("@/pages/AccountView.vue"),
      },
      {
        path: "invite",
        name: "invitePartner",
        meta: {
          nextRoute: { name: "bookWorkshop" },
          quizPrereq: [
            "demographics",
            "motivations",
            "dynamics1",
            "dynamics2",
            "payment",
            "loggedInAndPaid",
            "partnerNotInvited",
          ],
          hideBack: true,
          useInnerBlock: true,
        },
        component: () => import("@/pages/InvitePartnerView.vue"),
      },
      {
        path: "workshops",
        name: "bookWorkshop",
        meta: {
          nextRoute: { name: "quizComplete" },
          quizPrereq: [
            "demographics",
            "motivations",
            "dynamics1",
            "dynamics2",
            "payment",
            "loggedInAndPaid",
          ],
          hideBack: true,
          useInnerBlock: true,
        },
        component: () => import("@/pages/WorkshopsView.vue"),
      },
      {
        path: "complete",
        name: "quizComplete",
        meta: {
          hideBack: true,
        },
        component: () => import("@/pages/CompleteView.vue"),
      },
    ],
  },
];

export const TEST_REDIRECT_FN = function (to, from, next) {
  if (to.meta.quizPrereq) {
    let done;
    for (const req of to.meta.quizPrereq) {
      switch (req) {
        case "demographics":
          done = store.sectionComplete("demographics");
          if (!done) {
            next({ name: "demographicsQuiz" });
            return true;
          }
          break;
        case "motivations":
          done = store.sectionComplete("motivations");
          if (!done) {
            next({ name: "motivationsQuiz" });
            return true;
          }
          break;
        case "dynamics1":
          done = store.sectionComplete("dynamics1");
          if (!done) {
            next({ name: "dynamics1Quiz" });
            return true;
          }
          break;
        case "dynamics2":
          done = store.sectionComplete("dynamics2");
          if (!done) {
            next({ name: "dynamics2Quiz" });
            return true;
          }
          break;
        case "noPayment":
          done = !store.state.meta.paymentComplete;
          if (!done) {
            next({ name: "quizAccount" });
            return true;
          }
          break;
        case "noExistingLogin":
          done = store.state.meta.existingLogin;
          if (done) {
            if (!store.state.meta.paymentComplete)
              next({ name: "quizUserPayment" });
            else next({ name: "invitePartner" });
            return true;
          }
          break;
        case "existingLogin":
          done = store.state.meta.existingLogin;
          if (!done) {
            next({ name: "quizPayment" });
            return true;
          }
          break;
        case "noEmail":
          done = store.state.meta.existingLogin || store.state.meta.email;
          if (done) {
            next({ name: "quizPlan" });
            return true;
          }
          break;
        case "payment":
          done = store.state.meta.paymentComplete;
          if (!done) {
            next({ name: "quizPayment" });
            return true;
          }
          break;
        case "loggedInAndPaid":
          done = user.isLoggedIn();
          if (!done) {
            store.state.meta.paymentComplete = false;
            next({ name: "quizPayment" });
            return true;
          }
          if (!user.user) {
            user.get().then(() => {
              next();
            });
            return true;
          }
          break;
        case "partnerNotInvited":
          if (user.isLoggedIn() && !user.user) {
            user.get().then(() => {
              done = !user.canInvitePartner();
              if (done) {
                next({ name: "bookWorkshop" });
              } else {
                next();
              }
            });
            return true;
          } else {
            done = !user.canInvitePartner();
            if (done) {
              next({ name: "bookWorkshop" });
              return true;
            }
          }
          break;
      }
    }
  }
  return false;
};

export default ROUTES;
