<template>
  <section class="workshops">
    <div v-if="loading"><loading-spinner /></div>
    <div v-if="!loading">
      <h4>Accelerate your healing with live workshops.</h4>
      <h5>Your Live Workshops</h5>
      <p>
        Therapists guide you through live exercises. Couples call it "Peloton
        for therapy."
      </p>
      <ul>
        <workshop-list-item
          v-for="event in upcomingEvents"
          :id="event.id"
          :key="event.id"
          class="workshop"
          :title="event.workshop.attributes.title"
          :description="event.workshop.attributes['preview-text']"
          :start-time="event.attributes['start-time']"
          :end-time="event.attributes['end-time']"
          :live-link="event.attributes['live-link']"
        >
          {{ event.message }}
        </workshop-list-item>
      </ul>
      <quiz-button :solid="true" @click="goToNextRoute">Next</quiz-button>
    </div>
  </section>
</template>

<script>
import dayjs from "dayjs";
import nextRouteMixin from "@/mixins/nextRoute";
import WorkshopListItem from "@/components/WorkshopListItem";
import QuizButton from "@/components/QuizButton";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  name: "WorkshopsView",
  components: { WorkshopListItem, QuizButton, LoadingSpinner },
  mixins: [nextRouteMixin],
  data() {
    return { upcomingEvents: [], loading: true };
  },
  mounted() {
    this.$user.getUpcomingWorkshops().then((event) => {
      let workshops = event.data.data;
      this.upcomingEvents = event.data.included
        .filter(
          (event) =>
            event.type === "workshop-events" &&
            event.attributes["start-time"] !== null &&
            dayjs(event.attributes["start-time"]) > dayjs()
        )
        .sort((a, b) =>
          a.attributes["start-time"] < b.attributes["start-time"] ? -1 : 1
        )
        .slice(0, 3)
        .map((event) => ({
          ...event,
          reserved: false,
          workshop: workshops.find(
            (workshop) => workshop.id == event.attributes["workshop-id"]
          ),
        }));

      if (this.upcomingEvents.length < 1) {
        this.goToNextRoute();
      } else {
        this.loading = false;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variables.scss";
.workshops {
  h4 {
    margin-bottom: 0;
  }

  h5 {
    text-align: left;
    margin: 43px 0 0;
    font-size: 16.5px;
    line-height: 17px;
  }

  p {
    text-align: left;
    margin: 5px 0 0;
    font-size: 16px;
    line-height: 25px;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 43px;
  }

  .workshop:not(:first-child) {
    margin-top: 30px;
  }
}

@media only screen and (max-width: $breakpoint) {
  .workshops {
  }
}
</style>
