<template>
  <div
    v-if="email"
    :class="{
      row: true,
      'form-group-error': email.$error,
      'form-group': true,
    }"
  >
    <input
      name="email"
      type="text"
      :placeholder="placeholder"
      v-model="localEmail"
      :disabled="disabled"
      autocomplete="email"
    />
    <div v-if="!email.required" class="error">Enter your email address</div>
    <div v-if="!email.email" class="error">Enter a valid email address</div>
  </div>
</template>
<script>
export default {
  name: "EmailField",
  props: {
    email: { type: Object, default: () => {} },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: "Email address" },
  },
  computed: {
    localEmail: {
      get() {
        return this.email.$model;
      },
      set(val) {
        this.$emit("change", this.email, val);
      },
    },
  },
};
</script>
