<template>
  <section class="progress-date interstitial">
    <div class="content">
      <h4>
        Perfect. Based on your answers, you’ll be feeling better about your
        relationship by
      </h4>
      <h5>{{ date }}</h5>
      <progress-graph class="graph" :date="date" />
    </div>
    <quiz-button @click="goToNextRoute()">Continue</quiz-button>
  </section>
</template>

<script>
import QuizButton from "@/components/QuizButton";
import ProgressGraph from "@/components/ProgressGraph";
import nextRouteMixin from "@/mixins/nextRoute";
import dayjs from "dayjs";

export default {
  name: "ProgressDate",
  components: { QuizButton, ProgressGraph },
  mixins: [nextRouteMixin],
  props: { store: { type: Object, default: () => {} } },
  computed: {
    date() {
      const newDate = dayjs().add(3, "month");
      return newDate.format("MMMM D");
    },
  },
  mounted() {
    if (!this.$store.quizCompleted) {
      this.$store.quizCompleted = true;
      // this.$tracker.track("Quiz Completed");
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variables.scss";
h4 {
  margin-bottom: 60px;
}

h5 {
  color: var(--orange);
  text-transform: none;
  font-size: 30px;
  font-weight: normal;
  margin: 0 0 60px;
}
</style>
