const nextRouteMixin = {
  methods: {
    goToNextRoute() {
      let nextRoute = this.$router.currentRoute.meta.nextRoute;
      nextRoute =
        typeof nextRoute === "function" ? nextRoute(this.$router) : nextRoute;
      return this.$router.push(nextRoute).catch(() => {});
    },
  },
};

export default nextRouteMixin;
