<template>
  <div class="testimonials-wrapper">
    <div class="inner-block" style="padding-top: 0">
      <div class="content header">
        <h5>{{ title }}</h5>
      </div>
    </div>

    <div class="testimonial-swiper">
      <swiper :options="swiperOption" ref="slider" @slideChange="onSlideChange">
        <swiper-slide
          v-for="(testimonial, index) in testimonials"
          :key="'testimonial-' + index"
        >
          <testimonial-item :testimonial="testimonial"></testimonial-item>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
        <button class="swiper-button-prev" slot="button-prev">
          <img src="@/assets/left_caret.svg" alt="left-arrow" />
        </button>
        <button class="swiper-button-next" slot="button-next">
          <img src="@/assets/right_caret.svg" alt="right-arrow" />
        </button>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";
import testimonialsData from "../data/testimonials";
import TestimonialItem from "./TestimonialItem.vue";

export default {
  name: "TestimonialsComponent",
  components: {
    Swiper,
    SwiperSlide,
    TestimonialItem,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      title: "What people are saying",
      testimonials: testimonialsData,
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          320: {
            slidesPerView: 1.15,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
        },
      },
      showLeft: false,
      showRight: true,
    };
  },
  methods: {
    slideNext() {
      this.$nextTick(() => {
        this.$refs.slider.$swiper.slideNext();
      });
    },
    slidePrev() {
      this.$nextTick(() => {
        this.$refs.slider.$swiper.slidePrev();
      });
    },
    onSlideChange() {
      const slider = this.$refs.slider.$swiper;
      if (slider.activeIndex === 0) {
        this.showLeft = false;
        return;
      }
      if (slider.activeIndex === this.testimonials.length - 1) {
        this.showRight = false;
        return;
      }
      this.showLeft = true;
      this.showRight = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  border-top: 1px solid var(--light-gray);
}
.testimonials-wrapper {
  max-width: 480px;
  margin: 0 auto;
  padding: 20px 0;

  @media (max-width: 767.98px) {
    padding: 20px 0 0 0;
  }
}
.testimonial-swiper {
  padding: 70px 40px 40px 40px;
  width: 100%;
  display: block;
  position: relative;

  @media (max-width: 767.98px) {
    padding: 40px 0;
  }
}

.swiper-button-prev,
.swiper-button-next {
  &:after {
    display: none;
  }
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

.swiper-button-prev {
  left: 0;
  transform: translateX(-150%);
}
.swiper-button-next {
  right: 0;
  transform: translateX(150%);
}

@media (max-width: 767.98px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: -32px;
}

button {
  background: none;
  border: none;
}
</style>

<style lang="scss">
.testimonial-swiper {
  overflow-x: hidden;
  overflow-y: auto;

  .swiper-container {
    overflow: visible;
  }
}

.swiper-pagination-bullet-active {
  background-color: black;
}

@media (max-width: 767.98px) {
  .swiper-wrapper {
    padding-left: 20px;
  }
}
</style>
