<template>
  <section class="credit-card-fields">
    <StripeElements :stripe-key="stripeKey" #default="{ elements }" ref="elms">
      <div
        :class="{
          'form-group-error': model.cardNumber.$error,
          'form-group': true,
          row: true,
        }"
      >
        <StripeElement
          type="cardNumber"
          :elements="elements"
          :options="stripeOptions"
          ref="card"
          class="stripe-element card-number inspectlet-sensitive"
          :disabled="isLoading"
          @change="onCardChange(model.cardNumber, $event)"
          @blur="onCardBlur(model.cardNumber, $event)"
        />
        <div v-if="!model.cardNumber.validStripeField" class="error">
          {{
            getStripeErrorMessage(model.cardNumber.$model) ||
            "Valid credit card number required"
          }}
        </div>
      </div>
      <div class="cc-meta row">
        <div
          :class="{
            'form-group-error': model.cardExpiry.$error,
            'form-group': true,
          }"
        >
          <StripeElement
            type="cardExpiry"
            :elements="elements"
            :options="stripeOptions"
            ref="cardExpiry"
            class="stripe-element card-expiry inspectlet-sensitive"
            :disabled="isLoading"
            @change="onCardChange(model.cardExpiry, $event)"
            @blur="onCardBlur(model.cardExpiry, $event)"
          />
          <div v-if="!model.cardExpiry.validStripeField" class="error">
            {{
              getStripeErrorMessage(model.cardExpiry.$model) ||
              "Valid expiration date required"
            }}
          </div>
        </div>
        <div
          :class="{
            'form-group-error': model.cardCvc.$error,
            'form-group': true,
          }"
        >
          <StripeElement
            type="cardCvc"
            :elements="elements"
            :options="stripeOptions"
            ref="cardCvc"
            class="stripe-element card-cvc inspectlet-sensitive"
            :disabled="isLoading"
            @change="onCardChange(model.cardCvc, $event)"
            @blur="onCardBlur(model.cardCvc, $event)"
          />
          <div v-if="!model.cardCvc.validStripeField" class="error">
            {{
              getStripeErrorMessage(model.cardCvc.$model) ||
              "Valid CVC required"
            }}
          </div>
        </div>
      </div>
    </StripeElements>
  </section>
</template>

<script>
import { StripeElement, StripeElements } from "vue-stripe-elements-plus";

export default {
  name: "CreditCardFields",
  components: {
    StripeElement,
    StripeElements,
  },
  props: {
    store: {
      type: Object,
      default: () => ({ cardNumber: null, cardExpiry: null, cardCvc: null }),
    },
    model: { type: Object, default: () => {} },
    isLoading: { type: Boolean, default: false },
  },
  computed: {
    stripeOptions() {
      return {
        style: {
          base: {
            iconColor: "#1d1d1d",
            color: "#1d1d1d",
            fontFamily: '"Noto Sans", sans-serif',
            fontWeight: "300",
            fontSize: "18px",
            "::placeholder": {
              color: "#949494",
              fontWeight: "300",
            },
          },
          invalid: {
            color: "#1d1d1d",
            iconColor: "#FF0000",
          },
        },
        disabled: this.isLoading,
      };
    },
  },
  data() {
    return {
      stripeKey: process.env.VUE_APP_STRIPE_KEY,
    };
  },
  methods: {
    getStripeErrorMessage(model) {
      return (model && model.error && model.error.message) || null;
    },
    onCardChange(field, event) {
      field.$model = event;
      this.$emit("change", field, event);
    },
    onCardBlur(field, event) {
      field.$touch();
      this.$emit("blur", field, event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/mixins.scss";
.credit-card-fields {
  input,
  .stripe-element,
  select {
    padding: 20px;
    width: 100%;
  }

  .row {
    margin-top: var(--form-spacing);

    &.has-badge {
      margin-top: 18px;
    }
  }

  label {
    font-size: 17.5px;
    text-align: left;
  }

  .cc-meta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: var(--form-spacing);

    & > div {
      width: 45%;
    }
  }
}

@media only screen and (max-width: $breakpoint) {
  .credit-card-fields {
    .row {
      margin-top: var(--form-spacing);
    }

    .cc-meta {
      & > div {
        width: 48%;
        align-self: flex-start;
      }
    }
  }
}
</style>
