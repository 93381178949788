<template>
  <div v-if="$route" id="app" class="flex-column">
    <component :is="layout" />
  </div>
</template>

<script>
const defaultLayout = "bordered-page";
const THEME = "/theme/workshop.css";
export default {
  name: "AppWorkshop",
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
  },
  mounted() {
    this.applyTheme(THEME);
  },
  methods: {
    applyTheme(theme) {
      const head = document.head;
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = theme;
      head.appendChild(link);
    },
  },
};
</script>

<style lang="scss">
@import "@/style/global";
@import "@/style/mixins";

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
}

body {
  background: #f8f8f8;
  @include noto;
  position: relative;
  padding: 0;
  margin: 0;
}

#app {
  min-height: 100vh;
}
</style>
