<template>
  <section class="couples-helped interstitial">
    <div class="content">
      <h4 class="light-margin">You're not alone</h4>
      <p>We've helped <b>1,339,394</b> couples form happier relationships.</p>
      <img
        src="@/assets/heart_map_2x.png"
        srcset="@/assets/heart_map_2x.png 2x"
        alt="Map"
      />
    </div>
    <quiz-button @click="goToNextRoute()">Continue</quiz-button>
  </section>
</template>

<script>
import QuizButton from "../../components/QuizButton";
import nextRouteMixin from "../../mixins/nextRoute";

export default {
  name: "CouplesHelped",
  components: { QuizButton },
  mixins: [nextRouteMixin],
};
</script>
<style lang="scss" scoped>
@import "@/style/variables.scss";
h4 {
  margin-bottom: 30px;
}

p {
  text-align: left;
  margin-bottom: 40px;
}

img {
  width: 100%;
  min-height: 200px;
}

@media only screen and (max-width: $breakpoint) {
  p {
    margin-bottom: 30px;
  }

  img {
    min-height: 200px;
  }
}

@media only screen and (max-width: $breakpoint) {
  p {
    margin-bottom: 30px;
  }

  img {
    min-height: 150px;
  }
}
</style>
