<template>
  <div
    class="d-flex justify-content-center"
    :class="{
      highlights: true,
      'show-border': showBorder,
    }"
  >
    <ul class="list-style-check">
      <li>Included in your plan:</li>
      <li><span>Two spots (you, your partner)</span></li>
      <li><span>Hundreds of sessions</span></li>
      <li><span>Live, expert-led workshops</span></li>
      <li><span>New content every week</span></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PlanHighlights",
  props: { showBorder: { type: Boolean, default: true } },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/mixins.scss";

.highlights {
  text-align: left;

  &.show-border {
    border-top: 1px solid var(--light-gray);
    padding-top: 28px;
    margin-top: 32px;
  }

  ul {
    list-style: none;
    padding-left: 0;
    list-style-image: url("../assets/check.svg");
    margin: 0 auto;

    li {
      list-style-position: inside;
      padding-bottom: 30px;
    }

    li:first-child {
      list-style: none;
      margin-top: 0;
      margin-bottom: 30px;
      font-weight: bold;
      padding-bottom: 0;
    }

    &.list-style-arrow {
      max-width: 62%;
      li:first-child {
        padding-left: 28px;
        padding-right: 28px;
        text-align: center;
      }
    }

    &.list-style-check {
      max-width: 60%;
      li span {
        margin-left: 8px;
      }

      li:first-child {
        padding-left: 34px;
      }
    }
  }
}

@media only screen and (max-width: $xs-breakpoint) {
  .highlights {
    ul {
      margin: 0 5px;

      &.list-style-arrow,
      &.list-style-check {
        max-width: 90%;
        margin: 0 auto;
      }

      &.list-style-check li {
        &:not(:first-child):not(:last-child) {
          padding-bottom: 24px;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>
