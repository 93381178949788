<template>
  <form class="create-account checkout" novalidate @submit.prevent="submit">
    <div class="content">
      <h4>
        Congrats! Now complete your registration by confirming your email and
        password.
      </h4>
      <alert-section class="alert" v-show="error" ref="alertSection">
        {{ error }}
      </alert-section>
      <email-field
        class="email"
        :email="$v.email"
        placeholder="Email address"
        :disabled="isLoading"
        @change="modelFieldUpdated"
      />
      <password-field
        :password="$v.password"
        placeholder="Password (6+ characters)"
        :disabled="isLoading"
        autocomplete="new-password"
        @change="modelFieldUpdated"
      />
      <small
        >By continuing, you agree to our
        <a href="https://getlasting.com/terms" target="_blank">Terms</a>
        and
        <a href="https://getlasting.com/privacy-policy" target="_blank"
          >Privacy Policy</a
        >
      </small>
    </div>
    <quiz-button type="submit" :solid="true" :loading="isLoading"
      >Sign Up</quiz-button
    >
  </form>
</template>

<script>
import QuizButton from "@/components/QuizButton";
import AlertSection from "@/components/AlertSection";
import EmailField from "@/components/EmailField";
import PasswordField from "@/components/PasswordField";

import nextRouteMixin from "@/mixins/nextRoute";
import submitPaymentMixin, { GENERIC_FORM_ERROR } from "@/mixins/submitPayment";
import scrollToErrorMixin from "@/mixins/scrollToError";

import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  name: "AccountView",
  components: { QuizButton, AlertSection, EmailField, PasswordField },
  mixins: [
    nextRouteMixin,
    validationMixin,
    submitPaymentMixin,
    scrollToErrorMixin,
  ],
  props: {
    store: { type: Object, default: () => {} },
    saveAnswers: { type: Boolean, default: false },
  },
  data() {
    return {
      password: null,
      email: this.getEmail(),
      isLoading: false,
      error: null,
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.$tracker.trackRegistrationComplete();
    });
  },
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(6) },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = GENERIC_FORM_ERROR;
        return;
      }

      this.isLoading = true;
      this.updateAccount()
        .then(this.accountUpdated)
        .then(this.markWebTrialStarted)
        .then(this.submitAnswers)
        .then(this.accountComplete)
        .catch(this.handleError);
    },
    updateAccount() {
      return this.$user.updateAccount({
        email: this.email,
        password: this.password,
      });
    },
    accountUpdated() {
      this.$store.setMeta({ email: this.email });
      return Promise.resolve();
    },
    markWebTrialStarted() {
      return this.$user.markWebTrialStarted();
    },
    submitAnswers() {
      if (this.saveAnswers) {
        const answers = this.$store.batchAnswers();
        return this.$user.batchSubmit(answers);
      }
      return Promise.resolve();
    },
    accountComplete() {
      this.isLoading = false;
      this.goToNextRoute();
    },
    getEmail() {
      return this.$store.state.meta.email;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variables.scss";

.content {
  text-align: left;
}

p {
  line-height: 25px;
}

.alert {
  margin-bottom: 24px;
}

.email {
  margin-bottom: var(--form-spacing);
}

small {
  display: block;
  color: var(--medium-gray);
  padding-top: 10px;
  padding-bottom: 35px;
}

@media only screen and (max-width: $breakpoint) {
  .content {
    justify-content: space-between;
    padding-top: 25px;
  }

  .quiz-button {
    margin-top: 10px;
    margin-bottom: 10px;
    flex: 0;
  }
}
</style>
