<template>
  <section class="create-program interstitial">
    <div class="content">
      <h4 class="light-margin">We're creating your personalized program</h4>
      <progress-bar
        label="Analyzing your relationship dynamics..."
        :active="getDynamicsActive"
        @complete="dynamicsComplete()"
      />
      <progress-bar
        label="Analyzing your demographic profile..."
        class="demo-progress"
        :active="getDemographicsActive"
        @complete="demoComplete()"
      />
      <h5>Few more things...</h5>
      <text-slideshow :items="phrases" />
    </div>
  </section>
</template>

<script>
import ProgressBar from "../../components/ProgressBar";
import TextSlideshow from "../../components/TextSlideshow";
import nextRouteMixin from "../../mixins/nextRoute";

const PHRASES = [
  "Lasting is based on real psychology",
  "Our relationship program is based on behavior change",
  "It only takes 10 minutes a day",
  "We'll give you daily sessions and conversations that focus on emotional connection",
  "We're going to change your relationship with yourself and your partner",
  "If you have a setback, that's okay. No one's perfect!",
  "We'll help you get back on track.",
];

export default {
  name: "CreateProgram",
  components: { ProgressBar, TextSlideshow },
  mixins: [nextRouteMixin],
  data: function () {
    return {
      dynamicsActive: true,
      demographicsActive: false,
      phrases: PHRASES,
    };
  },
  computed: {
    getDynamicsActive() {
      return this.dynamicsActive;
    },
    getDemographicsActive() {
      return this.demographicsActive;
    },
  },
  methods: {
    dynamicsComplete() {
      this.demographicsActive = true;
    },
    demoComplete() {
      this.goToNextRoute();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";
@import "@/style/mixins";

.demo-progress {
  margin-top: 50px;
}

h4 {
  @include merriweather;
}

h5 {
  margin-top: 70px;
  margin-bottom: 6px;
}

@media only screen and (max-width: $breakpoint) {
  .demo-progress {
    margin-top: 35px;
  }

  h5 {
    margin-top: 60px;
  }
}
</style>
