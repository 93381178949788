import Vue from "vue";
import App from "./AppWorkshop.vue";
import VueRouter from "vue-router";

import user from "./user";
import tracker from "./tracker";
import * as store from "./store";
import router from "@/routes/workshop-router";

Vue.config.productionTip = false;

Vue.use(VueRouter);

import BlankLayout from "@/layout/Blank";
import BorderedPageLayout from "@/layout/BorderedPage";
Vue.component("BlankLayout", BlankLayout);
Vue.component("BorderedPageLayout", BorderedPageLayout);

Vue.use(store);
Vue.use(tracker);
Vue.use(user);
store.setCacheName("workshop");

new Vue({
  router,
  data: {
    store,
  },
  render: (h) => h(App),
}).$mount("#app");
