export const getGenderDescriptor = (gender = null) => {
  switch (gender) {
    case "male":
      return "men";
    case "female":
      return "women";
    default:
      return "people";
  }
};

export const getAgeDescriptor = (age = null) => {
  switch (age) {
    case "under 20":
      return "under 20";
    case "20s":
    case "30s":
    case "40s":
    case "50s":
    case "60s":
      return `in their ${age}`;
    default:
      return "of different ages";
  }
};

export const getChildrenDescriptor = (hasChildren) => {
  return hasChildren ? "who have children" : "";
};

export default {
  getGenderDescriptor,
  getAgeDescriptor,
  getChildrenDescriptor,
};
