<template>
  <div class="invite-partner-button">
    <component
      :is="component"
      :solid="solid"
      class="button"
      type="button"
      :href="emailLink"
      target="_blank"
      @click="clickHandler"
    >
      {{ buttonText }}
    </component>
  </div>
</template>

<script>
import sharingMixin from "@/mixins/sharing";

export default {
  name: "InvitePartnerButton",
  mixins: [sharingMixin],
  props: {
    component: { type: [String, Object], default: "button" },
    buttonText: { type: String, default: "Invite My Partner" },
    solid: { type: Boolean, default: true },
    text: { type: String, default: null },
    url: { type: String, default: null },
    title: { type: String, default: "Join me on Lasting!" },
  },
  data() {
    return {
      clickHandler: navigator.canShare ? this.openShare : this.openEmailLink,
    };
  },
  computed: {
    emailLink() {
      return !this.shareSupported
        ? this.generateShareEmailLink({
            text: this.text,
            title: this.title,
          })
        : null;
    },
  },
  methods: {
    openEmailLink() {
      this.$tracker.trackEvent("Partner Invitation Partner Tapped", {
        "Button Text": this.buttonText,
        "Button Type": "Email",
      });
      this.$emit("shared");
    },
    openShare() {
      this.$tracker.trackEvent("Button to Invite Partner Tapped", {
        "Button Text": this.buttonText,
        "Button Type": "Web Share",
      });
      return this.openShareDialog({
        title: this.title,
        text: this.text,
        url: this.url,
      })
        .then(() => {
          this.$tracker.trackEvent("Message to Invite Partner Sent");
          this.$emit("shared");
        })
        .catch(() => {
          this.$tracker.trackEvent("Message to Invite Partner Cancelled");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variables.scss";

.invite-partner-button {
  width: 100%;

  .link {
    display: block;
  }
}
</style>
