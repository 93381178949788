<template>
  <transition name="modal" class="alert-modal">
    <div ref="mask" class="modal-mask" @click="overlayClick">
      <div ref="wrapper" class="modal-wrapper">
        <div class="modal-container">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AlertModal",
  methods: {
    overlayClick(event) {
      if (
        event.target === this.$refs.mask ||
        event.target === this.$refs.wrapper
      ) {
        this.$emit("overlay-click");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(5, 5, 5, 0.5);
  display: flex;
  flex-direction: row;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}

.modal-container {
  flex: 0;
  width: 400px;
  margin: 0px auto;
  padding: 26px 22px 18px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: transform 0.3s ease;
  border-radius: 5px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media only screen and (max-width: $breakpoint) {
  .modal-container {
    max-width: 400px;
  }
}

@media only screen and (max-width: $xs-breakpoint) {
  .modal-container {
    max-width: 90%;
  }
}
</style>
