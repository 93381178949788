<template>
  <section class="workshop-not-found">
    <img
      src="../../assets/bird_searching.svg"
      width="140"
      height="140"
      alt="Workshop not found"
    />
    <h1>We can't find this workshop</h1>
    <p>
      Take our quiz to get your personalized program of self-guided counseling
      sessions and therapist-led workshops.
    </p>
    <workshop-button @click="onClick">Take the quiz</workshop-button>
  </section>
</template>

<script>
import dayjs from "dayjs";
import WorkshopButton from "@/components/WorkshopButton";

export default {
  name: "NotFoundView",
  components: {
    WorkshopButton,
  },
  mounted() {
    const { title, id, startTime } = this.$store.state.meta.workshop;
    const reasons = [];
    if (!title) {
      reasons.push("No workshop title");
    } else if (title.length < 4) {
      reasons.push("Workshop title too short");
    }
    if (!id) {
      reasons.push("No workshop id");
    } else if (!id.match(/^\d+$/)) {
      reasons.push("Workshop id not a number");
    }
    if (!startTime) {
      reasons.push("No workshop start time");
    } else if (!dayjs(startTime).isValid()) {
      reasons.push("Workshop start time invalid");
    }
    this.$tracker.trackWorkshopNotFound({
      "query string": this.$store.state.meta.queryString,
      reasons,
    });
    this.$store.setMeta({ workshop: null });
  },
  methods: {
    onClick() {
      const params = {};
      let paramString = "";
      if (this.$store.state.meta.existingLogin) {
        params.login = this.$store.state.meta.existingLogin;
      } else if (this.$store.state.meta.email) {
        params.email = this.$store.state.meta.email;
      }
      if (Object.keys(params).length) {
        paramString =
          "?" +
          Object.keys(params)
            .map((key) => key + "=" + encodeURIComponent(params[key]))
            .join("&");
      }
      window.location.href = `/#/quiz?${paramString}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/style/variables.scss";
@import "@/style/mixins.scss";
.workshop-not-found {
  @include flex-column;
  align-items: flex-start;
  text-align: center;

  img {
    align-self: center;
  }

  h1 {
    font-size: 20px;
    line-height: 30px;
    padding: 0;
    margin: 40px 0 0;
    width: 100%;
  }

  p {
    font-size: 16px;
    padding: 0;
    margin: 24px 0 0;
  }

  .workshop-button {
    margin: 27px auto 10px;
    width: 197px;
  }
}
</style>
