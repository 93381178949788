<template>
  <li class="workshop-list-item" :data-id="id">
    <div class="starts-in">{{ startsIn }}</div>
    <h6>{{ title }}</h6>
    <div class="date">{{ date }}</div>
    <p>{{ description }}</p>
    <quiz-button
      :class="{ 'reserve-button': true, selected: reserved }"
      type="button"
      :solid="false"
      :loading="loading"
      :disabled="reserved"
      @click="reserveWorkshop"
      >{{ buttonText
      }}<span v-if="reserved" class="check">&#10003;</span></quiz-button
    >
    <alert-section v-if="error" class="alert">{{ error }}</alert-section>
    <add-to-calendar-modal
      v-if="showModal"
      :id="id"
      :title="title"
      :description="description"
      :start-time="startTime"
      :end-time="endTime"
      :location="liveLink"
      @close="closeModal"
    />
  </li>
</template>

<script>
import dayjs from "dayjs";
import QuizButton from "@/components/QuizButton";
import AlertSection from "@/components/AlertSection";
import AddToCalendarModal from "@/components/AddToCalendarModal";

export default {
  name: "WorkshopListItem",
  components: { QuizButton, AlertSection, AddToCalendarModal },
  props: {
    selected: { type: Boolean, default: false },
    id: { type: String, default: "" },
    title: { type: String, default: "" },
    description: { type: String, default: "" },
    startTime: { type: String, default: null },
    endTime: { type: String, default: null },
    liveLink: { type: String, default: null },
  },
  data: function () {
    return {
      reserved: false,
      loading: false,
      error: null,
      showModal: false,
    };
  },
  computed: {
    buttonText() {
      return this.reserved ? "Spot reserved" : "Reserve my spot";
    },
    startsIn() {
      const eventDate = dayjs(this.startTime);
      const now = dayjs();
      let timeUntil = null;

      if (eventDate > now && eventDate <= now.add(60, "minute")) {
        timeUntil = eventDate.diff(now, "minute");
        return `Starts in ${timeUntil} min${timeUntil > 1 ? "s" : ""}`;
      }

      if (eventDate > now && eventDate <= now.add(12, "hour")) {
        timeUntil = eventDate.diff(now, "hour");
        return `Starts in ${timeUntil} hr${timeUntil > 1 ? "s" : ""}`;
      }

      timeUntil = Math.round(eventDate.diff(now, "day", true));
      return `Starts in ${timeUntil} day${timeUntil > 1 ? "s" : ""}`;
    },
    date() {
      const eventDate = dayjs(this.startTime);
      const now = dayjs();
      if (eventDate.format("MM/DD/YYYY") === now.format("MM/DD/YYYY"))
        return `Today @ ${eventDate.format("h:mm A")}`;
      if (
        eventDate.format("MM/DD/YYYY") ===
        now.add(1, "day").format("MM/DD/YYYY")
      )
        return `Tomorrow @ ${eventDate.format("h:mm A")}`;
      return eventDate.format("MMM D @ h:mm A");
    },
  },
  methods: {
    reserveWorkshop() {
      this.loading = true;
      this.error = null;
      this.$user
        .purchaseWorkshop(this.id)
        .then(() => {
          this.loading = false;
          this.reserved = true;
          this.showModal = true;
        })
        .catch(this.handleAPIError);
    },
    handleAPIError(err) {
      let response = err.response;
      let errors =
        response && response.data && response.data.errors
          ? response.data.errors
          : [];
      let errorMessage =
        "An error occurred booking this workshop. Please try again.";

      this.loading = false;

      if (errors.length) {
        errorMessage =
          typeof errors[0] === "object" ? JSON.stringify(errors[0]) : errors[0];
      }
      // Handle case where workshop was previously booked by this user
      if (errorMessage && errorMessage.includes("Workshop event Congrats")) {
        errorMessage = null;
        this.reserved = true;
        this.showModal = true;
      }
      this.error = errorMessage;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixins.scss";
@import "@/style/variables.scss";

.workshop-list-item {
  border: 1px solid var(--light-gray);
  box-shadow: 2px 2px 3px 2px #e0e0e080;
  padding: 25px 18px 30px;
  @include flex-column;
  width: auto;

  & > * {
    align-self: flex-start;
  }

  .starts-in {
    border: 0.5px solid rgba(71, 192, 44, 0.6);
    border-radius: 2px;
    color: #47c02c;
    flex: 0;
    font-size: 11.5px;
    font-weight: 700;
    padding: 6px;
  }

  h6 {
    font-size: 20px;
    line-height: 26px;
    font-weight: 700px;
    margin: 15px 0 0;
    text-align: left;
  }

  .date {
    font-size: 14px;
    line-height: 11.5px;
    color: #999999;
    font-weight: 700;
    margin-top: 16px;
  }

  p {
    margin: 10px 0 0;
    font-size: 14px;
    line-height: 25px;
  }

  .reserve-button {
    align-self: center;
    font-size: 14px;
    font-weight: bold;
    margin: 22px 0 0;
    line-height: 20px;
    border-radius: 25px;
    padding: 10px 51px;
    width: auto;
    display: block;
    flex: 0 0 auto;

    &[disabled] {
      opacity: 1;
    }

    &.is-loading {
      padding-right: 45px;
      right: 0;
    }

    .check {
      padding-left: 5px;
      color: var(--white);
    }

    &.selected {
      background-color: var(--orange);
      color: var(--white);
    }
  }

  p {
    text-align: left;
  }

  .alert {
    margin-top: 25px;
  }
}

@media only screen and (max-width: $breakpoint) {
  .workshop-list-item {
  }
}
</style>
