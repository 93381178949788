<template>
  <alert-modal class="why-now-modal">
    <p>
      We ask for your payment information now so you can enjoy Lasting
      uninterrupted after your trial ends.
    </p>
    <p>
      If you cancel anytime before the end of the 7-day trial, you won't be
      charged.
    </p>
    <quiz-button :solid="true" @click="$emit('close')">Got it</quiz-button>
  </alert-modal>
</template>

<script>
import QuizButton from "@/components/QuizButton";
import AlertModal from "@/components/AlertModal";

export default {
  name: "WhyNowModal",
  components: {
    QuizButton,
    AlertModal,
  },
};
</script>

<style lang="scss" scoped>
.why-now-modal {
  p {
    font-size: 15px;
    line-height: 25px;
    margin: 0 7px;
    text-align: center;

    &:not(:first-child) {
      margin-top: 32px;
    }
  }

  .quiz-button {
    margin: 26px 0 0;
  }
}
</style>
