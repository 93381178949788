<template>
  <section class="booking-confirmation workshop-checkout">
    <div v-if="isLoading" class="inner-block">
      <loading-spinner />
    </div>
    <template v-if="!isLoading">
      <workshop-banner :workshop="workshop" />
      <div class="content inner-block">
        <input type="text" id="workshop-link" :value="liveLink" />
        <template v-if="workshopBeginsLater">
          <h4>You're booked!</h4>
          <img
            class="check"
            src="@/assets/checkmark.svg"
            width="48"
            height="48"
            alt="checkmark"
          />
          <p>
            15 minutes before the workshop begins, you'll receive a link in your
            email to join.
          </p>
          <invite-partner-button
            v-if="canInvitePartner"
            :component="buttonComponent"
            class="button top"
            button-text="Invite my partner"
            :text="partnerShare.text"
            :title="partnerShare.title"
          />
          <workshop-button class="button" :solid="false" @click="addToCalendar"
            >Add to calendar</workshop-button
          >
        </template>
        <template v-if="workshopBeginsSoon">
          <h4>You're booked!</h4>
          <img
            class="check"
            src="@/assets/checkmark.svg"
            width="48"
            height="48"
            alt="checkmark"
          />
          <p>
            This workshop starts in {{ minutesUntilStart }} minutes. Ready to
            join?
          </p>
          <workshop-button class="button top" :href="liveLink" target="_blank"
            >Join workshop</workshop-button
          >
          <workshop-button class="button" :solid="false" @click="copyLink">{{
            copyLinkText
          }}</workshop-button>
        </template>
        <template v-if="workshopStarted">
          <h4>You're booked!</h4>
          <img
            class="check"
            src="@/assets/checkmark.svg"
            width="48"
            height="48"
            alt="checkmark"
          />
          <p>This workshop just started, but there's still time to join!</p>
          <workshop-button class="button top" :href="liveLink" target="_blank"
            >Join workshop</workshop-button
          >
          <workshop-button class="button" :solid="false" @click="copyLink">{{
            copyLinkText
          }}</workshop-button>
        </template>
        <template v-if="workshopPassed">
          <h4>Congrats!</h4>
          <img
            class="check"
            src="@/assets/checkmark.svg"
            width="48"
            height="48"
            alt="checkmark"
          />
          <p>
            Now you can view the workshop on-demand and access hundreds more in
            the app
          </p>
          <workshop-button
            class="button top"
            v-if="recordingLink"
            :href="recordingLink"
            target="_blank"
            >View workshop</workshop-button
          >
          <invite-partner-button
            :class="{ button: true, top: !recordingLink }"
            :solid="!recordingLink"
            v-if="canInvitePartner"
            :component="buttonComponent"
            button-text="Invite my partner"
            class="button"
            :text="partnerShare.text"
            :title="partnerShare.title"
          />
        </template>
        <download-app-buttons-workshop class="download" />
      </div>
    </template>
  </section>
</template>

<script>
import dayjs from "dayjs";
import WorkshopBanner from "@/components/WorkshopBanner";
import WorkshopButton from "@/components/WorkshopButton";
import LoadingSpinner from "@/components/LoadingSpinner";
import InvitePartnerButton from "@/components/InvitePartnerButton";
import DownloadAppButtonsWorkshop from "@/components/DownloadAppButtonsWorkshop";
import nextRouteMixin from "@/mixins/nextRoute";
import fileMixin from "@/mixins/file";
import sharingMixin from "@/mixins/sharing";

export default {
  name: "WorkshopBookingConfirmation",
  components: {
    WorkshopButton,
    WorkshopBanner,
    LoadingSpinner,
    DownloadAppButtonsWorkshop,
    InvitePartnerButton,
  },
  mixins: [nextRouteMixin, fileMixin, sharingMixin],
  props: {
    store: { type: Object, default: () => {} },
    saveAnswers: { type: Boolean, default: false },
  },
  data() {
    return {
      isLoading: true,
      error: null,
      workshop: this.$store.state.meta.workshop,
      workshopBeginsLater: false,
      workshopBeginsSoon: false,
      workshopStarted: false,
      workshopPassed: false,
      minutesUntilStart: 0,
      countdownTimeout: null,
      liveLink: null,
      recordingLink: null,
      copyLinkText: "Copy workshop link",
      copyTimeout: null,
      canInvitePartner: this.$user.canInvitePartner(),
      buttonComponent: WorkshopButton,
      partnerShare: {
        message: "Join me for this couples workshop on Lasting!",
        text: null,
      },
    };
  },
  created() {
    this.partnerShare.text = `I just booked us a free couples workshop with the Lasting app. Download the app, and use this code to pair with my account.\n\n${this.$user.user["partner-code"]}\n\n${this.invitePartnerUrl}`;
  },
  mounted() {
    this.bookWorkshop();
  },
  unmounted() {
    if (this.copyTimeout) {
      clearTimeout(this.copyTimeout);
    }
    if (this.countdownTimeout) {
      clearInterval(this.countdownTimeout);
    }
  },
  methods: {
    bookWorkshop() {
      return this.$user
        .purchaseWorkshop(this.workshop.id)
        .then(this.$user.getPurchasedWorkshops)
        .then(this.purchasedWorkshopsLoaded)
        .then(this.$user.getUpcomingWorkshops)
        .then(this.upcomingWorkshopsLoaded)
        .then(this.updateTime)
        .then(this.workshopCallsComplete)
        .catch(this.handleBookingError);
    },
    // If the workshop was already booked, then the resulting request won't have the details
    // of the workshop, so make a call to fetch all workshops so all datapoints are available
    handleWorkshopAlreadyBooked() {
      this.$user
        .getPurchasedWorkshops()
        .then(this.purchasedWorkshopsLoaded)
        .then(this.$user.getUpcomingWorkshops)
        .then(this.upcomingWorkshopsLoaded)
        .then(this.updateTime)
        .then(this.workshopCallsComplete)
        .catch(this.handleBookingError);
    },
    workshopBookingError() {
      this.$router.push({ name: "workshopBookingError" });
    },
    purchasedWorkshopsLoaded(event) {
      let workshops = event.data.data;
      let workshopDetails = workshops.find(
        (workshop) =>
          workshop.attributes["workshop-event-id"] == this.workshop.id
      );
      if (workshopDetails && workshopDetails.attributes) {
        this.liveLink = workshopDetails.attributes["live-link"];
        this.recordingLink = workshopDetails.attributes["recording-link"];
      }

      return Promise.resolve();
    },
    upcomingWorkshopsLoaded(event) {
      let loadedEvent = event.data.included.find(
        (event) =>
          event.type === "workshop-events" && event.id == this.workshop.id
      );

      let loadedWorkshop = loadedEvent
        ? event.data.data.find((event) => {
            return (
              event.type === "workshops" &&
              event.id == loadedEvent.attributes["workshop-id"]
            );
          })
        : null;

      if (loadedEvent && loadedWorkshop) {
        this.liveLink = loadedEvent.attributes["live-link"];
        this.recordingLink = loadedEvent.attributes["recording-link"];
        this.workshop.startTime = loadedEvent.attributes["start-time"];
        this.workshop.endTime = loadedEvent.attributes["end-time"];
        this.workshop.description = loadedWorkshop.attributes["preview-text"];
        this.workshop.title = loadedWorkshop.attributes.title;
      }

      return Promise.resolve();
    },
    workshopCallsComplete() {
      this.$store.clearData();
      this.$user.logOut();
      this.isLoading = false;
      this.countdownTimeout = setInterval(this.updateTime, 10000);
      return Promise.resolve();
    },
    updateTime() {
      const now = dayjs();
      const eventDate = dayjs(this.workshop.startTime);
      const minUntil = dayjs(this.workshop.startTime).diff(dayjs(), "minute");
      this.workshopBeginsLater = now.isBefore(
        eventDate.subtract("30", "minute")
      );
      this.workshopBeginsSoon =
        now.isAfter(eventDate.subtract("30", "minute")) &&
        now.isBefore(eventDate);
      this.workshopStarted =
        (now.isSame(eventDate) || now.isAfter(eventDate)) &&
        now.isBefore(eventDate.add(30, "minute"));
      this.workshopPassed =
        now.isAfter(eventDate) && now.isAfter(eventDate.add(30, "minute"));
      this.minutesUntilStart = minUntil;

      if (this.workshopPassed) {
        clearInterval(this.countdownTimeout);
        this.countdownTimeout = null;
      }
      return Promise.resolve();
    },
    addToCalendar() {
      const props = {
        uid: this.workshop.id,
        startTime: this.workshop.startTime,
        endTime: this.workshop.endTime,
        location: this.liveLink,
      };
      if (this.workshop.description) {
        props.description = this.generateWorkshopDescription(
          this.workshop.description
        );
      }
      if (this.workshop.title) {
        props.summary = this.generateWorkshopTitle(this.workshop.title);
      }
      const file = this.generateICSBlob(props);
      this.saveBlob({
        content: file,
        name: `event-${this.workshop.id}.ics`,
        type: "text/calendar;charset=UTF-8",
      });
    },
    copyLink() {
      let copyText = document.querySelector("#workshop-link");
      copyText.select();
      document.execCommand("copy");
      copyText.blur();
      this.copyLinkText = "Copied!";
      this.copyTimeout = setTimeout(() => {
        this.copyTimeout = null;
        this.copyLinkText = "Copy workshop link";
      }, 5000);
    },
    handleBookingError(err) {
      let response = err.response;
      let errors =
        response && response.data && response.data.errors
          ? response.data.errors
          : [];
      let errorMessage =
        "An error occurred booking this workshop. Please try again.";

      this.loading = false;

      if (errors.length) {
        // Bookshop is already booked
        if (errors[0].includes("Workshop event Congrats")) {
          errorMessage = null;
        } else
          errorMessage =
            typeof errors[0] === "object"
              ? JSON.stringify(errors[0])
              : errors[0];
      }
      this.error = errorMessage;
      if (!this.error) {
        this.handleWorkshopAlreadyBooked();
      } else {
        this.workshopBookingError();
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/style/variables.scss";
@import "@/style/mixins.scss";

.booking-confirmation {
  .content {
    text-align: center;
  }

  #workshop-link {
    position: absolute;
    left: -2000px;
    top: -200px;
    top: 0;
  }

  .inner-block {
    align-items: flex-start;
    padding-top: 58px;
  }

  h4 {
    margin: 0;
    padding: 0;
    font-size: 26px;
    line-height: 40px;
    align-self: center;
  }

  .check {
    margin: 28px auto 0;
  }

  p {
    line-height: 24px;
    margin: 24px 0 0;
    padding: 0;
    align-self: center;
  }

  .button.top {
    margin-top: 26px;
  }

  .workshop-button {
    margin: 18px auto 0;
    align-self: center;
    width: 202px;
  }

  .download {
    margin-top: 22px;
  }
}
</style>
