<template>
  <section class="payment checkout">
    <div class="content">
      <header class="main-header">
        <h4>Get 30% off today</h4>
        <h5>Offer expires on <b>March 25th, 2021</b></h5>
      </header>
      <section class="invoice">
        <div class="flex-row total">
          <div>Cost Per Month</div>
          <div>${{ plan.monthlyCost }}</div>
        </div>
        <div v-if="plan.note" class="sub">
          {{ plan.note }}
        </div>
      </section>
      <user-payment-form
        :plan="plan"
        footnote="Renews monthly at the same rate. Cancel anytime."
        cta="Purchase Now"
        :show-why-now="false"
        @payment-complete="goToNextRoute"
      />
    </div>
  </section>
</template>

<script>
import UserPaymentForm from "@/components/UserPaymentForm";
import nextRouteMixin from "@/mixins/nextRoute";
import { getPlan, PLAN_39_99_MONTHLY_NAME } from "@/plans";

export default {
  name: "SalePayment",
  components: {
    UserPaymentForm,
  },
  mixins: [nextRouteMixin],
  props: { store: { type: Object, default: () => {} } },
  data() {
    return {
      plan: {
        ...getPlan(PLAN_39_99_MONTHLY_NAME),
      },
    };
  },
  mounted() {
    this.$tracker.viewedCheckout();
  },
  methods: {
    startPayment() {
      this.isLoading = true;
      this.error = null;
      if (!this.usingBrowserPay) {
        return this.createAccount()
          .then(this.createToken)
          .then(this.onStripeTokenCreated)
          .then(this.subscribeToPlan)
          .then(this.paymentComplete)
          .then(this.navigateToNextPage)
          .catch(this.handleError);
      } else {
        return this.createAccount()
          .then(this.subscribeToPlan)
          .then(this.paymentComplete)
          .then(this.navigateToNextPage)
          .catch(this.handleError);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/mixins.scss";
.payment {
  h4 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 20px;
  }

  .main-header h5 {
    margin-top: 0;
    font-weight: normal;
    text-align: center;
    font-size: 15.5px;
  }

  .invoice {
    border-top: 1px solid var(--light-gray);
    padding-top: 32px;
    margin-top: 26px;
    font-size: 16px;

    .flex-row {
      justify-content: space-between;
    }

    .total {
      font-weight: bold;
      margin-bottom: 15px;
    }

    .sub {
      font-size: 14px;
      text-align: left;
    }
  }

  h5 {
    font-size: 16.5px;
    margin-bottom: 16px;
    text-align: left;

    &.sub {
      text-align: center;
      font-size: 15.5px;
      font-weight: normal;
      margin-top: 10px;
    }
  }
}
</style>
