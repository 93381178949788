<template>
  <section class="workshop-banner">
    <header>
      <h2>Workshop</h2>
      <h1>{{ title }}</h1>
      <div class="time">{{ formattedTime }}</div>
    </header>
  </section>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "WorkshopBanner",
  props: {
    workshop: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      title: this.workshop.title,
      time: this.workshop.startTime,
    };
  },
  computed: {
    formattedTime() {
      const now = dayjs();
      const eventDate = dayjs(this.time);
      const maxTime = eventDate.add(30, "minute");
      if (now.isBefore(maxTime)) {
        return `${eventDate.format("MMM D")} at ${eventDate.format("h:mma")} ${
          new Date()
            .toLocaleTimeString("en-us", { timeZoneName: "short" })
            .split(" ")[2]
        }`;
      }
      return "On-Demand";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/mixins.scss";

.workshop-banner {
  position: relative;
  width: 100%;
  color: var(--white);

  header {
    background-color: #4295ed;
    background-image: url("../assets/workshop_header_bg_desktop.svg");
    background-repeat: no-repeat;
    background-size: cover;
    @include flex-column;
    justify-content: center;
    min-height: 210px;
    width: 100%;
  }

  h1 {
    font-size: 26px;
    margin: 0;
    padding: 7px 24px 0;
    line-height: 40px;
  }

  h2 {
    @include noto;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 19px;
    padding: 0;
    margin: 0;
  }

  .time {
    position: absolute;
    bottom: -32px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    border-radius: 5px;
    box-shadow: 0px 2px 12px rgba(29, 29, 29, 0.1);
    margin: 0 auto;
    padding: 8px 16px;
    text-align: center;
    color: var(--black);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
}

@media only screen and (max-width: $breakpoint) {
  .workshop-banner {
    header {
      background-image: url("../assets/workshop_header_bg.svg");
    }
  }
}
</style>
