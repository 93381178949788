<template>
  <section class="payment-invoice bordered">
    <div class="flex-row total">
      <div>Total today</div>
      <div>$0.00</div>
    </div>
    <div class="itemization bordered">
      <div class="promo-row flex-row" v-if="showPromo">
        <div class="promo-code">Code LOVE Applied!</div>
        <div v-if="showSavings" class="price strike">
          ${{ plan.originalMonthlyCost }}
        </div>
      </div>
      <div class="pricing flex-row">
        <div class="label">{{ plan.costPerMonthAfterTrial }}</div>
        <div class="price">
          <span v-if="showSavings && !showPromo" class="strike"
            >${{ plan.originalMonthlyCost }}</span
          ><b>${{ plan.monthlyCost }}</b>
        </div>
      </div>
      <div v-if="showSavings" class="savings">{{ plan.savings }}</div>
    </div>
    <div class="sub bordered">{{ plan.promoNote }}</div>
  </section>
</template>

<script>
export default {
  name: "PaymentInvoice",
  components: {},
  props: {
    plan: { type: Object, default: () => {} },
    showPromo: { type: Boolean, default: true },
    showSavings: { type: Boolean, default: true },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/mixins.scss";
.payment-invoice {
  padding-top: 16px;
  font-size: 16px;
  line-height: 26px;

  &.bordered,
  .bordered {
    border-top: 1px solid var(--light-gray);
  }

  .itemization {
    padding-top: 16px;
  }

  .promo-row {
    align-items: center;
  }

  .promo-code {
    font-size: 14px;
    color: var(--green);
    font-weight: 700;
    text-align: left;
  }

  .pricing {
    margin: 3px 0 0;
  }

  .label {
    text-align: left;
    font-size: 16px;
    flex: 1;
  }

  .price {
    margin-left: 75px;
  }

  .flex-row {
    justify-content: space-between;
  }

  .total {
    font-weight: bold;
    margin-bottom: 15px;
  }

  .savings {
    font-weight: 400;
    font-size: 14px;
    color: var(--medium-gray);
    margin-top: 6px;
    text-align: left;
  }

  .strike {
    text-decoration: line-through;
    color: var(--medium-gray);
    padding-right: 3px;
  }

  .sub {
    font-size: 14px;
    text-align: left;
    color: var(--light-black);
    font-style: italic;
    padding-top: 10px;
    margin-top: 16px;
  }
}
</style>
