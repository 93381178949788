const testimonials = [
  {
    content:
      "This app is helping my marriage so much which honestly was on the verge of divorce not too long ago. Thank you, Lasting.",
    author: "Chelsie B",
    image: "/imgs/img1.png",
  },
  {
    content:
      "Loving this app so far. It has created MUCH needed communication and understanding of each of our needs. It is helping us grow in the right direction.",
    author: "Behtsee M",
    image: "/imgs/img2.png",
  },
  {
    content:
      "This app really helped me and my partner open up the lines of communication. An eye opening experience!",
    author: "Tineshia H",
    image: "/imgs/img3.png",
  },
  {
    content:
      "What a wonderful app that helps my husband and I communicate. I've learned more about him through this and we've been together 10 years!",
    author: "Nina R",
    image: "/imgs/img4.png",
  },
  {
    content:
      "Really glad me and my fiancé decided to invest in this. He hasn't been fond of couples therapy but he’s been very open and receptive to Lasting.",
    author: "Jasmine H",
    image: "/imgs/img5.png",
  },
  {
    content:
      "My husband and I used this app and it helped so much. It gave us so many great tools to bridge the gap between us and allow for better communication.",
    author: "Leanne H",
    image: "/imgs/img6.png",
  },
  {
    content:
      "Love this. Great for communication, and all areas of marriage. Designed to fit our needs, is helping us learn about ourselves and each other. Worth it.",
    author: "Cat C",
    image: "/imgs/img7.png",
  },
  {
    content:
      "A tremendously useful app. I suggest paying for the subscription as it's definitely worth it considering—your relationship is priceless.",
    author: "David M",
    image: "/imgs/img8.png",
  },
  {
    content:
      "This app makes it easy to work through issues and find the right way to engage. I love that we can work at our own pace and pick up right where we left off.",
    author: "Beth K",
    image: "/imgs/img9.png",
  },
  {
    content:
      "The app has given my wife and I a chance to think and talk about topics we normally wouldn't.",
    author: "Quintana P",
    image: "/imgs/img10.png",
  },
];

export default testimonials;
