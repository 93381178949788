import fileSaver from "file-saver";

const fileMixin = {
  methods: {
    generateICSBlob({
      uid,
      startTime,
      endTime,
      location,
      summary,
      description,
    }) {
      return (
        `BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT` +
        `\nUID:${uid}@getlasting.com` +
        `\nDTSTART:${this.formatDate(startTime)}` +
        `\nDTEND:${this.formatDate(endTime)}` +
        `\nLOCATION:${location}` +
        `\nDTSTAMP:${this.formatDate(new Date().getTime())}` +
        `\nPRODID:getlasting.com` +
        `\nDESCRIPTION:${description}` +
        `\nSUMMARY:${summary}\nEND:VEVENT\nEND:VCALENDAR`
      );
    },
    formatDate(date) {
      return date
        ? new Date(date).toISOString().replace(/-|:|\.\d+/g, "")
        : null;
    },
    generateWorkshopDescription(description) {
      return `To join this workshop, please use the Lasting app or the link in the email sent one hour prior to the workshop start time.\\n\\n${description}`;
    },
    generateWorkshopTitle(title) {
      return `Lasting Workshop: ${title}`;
    },
    saveBlob({ content, name, type }) {
      return new Promise((resolve) => {
        const blob = new Blob([content], {
          type,
          encoding: "UTF-8",
        });
        fileSaver.saveAs(blob, name);
        resolve();
      });
    },
  },
};

export default fileMixin;
