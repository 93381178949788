<template>
  <section class="complete checkout">
    <div class="content">
      <h4>Now get started!</h4>
      <p>
        Click on one of the links below to download the app. In the app, you can
        invite your partner to join Lasting!
      </p>
      <download-app-buttons />
    </div>
  </section>
</template>

<script>
import DownloadAppButtons from "@/components/DownloadAppButtons";

export default {
  name: "CompleteView",
  components: { DownloadAppButtons },
  mounted() {
    this.$store.clearData();
    this.$user.logOut();
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/variables.scss";
.complete {
  .content {
    text-align: left;
  }

  p {
    line-height: 25px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: $breakpoint) {
  .complete {
    .content {
      padding-top: 25px;
    }

    h4 {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 21px;
    }
  }
}
</style>
