<template>
  <li class="workshop-error-list-item" :data-id="id">
    <h6>{{ title }}</h6>
    <p>{{ description }}</p>
    <div class="date">
      <img
        src="../assets/calendar_icon.svg"
        width="24"
        height="24"
        alt="Calendar icon"
      />
      <div>{{ date }}</div>
    </div>
    <div>
      <workshop-button class="button" @click="bookWorkshop"
        >Book workshop</workshop-button
      >
    </div>
  </li>
</template>

<script>
import dayjs from "dayjs";
import WorkshopButton from "@/components/WorkshopButton";

export default {
  name: "WorkshopErrorListItem",
  components: { WorkshopButton },
  props: {
    selected: { type: Boolean, default: false },
    id: { type: String, default: "" },
    title: { type: String, default: "" },
    description: { type: String, default: "" },
    startTime: { type: String, default: null },
    endTime: { type: String, default: null },
  },
  computed: {
    date() {
      const eventDate = dayjs(this.startTime);
      const timezone = new Date()
        .toLocaleTimeString("en-us", { timeZoneName: "short" })
        .split(" ")[2];
      const now = dayjs();
      if (eventDate.format("MM/DD/YYYY") === now.format("MM/DD/YYYY"))
        return `Today at ${eventDate.format("h:mm A")}, ${timezone}`;
      if (
        eventDate.format("MM/DD/YYYY") ===
        now.add(1, "day").format("MM/DD/YYYY")
      )
        return `Tomorrow at ${eventDate.format("h:mm A")}, ${timezone}`;
      return `${eventDate.format("dddd, MMM D")} at ${eventDate.format(
        "h:mm A"
      )}, ${timezone}`;
    },
  },
  methods: {
    bookWorkshop() {
      this.$store.setMeta({
        workshop: { id: this.id, title: this.title, startTime: this.startTime },
      });

      this.$router.push({ name: "workshopBooked" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixins.scss";
@import "@/style/variables.scss";

.workshop-error-list-item {
  border: 1px solid var(--light-gray);
  border-radius: 5px;
  padding: 24px;
  @include flex-column;
  width: 100%;
  justify-content: flex-start;

  h6 {
    @include noto;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700px;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  p {
    font-size: 14px;
    text-align: left;
    line-height: 24px;
  }

  .date {
    @include flex-row;
    justify-content: flex-start;
    font-size: 14px;
    line-height: 11.5px;
    margin-top: 20px;

    div {
      margin-left: 12px;
      align-self: center;
    }
  }

  .button {
    width: 176px;
    margin: 18px auto 0;
  }
}
</style>
