<template>
  <quiz-section
    class="quiz"
    :index="index"
    :questions="questions"
    :on-answer="onAnswer"
  />
</template>

<script>
import QuizSection from "@/components/QuizSection";
import nextRouteMixin from "@/mixins/nextRoute";

export default {
  name: "QuizView",
  components: { QuizSection },
  mixins: [nextRouteMixin],
  props: {
    sectionName: { type: String, default: "" },
    index: { type: Number, default: 0 },
    store: { type: Object, default: () => {} },
  },
  computed: {
    questions() {
      return this.$store.getQuestions(this.sectionName);
    },
  },
  mounted() {
    if (!this.$store.state.quizStarted) {
      this.$store.state.quizStarted = true;
      // this.$tracker.track("Quiz Started");
    }
  },
  methods: {
    onAnswer({ question, answer }) {
      this.$store.answer(this.sectionName, this.index, answer);
      this.trackResponse(question, answer);
      this.goToNext();
    },
    trackResponse(question, answer) {
      const trackedResponse = Array.isArray(answer)
        ? answer.map((e) => e.label)
        : answer?.label;
      this.$tracker.trackQuizAnswer({
        Page: window.location.href,
        Question: question.text,
        Id: question.id,
        Answer: trackedResponse,
      });
    },
    goToNext() {
      const nextIndex = this.$store.nextUp(this.sectionName, this.index);

      if (nextIndex) {
        this.$router
          .push(
            Object.assign({}, this.$router.currentRoute.meta.questionRoute, {
              params: {
                index: nextIndex,
              },
            })
          )
          .catch(() => {});
      } else {
        this.goToNextRoute();
      }
    },
  },
};
</script>
