<template>
  <div>
    <!-- eslint-disable -->
    <div
      class="container"
      v-html="require(`!html-loader!@/assets/progress_date_graph.svg`)"
    >
      <!-- eslint-enable -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressGraph",
  props: {
    date: {
      type: String,
      default: "",
    },
  },
  mounted() {
    if (this.$el.querySelectorAll("svg").length > 0) {
      const svgElement = this.$el.querySelectorAll("svg")[0];
      if (svgElement) {
        const dateElement = svgElement.getElementById("progress-date");
        const dateSmallElement = svgElement.getElementById(
          "progress-date-small"
        );
        if (dateElement) {
          dateElement.innerHTML = this.date;
        }
        if (dateSmallElement) {
          dateSmallElement.innerHTML = this.date;
        }
      }
    }
  },
};
</script>
