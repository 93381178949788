<template>
  <footer class="security-footer">
    <div class="content inner-block">
      <div class="satisfaction">100% Satisfaction Guaranteed</div>
      <span class="secured">
        <security-badge class="wrapper" />
      </span>
    </div>
  </footer>
</template>

<script>
import SecurityBadge from "@/components/SecurityBadge";

export default {
  name: "SecurityFooter",
  components: {
    SecurityBadge,
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/mixins.scss";

.security-footer {
  width: 100%;
  background-color: #f2f2f2;
  font-size: 16px;
  padding: 30px 0;
  color: var(light-black);
  height: 110px;

  & > * {
    align-self: center;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.inner-block {
      padding: 0;
    }
  }

  .satisfaction {
    align-self: center;
    text-align: left;
    width: 50%;
  }

  .secured {
    font-size: 14px;
    width: 50%;
    flex: 0;
    align-self: center;

    .wrapper {
      & > * {
        align-self: center;
      }

      border: 1px solid var(--black);
      border-radius: 3px;
      padding: 11px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

@media only screen and (max-width: $breakpoint) {
  .security-footer {
    .content {
      justify-content: center;
    }

    .secured {
      padding-left: 35px;
    }
  }
}

@media only screen and (max-width: $xs-breakpoint) {
  .security-footer {
    padding-left: 25px;
    padding-right: 25px;

    .satisfaction {
      width: auto;
    }

    .secured {
      padding-left: 30px;
      width: auto;
    }
  }
}

@media only screen and (max-width: $xxs-breakpoint) {
  .security-footer {
    padding-left: 15px;
    padding-right: 15px;

    .secured {
      padding-left: 15px;
    }
  }
}
</style>
