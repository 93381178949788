<template>
  <div :class="{ 'alert-section': true, info }" role="alert">
    <img
      v-if="!info"
      src="../assets/alert_icon.svg"
      class="icon"
      width="20"
      height="20"
      alt="alert"
    />
    <img
      v-if="info"
      src="../assets/info_icon_blue.svg"
      class="icon"
      width="20"
      height="20"
      alt="info"
    />
    <slot />
  </div>
</template>

<script>
const ERROR = "error";
const INFO = "info";

export default {
  name: "AlertSection",
  props: {
    type: { type: String, default: ERROR },
  },
  computed: {
    info() {
      return INFO === this.type;
    },
  },
};
</script>

<style lang="scss">
@import "@/style/variables.scss";
@import "@/style/mixins.scss";

.alert-section {
  padding: 16px 24px 16px 28px;
  background-color: #fcebeb;
  border-radius: 2px;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  word-wrap: break-word;
  @include flex-row;
  justify-content: flex-start;

  .icon {
    margin-right: 16px;
    align-self: flex-start;
  }

  &.info {
    background-color: #edf5fe;
  }
}

@media only screen and (max-width: $breakpoint) {
  .alert-section {
    margin-left: -25px;
    margin-right: -25px;
  }
}
</style>
