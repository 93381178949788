<template>
  <section class="plan checkout">
    <div class="inner-block">
      <div class="content">
        <h4>
          On this website only, get your program for {{ savingsPercentage }}%
          off
        </h4>
        <plan-highlights :show-border="true" />
        <div class="testimonials-container">
          <testimonials />
        </div>
      </div>
    </div>
    <div class="button-container">
      <quiz-button :solid="true" @click="submit()">Continue</quiz-button>
    </div>
  </section>
</template>

<script>
import { getPlan } from "@/plans";
import QuizButton from "@/components/QuizButton";
import PlanHighlights from "@/components/PlanHighlights";
import Testimonials from "@/components/Testimonials";
import nextRouteMixin from "@/mixins/nextRoute";
import dayjs from "dayjs";

export default {
  name: "PlanPage",
  components: { QuizButton, PlanHighlights, Testimonials },
  mixins: [nextRouteMixin],
  data: function () {
    const { savingsPercentage } = getPlan();
    return {
      savingsPercentage,
    };
  },
  computed: {
    date() {
      const newDate = dayjs().add(1, "month");
      return newDate.format("MMMM D");
    },
  },
  mounted() {
    this.$tracker.viewedPlanPage();
  },
  methods: {
    submit() {
      this.goToNextRoute();
    },
  },
};
</script>

<style lang="scss">
@import "@/style/variables.scss";
@import "@/style/mixins.scss";
.has-promo .plan {
  margin-top: 25px;
}

.plan {
  justify-content: start;

  h4 {
    font-weight: normal;
    line-height: 30px;
    margin: 0;
  }

  p {
    line-height: 25px;
  }

  h5 {
    text-align: center;
  }

  .details {
    margin: 35px 0 0;
  }

  .date {
    color: var(--orange);
    font-size: 24px;
    font-weight: normal;
    margin: 20px;
  }

  .button-container {
    flex: 0;
    position: sticky;
    bottom: 0px;
    background-color: var(--white);
    box-shadow: 1px 0px 4px 0px rgba(181, 181, 181, 0.5);
    clip-path: inset(-4px 0px 0px 0px);
    margin-top: 0;
    width: 100%;
    padding: 25px;
    z-index: 1;

    .quiz-button {
      margin: 0 auto;
      max-width: 480px;
    }
  }
}

@media only screen and (max-width: $breakpoint) {
  .plan {
    h4 {
      margin-bottom: 55px;
    }
  }
}
</style>
