<template>
  <alert-section class="workshop-alert" type="info" v-if="message">{{
    message
  }}</alert-section>
</template>
<script>
import dayjs from "dayjs";
import workshopsMixin from "@/mixins/workshops";
import AlertSection from "@/components/AlertSection";

const WORKSHOP_IN_PROGRESS =
  "This workshop has already started, but there's still time to join!";
const WORKSHOP_PASSED =
  "This workshop is over now, but you can watch it on-demand anytime with Lasting Premium.";

export default {
  name: "WorkshopAlert",
  components: { AlertSection },
  mixins: [workshopsMixin],
  props: {
    workshop: { type: Object, default: () => {} },
  },
  computed: {
    message() {
      if (this.workshopInProgress) return WORKSHOP_IN_PROGRESS;
      if (this.workshopPassed) return WORKSHOP_PASSED;
      return null;
    },
    workshopInProgress() {
      if (!this.workshop || !this.workshop.startTime) return false;
      const now = dayjs();
      const eventDate = dayjs(this.workshop.startTime);
      const maxTime = eventDate.add(30, "minute");
      if (now.isAfter(eventDate) && now.isBefore(maxTime)) {
        return true;
      }
      return false;
    },
    workshopPassed() {
      if (!this.workshop || !this.workshop.startTime) return false;

      const now = dayjs();
      const eventDate = dayjs(this.workshop.startTime);
      const maxTime = eventDate.add(30, "minute");
      if (now.isAfter(maxTime)) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.workshop-alert {
  padding: 34px 24px var(--form-spacing) 28px;
  justify-content: center;
}

@media only screen and (max-width: $breakpoint) {
  .workshop-alert {
    justify-content: flex-start;
  }
}
</style>
