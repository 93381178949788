<template>
  <alert-modal class="forgot-password-modal" @overlay-click="overlayClick">
    <h5>Reset Password</h5>
    <form v-if="!emailSent" @submit.prevent="submit">
      <p class="instructions">
        Enter the email associated with your account, and we'll send you an
        email with instructions to reset your password.
      </p>
      <alert-section v-show="error" class="row alert">{{
        error
      }}</alert-section>
      <email-field
        class="row"
        :email="$v.email"
        placeholder="Email address"
        :disabled="isLoading"
        @change="onFieldChange"
      />
      <quiz-button
        :solid="true"
        type="submit"
        value="Submit"
        :loading="isLoading"
        >Submit</quiz-button
      >
    </form>
    <div v-if="emailSent" class="success">
      <p>
        Password reset email sent to <span class="email">{{ email }}</span
        >!
      </p>
      <quiz-button :solid="true" type="button" @click="$emit('close')"
        >Close</quiz-button
      >
    </div>
  </alert-modal>
</template>

<script>
import QuizButton from "@/components/QuizButton";
import AlertModal from "@/components/AlertModal";
import AlertSection from "@/components/AlertSection";
import EmailField from "@/components/EmailField";

import { GENERIC_FORM_ERROR } from "@/mixins/submitPayment";

import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "ForgotPasswordModal",
  components: {
    QuizButton,
    AlertModal,
    AlertSection,
    EmailField,
  },
  mixins: [validationMixin],
  props: { email: { type: String, default: "" } },
  data() {
    return {
      emailSent: false,
      error: null,
      isLoading: false,
    };
  },
  validations: {
    email: { required, email },
  },
  unmounted() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
    }
    this.closeTimeout = null;
  },
  methods: {
    overlayClick() {
      if (!this.isLoading) {
        this.$emit("close");
      }
    },
    onFieldChange(field, newValue) {
      this.error = null;
      field.$model = newValue;
      if (field) {
        field.$touch();
      }
    },
    handleError() {
      this.error = "There was a problem resetting your password.";
      this.isLoading = false;
    },
    complete() {
      this.emailSent = true;
      this.isLoading = false;
      this.closeTimeout = setTimeout(this.close, 2000);
    },
    close() {
      this.$emit("close");
    },
    submit() {
      this.error = null;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = GENERIC_FORM_ERROR;
        return;
      }

      this.isLoading = true;
      this.$store.setMeta({ email: this.email });
      return this.$user
        .resetPassword(this.email)
        .then(this.complete)
        .catch(this.handleError);
    },
  },
};
</script>

<style lang="scss" scoped>
.forgot-password-modal {
  h5 {
    margin: 0 0 25px;
    text-align: center;
  }

  .instructions {
    text-align: left;
  }

  .form-group {
    margin-top: 25px;
  }

  p {
    font-size: 15px;
    line-height: 25px;
    margin: 0 7px;
    text-align: center;
  }

  .quiz-button {
    margin: 25px 0 0;
  }

  .alert {
    margin-top: 20px;
  }

  .success {
    word-wrap: break-word;

    .email {
      font-weight: bold;
    }
  }
}
</style>
