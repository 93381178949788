<template>
  <section class="payment workshop-checkout">
    <workshop-banner :workshop="workshop" />
    <alert-section class="workshop-alert" type="info" v-if="workshopInProgress"
      >This workshop has already started, but there's still time to
      join!</alert-section
    >
    <alert-section class="workshop-alert" type="info" v-if="workshopPassed"
      >This workshop is over now, but you can watch it on-demand anytime with
      Lasting Premium.</alert-section
    >
    <div class="content inner-block">
      <header class="main-header">
        <h4>Start your 7-day free trial</h4>
        <p class="row" v-if="!workshopPassed">
          Book this workshop and unlock hundreds more with Lasting Premium.
        </p>
        <p class="row" v-if="workshopPassed">
          View this workshop on-demand and unlock hundreds more with Lasting
          Premium.
        </p>
      </header>
      <payment-promo-invoice
        class="invoice"
        :plan="plan"
        :show-promo="false"
        :show-savings="false"
      />
      <form ref="form" @submit.prevent="submit">
        <alert-section
          class="form-alert"
          v-show="error && !showPasswordError"
          >{{ error }}</alert-section
        >
        <log-in-fields
          v-if="showLogInForm"
          :email="$v.email"
          :email-placeholder="emailPlaceholder"
          :password="$v.password"
          :show-password-error="showPasswordError"
          :disabled="isLoading"
          @change="modelFieldUpdated"
          @switchToSignUp="switchToSignUpMode"
        />
        <sign-up-fields
          ref="signUpFields"
          class="top-sign-up-fields"
          v-if="showSignUpForm"
          :email="$v.email"
          :email-placeholder="emailPlaceholder"
          :password="$v.password"
          :disabled="isLoading"
          @change="modelFieldUpdated"
          @switchToLogIn="switchToLogInMode"
        />
        <browser-pay
          :class="{
            'browser-pay': true,
            'no-form-above': !showSignUpForm && !showLogInForm,
          }"
          :stripe="stripeKey"
          :plan="plan"
          @token="onBrowserPayTokenCreated"
          @payment-loaded="onPaymentLoaded"
        />
        <loading-spinner v-if="browserPayLoading" class="row spinner" />
        <use-credit-card-button
          v-if="showCreditCardToggle"
          class="row"
          @click="toggleCreditCard"
        />
        <section v-if="showCreditCard">
          <h5 v-if="showCreditCardOptionHeader" class="flex-row pay-with-card">
            Or pay with card
          </h5>
          <h5
            :class="{
              'payment-header': true,
              'no-margin': !browserPaySupported && !showLogInForm,
              'has-content-above':
                showLogInForm || showSignUpForm || browserPaySupported,
            }"
          >
            Payment information
          </h5>
          <credit-card-fields
            ref="creditCardFields"
            :is-loading="isLoading"
            :model="$v.creditCard"
            @change="onCardChange"
            @blur="onCardBlur"
          />
          <sign-up-fields
            ref="signUpFields"
            v-if="showInlineSignUpForm"
            :showHeader="false"
            :showPassword="requiresPassword"
            :showLogInLink="false"
            :email="$v.email"
            :email-placeholder="emailPlaceholder"
            :password="$v.password"
            :disabled="isLoading"
            :show-password-error="showPasswordError"
            @change="modelFieldUpdated"
            @switchToLogIn="switchToLogInMode"
          />
          <quiz-button :solid="true" type="submit" :loading="isLoading"
            >Start free trial</quiz-button
          >
        </section>
        <p class="charge row">
          You won't be charged until <b>after your trial</b>
        </p>
      </form>
    </div>
  </section>
</template>

<script>
import dayjs from "dayjs";
import QuizButton from "@/components/QuizButton";
import BrowserPay from "@/components/BrowserPay";
import LoadingSpinner from "@/components/LoadingSpinner";
import PaymentPromoInvoice from "@/components/PaymentPromoInvoice";
import AlertSection from "@/components/AlertSection";
import CreditCardFields from "@/components/CreditCardFields";
import WorkshopBanner from "@/components/WorkshopBanner";
import UseCreditCardButton from "@/components/UseCreditCardButton";

import LogInFields from "@/components/LogInFields";
import SignUpFields from "@/components/SignUpFields";

import nextRouteMixin from "@/mixins/nextRoute";
import submitPaymentMixin, { GENERIC_FORM_ERROR } from "@/mixins/submitPayment";
import { PLAN_39_99_MONTHLY } from "@/plans";
import { validStripeField } from "@/validators";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

const MODES = {
  IMPLICIT_SIGN_UP: "implicitSignUp",
  IMPLICIT_LOG_IN: "implicitLogIn",
  // User has clicked the "Don't have an account? Sign up" link
  EXPLICIT_SIGN_UP: "explicitSignUp",
  // User has clicked the "Already have an account? Log in" link
  EXPLICIT_LOG_IN: "explicitLogIn",
};

export default {
  name: "WorkshopPaymentView",
  components: {
    QuizButton,
    BrowserPay,
    LoadingSpinner,
    AlertSection,
    CreditCardFields,
    PaymentPromoInvoice,
    WorkshopBanner,
    LogInFields,
    SignUpFields,
    UseCreditCardButton,
  },
  mixins: [nextRouteMixin, validationMixin, submitPaymentMixin],
  props: { store: { type: Object, default: () => {} } },
  data() {
    return {
      email:
        this.$store.state.meta.existingLogin ||
        this.$store.state.meta.email ||
        null,
      plan: {
        ...PLAN_39_99_MONTHLY,
      },
      workshop: this.$store.state.meta.workshop,
      mode: this.$store.state.meta.existingLogin
        ? MODES.IMPLICIT_LOG_IN
        : MODES.IMPLICIT_SIGN_UP,
      showPasswordError: false,
      existingAccount: !!this.$store.state.meta.existingLogin,
    };
  },
  computed: {
    isLoggingIn() {
      return (
        this.logInMode || (this.implicitSignUpMode && this.requiresPassword)
      );
    },
    implicitLogInMode() {
      return this.mode === MODES.IMPLICIT_LOG_IN;
    },
    explicitLogInMode() {
      return this.mode === MODES.EXPLICIT_LOG_IN;
    },
    implicitSignUpMode() {
      return this.mode === MODES.IMPLICIT_SIGN_UP;
    },
    explicitSignUpMode() {
      return this.mode === MODES.EXPLICIT_SIGN_UP;
    },
    logInMode() {
      return [MODES.IMPLICIT_LOG_IN, MODES.EXPLICIT_LOG_IN].includes(this.mode);
    },
    signUpMode() {
      return [MODES.IMPLICIT_SIGN_UP, MODES.EXPLICIT_SIGN_UP].includes(
        this.mode
      );
    },
    requiresPassword() {
      return (
        this.logInMode ||
        this.explicitSignUpMode ||
        (this.implicitSignUpMode && this.existingAccount)
      );
    },
    showLogInForm() {
      return (
        this.logInMode ||
        (this.signUpMode && this.existingAccount && this.browserPaySupported)
      );
    },
    showSignUpForm() {
      return this.explicitSignUpMode;
    },
    showInlineSignUpForm() {
      return (
        this.implicitSignUpMode &&
        (!this.browserPaySupported ||
          (this.browserPaySupported && !this.requiresPassword))
      );
    },
    emailPlaceholder() {
      return this.logInMode || (this.implicitLogInMode && this.existingAccount)
        ? "Email address (for your receipt)"
        : "Email address";
    },
    workshopInProgress() {
      const now = dayjs();
      const eventDate = dayjs(this.workshop.startTime);
      const maxTime = eventDate.add(30, "minute");
      if (now.isAfter(eventDate) && now.isBefore(maxTime)) {
        return true;
      }
      return false;
    },
    workshopPassed() {
      const now = dayjs();
      const eventDate = dayjs(this.workshop.startTime);
      const maxTime = eventDate.add(30, "minute");
      if (now.isAfter(maxTime)) {
        return true;
      }
      return false;
    },
  },
  validations() {
    const rules = {};

    rules.creditCard = {
      cardNumber: { validStripeField: this.requiresCreditCard },
      cardExpiry: { validStripeField: this.requiresCreditCard },
      cardCvc: { validStripeField: this.requiresCreditCard },
    };
    rules.email = { required, email };

    if (this.requiresPassword) {
      rules.password = { required };
      if (this.showPasswordError) {
        this.$nextTick(() => {
          this.$v.$touch("password");
        });
      }
    }

    if (this.logInMode || this.implicitLogInMode || this.signUpMode) {
      rules.email = { required, email };
    }
    return rules;
  },
  mounted() {
    this.$tracker.viewedWorkshopCheckout({
      "query string": this.$store.state.meta.queryString,
    });
  },
  updated() {
    if (this.error || this.showPasswordError) {
      this.scrollToAlert();
    }
  },
  methods: {
    requiresCreditCard(val) {
      if (
        !this.browserPaySupported ||
        (this.browserPaySupported &&
          this.creditCardChosen &&
          !this.usingBrowserPay)
      )
        return validStripeField(val);
      return true;
    },
    switchToLogInMode() {
      this.mode = MODES.EXPLICIT_LOG_IN;
      this.modelFieldUpdated(this.$v.password, null);
      this.showPasswordError = false;
      this.existingAccount = true;
      this.error = null;
      this.$v.$reset();
    },
    switchToSignUpMode() {
      this.mode = MODES.EXPLICIT_SIGN_UP;
      this.modelFieldUpdated(this.$v.password, null);
      this.showPasswordError = false;
      this.existingAccount = false;
      this.error = null;
      this.$v.$reset();
    },
    scrollToAlert() {
      this.$nextTick(() => {
        let el = null;
        if (this.error) {
          el = this.$refs.form;
        } else if (this.showPasswordError) {
          el = this.$refs.signUpFields;
        }

        if (el) {
          el.scrollIntoView({ behavior: "smooth", alignToTop: true });
        }
      });
    },
    submit() {
      this.showPasswordError = false;
      this.usingBrowserPay = false;
      this.error = false;
      return this.isLoggingIn
        ? this.validateForm(this.startExistingUserPayment)
        : this.validateForm(this.startNewUserPayment);
    },
    onComplete() {
      if (this.requiresPassword) {
        this.$store.state.meta.existingLogin = this.email;
      }
      return this.navigateToNextPage();
    },
    onExistingAccountError() {
      this.showPasswordError = true;
      this.existingAccount = true;
      this.$store.setMeta({ existingLogin: this.email });
      this.error = null;
    },
    startExistingUserPayment() {
      if (!this.usingBrowserPay) {
        return this.logIn()
          .then(this.createToken)
          .then(this.onStripeTokenCreated)
          .then(this.subscribeToPlan)
          .then(this.subscriptionComplete)
          .then(this.onComplete)
          .catch(this.handleError);
      } else {
        return this.logIn()
          .then(this.subscribeToPlan)
          .then(this.subscriptionComplete)
          .then(this.onComplete)
          .catch(this.handleError);
      }
    },
    onBrowserPayTokenCreated(event) {
      this.error = null;
      this.showPasswordError = false;
      this.browserPayEvent = event;
      this.usingBrowserPay = true;

      if (this.browserPayEvent?.payerEmail && !this.email)
        this.email = this.browserPayEvent.payerEmail;

      this.$v.$touch();

      if (event.error) {
        throw event.error;
      } else {
        if (this.$v.$invalid) {
          this.browserPayEvent.complete("fail");
          this.browserPayEvent = null;
          this.error = GENERIC_FORM_ERROR;
          return;
        }

        this.stripeToken = event.token;
        this.onStripeTokenSet(
          event,
          "workshop payment: onBrowserPayTokenCreated"
        );
        this.isLoading = true;

        return this.isLoggingIn
          ? this.startExistingUserPayment()
          : this.startNewUserPayment();
      }
    },
    subscriptionComplete() {
      let paymentMethod = "Credit Card";

      if (this.browserPayEvent) {
        this.browserPayEvent.complete("success");
        paymentMethod = this.browserPayEvent.methodName.includes("apple")
          ? "Apple Pay"
          : "Google Pay";
        this.browserPayEvent = null;
      }
      this.$tracker.workshopTrialStarted({
        "query string": this.$store.state.meta.queryString,
        "Payment Method": paymentMethod,
      });
      this.$store.setMeta({ paymentComplete: true });
      this.$user.markWebTrialStarted();
      return Promise.resolve();
    },
  },
};
</script>

<style lang="scss">
@import "@/style/variables.scss";
@import "@/style/mixins.scss";

.payment.workshop-checkout {
  min-height: 100%;

  .inner-block {
    padding-top: 60px;
    padding-bottom: 56px;
  }

  .workshop-alert ~ .inner-block {
    padding-top: 33px;
  }

  .spinner {
    text-align: center;
    display: block;
    margin: 0 auto var(--spacing-sm);
  }

  a {
    cursor: pointer;
    font-weight: bold;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
  }

  .main-header p {
    font-size: 16px;
  }

  h4 {
    margin-bottom: 0;
  }

  h5 {
    @include noto;
    text-align: left;
    font-size: 16px;
    margin-bottom: 0;

    &.pay-with-card {
      color: var(--medium-gray);
      font-size: 16px;
      font-weight: normal;
      @include flex-row;
      margin: 20px 25% 0;

      &:before,
      &:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid var(--medium-gray);
        margin: auto;
      }

      &:before {
        margin-right: 10px;
      }

      &:after {
        margin-left: 10px;
      }
    }

    &.payment-header {
      &.no-margin {
        margin-top: 0;
      }

      &.has-content-above {
        margin-top: 32px;
      }
    }
  }

  .top-sign-up-fields {
    h5 {
      margin-top: 0;
    }
  }

  .workshop-alert {
    box-shadow: 0px 2px 3px rgba(177, 177, 177, 0.25);
    justify-content: center;
    padding: 35px 24px 16px 24px;
  }

  .invoice {
    margin-top: 40px;
  }

  .form-alert {
    margin-top: 0;
    margin-bottom: var(--spacing-sm);
  }

  .log-in-fields h5,
  .sign-in-fields h5 {
    margin-top: 0;
  }

  .browser-pay {
    margin-top: var(--spacing-sm);

    &.no-form-above {
      margin-top: 0;
    }
  }

  .quiz-button {
    margin-top: var(--spacing-sm);
  }

  form {
    margin-top: var(--spacing-md);

    .row {
      margin-top: var(--form-spacing);
    }

    input,
    .stripe-element {
      border: 1px solid var(--light-gray);
      border-radius: 3px;
      padding: 20px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: $breakpoint) {
  .payment.workshop-checkout {
    .inner-block {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px;
    }

    .workshop-alert ~ .inner-block {
      padding-top: 40px;
    }

    .workshop-alert {
      padding: 35px 24px 16px 28px;
      justify-content: flex-start;
      margin: 0;
    }
  }
}
</style>
