<template>
  <div class="forgot-password-button">
    <button type="button" @click="toggleModal">Forgot password?</button>
    <forgot-password-modal
      v-if="modalOpen"
      :email="email"
      @close="toggleModal"
    />
  </div>
</template>
<script>
import ForgotPasswordModal from "@/components/ForgotPasswordModal";

export default {
  name: "ForgotPasswordButton",
  components: { ForgotPasswordModal },
  props: { email: { type: String, default: "" } },
  data() {
    return {
      modalOpen: false,
    };
  },
  methods: {
    toggleModal() {
      this.modalOpen = !this.modalOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/variables.scss";
.forgot-password-button {
  display: flex;
  flex-direction: row;

  button {
    border: none;
    background-color: transparent;
    color: var(--orange);
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    padding: 0;
    justify-content: flex-end;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
