<template>
  <div
    :class="{
      'bordered-page': true,
      'has-promo': promoBannerShown,
      'flex-column': true,
    }"
  >
    <promo-code-banner
      v-if="promoBannerShown"
      class="promo"
      @hide="hidePromo"
    />
    <light-nav-bar
      :show-back="showBack"
      class="nav"
      :show-skip="showSkip"
      @skip="skipHandler"
    />
    <quiz-progress-bar
      v-if="showProgress"
      :thin="true"
      :percent-complete="progressPercent"
    />
    <section
      :class="{
        'inner-block': useInnerBlock,
        'flex-column': !useInnerBlock,
      }"
    >
      <router-view class="flex-column" />
    </section>
    <portal-target name="sticky-footer" class="sticky-footer" />
  </div>
</template>

<script>
// TODO: Set this up to use slots
import LightNavBar from "@/components/LightNavBar";
import QuizProgressBar from "@/components/QuizProgressBar";
import PromoCodeBanner from "@/components/PromoCodeBanner";

export default {
  name: "BorderedPageLayout",
  components: {
    LightNavBar,
    QuizProgressBar,
    PromoCodeBanner,
  },
  props: { store: { type: Object, default: () => {} } },
  data() {
    return {
      showBack: true,
      showProgress: false,
      progressPercent: 0,
      useInnerBlock: true,
      showPromoBanner: false,
      userClosedPromoBanner: false,
      showSkip: false,
      skipHandler: () => {},
      autoApplyPromo: false,
    };
  },
  computed: {
    promoBannerShown: function () {
      return this.showPromoBanner && !this.userClosedPromoBanner;
    },
  },
  watch: {
    $route: function (newVal) {
      this.setNavBar(newVal);
      this.setProgressBar(newVal);
      this.setInnerBlock(newVal);
      this.setPromoBanner(newVal);
      this.setSkipHandler(newVal);
      this.setAutoApplyPromo(newVal);
    },
  },
  mounted() {
    this.setNavBar(this.$route);
    this.setProgressBar(this.$route);
    this.setInnerBlock(this.$route);
  },
  methods: {
    hidePromo() {
      this.userClosedPromoBanner = true;
    },
    setAutoApplyPromo(route) {
      this.autoApplyPromo = route.meta.autoApplyPromo;
    },
    setSkipHandler(route) {
      this.showSkip = route.meta.showSkip;
      this.skipHandler = route.meta.skipHandler || (() => {});
    },
    setNavBar(route) {
      this.showBack =
        typeof route.meta.hideBack === "function"
          ? !route.meta.hideBack(route)
          : !route.meta.hideBack;
    },
    setProgressBar(route) {
      this.showProgress = route.meta.showProgress || false;
      if (route.meta.sectionName && route.params.index) {
        this.progressPercent =
          (this.$store.getQuestionCountBefore(
            route.meta.sectionName,
            route.params.index
          ) /
            this.$store.getTotalQuestionsCount()) *
          100;
      }
    },
    setInnerBlock(route) {
      const val = route.meta.useInnerBlock;
      this.useInnerBlock =
        typeof val === "undefined" || val === null ? true : val;
    },
    setPromoBanner(route) {
      let { showPromoBanner } = route.meta;

      this.showPromoBanner =
        typeof showPromoBanner === "undefined" || showPromoBanner === null
          ? false
          : showPromoBanner;
    },
  },
};
</script>

<style lang="scss">
@import "@/style/global";
@import "@/style/mixins";

.bordered-page {
  width: 950px;
  margin: 68px auto 0;
  min-height: 100vh;
  position: relative;
  box-shadow: 0px 3px 5px rgba(160, 159, 159, 0.5);
  flex: 1;

  h4 {
    margin-top: 0;
  }

  .nav {
    flex: 0;
  }

  .form-group {
    .error {
      display: none;
    }
  }

  .form-group-error {
    .error {
      display: block;
    }
  }

  .promo {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .inner-block {
    flex: 1;
    padding: 75px 0 20px;
    width: 480px;
    margin: 0 auto;
    text-align: center;
    z-index: 0;
    @include flex-column;

    .interstitial {
      flex: 1;
      padding-top: 25px;

      h4 {
        margin-bottom: 70px;

        &.light-margin {
          margin-bottom: 30px;
        }
      }
    }

    .quiz {
      flex: 1;
    }

    .checkout {
      flex: 1;
      @include flex-column;

      .graph {
        max-width: 350px;
        margin: 0 auto;
        text-align: center;
      }
    }
  }

  .sticky-footer {
    .button-container {
      flex: 0;
      background-color: var(--white);
      box-shadow: 1px 0px 4px 0px rgba(181, 181, 181, 0.5);
      margin-top: 0;
      width: 100%;
      padding: 24px 20px;
      animation: slideUp 1.25s;

      .quiz-button {
        margin: 0 auto;
        width: 100%;
        max-width: 480px;
      }
    }
  }

  .workshop-checkout {
    h1,
    h2,
    h3,
    h4,
    h5 {
      @include merriweather;
      text-align: center;
    }

    p {
      font-size: 16px;
    }
  }

  &.has-promo {
    .inner-block {
      padding-top: 50px;
    }
  }

  .sticky-footer {
    position: sticky;
    bottom: 0;
    z-index: 1;
  }

  .graph {
    max-width: 350px;
    margin: 0 auto;
    text-align: center;
  }
}

@media only screen and (max-width: $breakpoint) {
  body {
    background: #f8f8f8;
  }

  .bordered-page {
    margin: 0;
    width: 100%;

    .inner-block {
      padding: 35px 20px 30px;
      width: 100%;
    }

    &.has-promo {
      .inner-block {
        padding-top: 25px;
      }
    }

    .interstitial,
    .checkout {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;

      h4 {
        margin-bottom: 35px;
      }

      .content {
        flex: 1;
      }
    }

    .quiz {
      padding-top: 35px;
    }

    .quiz-button {
      margin: 25px 0 0;
      flex: 0;
      max-width: 480px;
    }

    .graph {
      max-width: 100%;
    }
  }
}
</style>
