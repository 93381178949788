<script>
export default {
  name: "WorkshopButton",
  props: {
    loading: { type: Boolean, default: false },
    type: { type: String, default: "button" },
    disabled: { type: Boolean, default: false },
    solid: { types: Boolean, default: true },
    href: { type: String, default: null },
  },
  render: function (createElement) {
    let classes = {
      "workshop-button": true,
      "is-loading": this.loading,
      bordered: this.bordered,
      responsive: this.responsive,
      solid: this.solid,
    };
    classes = Object.keys(classes)
      .filter((key) => classes[key])
      .join(" ");

    return this.href
      ? createElement(
          "a",
          {
            class: classes,
            attrs: {
              disabled: this.loading || this.disabled,
              href: this.href,
            },
            on: {
              click: this.handleClick,
            },
          },
          this.$slots.default
        )
      : createElement(
          "button",
          {
            class: classes,
            attrs: {
              type: this.type,
              disabled: this.loading || this.disabled,
            },
            on: {
              click: this.handleClick,
            },
          },
          this.$slots.default
        );
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>

<style lang="scss">
@import "@/style/variables.scss";
@import "@/style/mixins.scss";

.workshop-button {
  @include noto;
  @include flex-row;
  justify-content: center;
  border: 2px solid var(--orange);
  background-color: var(--white);
  color: var(--orange);
  font-weight: 700;
  text-align: center;
  transition: background-color 0.3s;
  border-radius: 25px;
  font-size: 14px;
  line-height: 20px;
  flex-grow: 0;
  text-decoration: none;
  padding: 10px 0;

  &:hover {
    background-color: rgba(var(--orange-rgb), 0.05);
    text-decoration: none;
  }

  &.solid {
    background-color: var(--orange);
    border: none;
    color: var(--white);

    &:hover {
      background-color: rgba(var(--orange-rgb), 0.85);
    }
  }

  &[disabled] {
    opacity: 0.5;
  }

  &.is-loading {
    padding-right: 40px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 0px;
      height: 0px;
      border-radius: 50%;
      right: 20px;
      top: 50%;
      border: 2px solid rgba(255, 255, 255, 0.4);
      border-right: 2px solid #000;
      animation: rotate360 0.5s infinite linear, exist 0.1s forwards ease;
      z-index: 5;
    }

    @keyframes rotate360 {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes exist {
      100% {
        width: 12px;
        height: 12px;
        margin: -8px 5px 0 0;
      }
    }
  }
}
</style>
