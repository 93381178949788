<template>
  <div class="why-now-button">
    <button type="button" @click="toggleModal">Why now?</button>
    <why-now-modal v-if="modalOpen" @close="toggleModal" />
  </div>
</template>
<script>
import WhyNowModal from "@/components/WhyNowModal";

export default {
  name: "WhyNowButton",
  components: { WhyNowModal },
  data() {
    return {
      modalOpen: false,
    };
  },
  methods: {
    toggleModal() {
      this.modalOpen = !this.modalOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/variables.scss";
.why-now-button {
  display: flex;
}

button {
  border: none;
  background-color: transparent;
  color: var(--orange);
  font-weight: bold;
  font-size: 14px;
  padding: 0;
  justify-content: center;

  &:hover {
    text-decoration: underline;
  }
}
</style>
