<template>
  <div
    v-if="password"
    :class="{
      row: true,
      'form-group-error': password.$error,
      'form-group': true,
    }"
  >
    <input
      name="password"
      type="password"
      :placeholder="placeholder"
      v-model="localPassword"
      :disabled="disabled"
      :autocomplete="autocomplete"
    />
    <div v-if="!password.required" class="error">Enter your password</div>
    <div v-if="!password.minLength && password.$params.minLength" class="error">
      Your password must be at least
      {{ password.$params.minLength.min }} characters.
    </div>
  </div>
</template>
<script>
export default {
  name: "PasswordField",
  props: {
    password: { type: Object, default: () => {} },
    disabled: { type: Boolean, default: false },
    autocomplete: { type: String, default: "current-password" },
    placeholder: { type: String, default: "Password" },
  },
  computed: {
    localPassword: {
      get() {
        return this.password.$model;
      },
      set(val) {
        this.$emit("change", this.password, val);
      },
    },
  },
};
</script>
