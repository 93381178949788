<template>
  <section class="download-app-btns">
    <h5>Download the app</h5>
    <p>
      Check out more workshops plus hundreds of guided counseling sessions in
      the app.
    </p>
    <download-app-button :is-apple="true" text="Download on the App Store" />
    <download-app-button :is-apple="false" text="Get it on Google Play" />
  </section>
</template>

<script>
import DownloadAppButton from "@/components/DownloadAppButton";
export default {
  name: "DownloadAppButtons",
  components: { DownloadAppButton },
};
</script>
<style lang="scss" scoped>
@import "@/style/variables.scss";
.download-app-btns {
  h5 {
    margin-bottom: 0;
    font-size: 22px;
  }

  .link {
    border: 1.6px solid var(--light-gray);
    justify-content: flex-start;
    padding: 20px 25px;
    margin-top: 20px;
    height: 100px;

    a {
      display: block;
      margin-left: 30px;
    }

    .img-wrapper {
      width: 54px;
      text-align: center;
    }

    .img-wrapper,
    a {
      align-self: center;
    }
  }
}

@media only screen and (max-width: $breakpoint) {
  .download-app-btns {
    .link {
      margin-top: 15px;
      font-size: 14px;
    }
  }
}
</style>
