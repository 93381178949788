<template>
  <div class="security-badge">
    <img
      class="icon"
      src="@/assets/lock_check.svg"
      width="14"
      height="15"
      alt="Secured"
    />
    <span class="text">Secured with SSL</span>
  </div>
</template>

<script>
export default {
  name: "SecurityBadge",
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/mixins.scss";

.security-badge {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & > * {
    align-self: center;
  }

  .text {
    padding-left: 5px;
    white-space: nowrap;
  }
}
</style>
