import Vue from "vue";

const mixpanel = require("mixpanel-browser");

require("./facebook");

let defaultEventProps = { event_source: "Web" };

class Tracker {
  constructor() {
    // Hack for mocking Mixpanel in tests
    window.mixpanel = window.mixpanel || mixpanel;
    if (window.mixpanel)
      window.mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
        ignore_dnt: true,
        batch_requests: false,
      });
  }

  registerMixpanel(props) {
    if (window.mixpanel) window.mixpanel.register(props);
  }

  aliasMixpanel(id) {
    if (id) {
      if (window.mixpanel) window.mixpanel.alias(id);
    }
  }

  trackMixpanel(name, data = {}) {
    if (window.mixpanel)
      window.mixpanel.track(name, Object.assign({}, defaultEventProps, data));
  }

  identifyCustomerio(props = {}) {
    if (window._cio) {
      _cio.identify(props);
    }
  }

  trackCustomerio(name, data = {}) {
    if (window._cio) {
      _cio.track(name, Object.assign({}, defaultEventProps, data));
    }
  }

  identifySegment(id, props = {}) {
    if (window.analytics) {
      analytics.identify(id, props);
    }
  }

  trackSegment(name, data = {}) {
    if (window.analytics) {
      analytics.track(name, Object.assign({}, defaultEventProps, data));
    }
  }

  // Google conversion event: Viewed Checkout
  trackGoogleCheckoutConversion() {
    if (window.gtag) {
      gtag("event", "conversion", {
        send_to: "AW-741783421/sfh2CM3YhfMBEP3u2uEC",
      });
    }
  }

  // Google conversion: Subscription Trial Started
  trackGoogleTrialConversion() {
    if (window.gtag) {
      gtag("event", "conversion", {
        send_to: "AW-741783421/EbkACNecz6IBEP3u2uEC",
      });
    }
  }

  identify(id, email, createdAt) {
    let props = {};
    const createdAtDate = createdAt ? new Date(createdAt) : null;

    if (email && email.length) props = { email };
    if (id) {
      if (window.mixpanel) window.mixpanel.identify(id);
      this.identifyCustomerio(
        Object.assign(
          { id },
          props,
          createdAt
            ? { created_at: Math.floor(createdAtDate.getTime() / 1000) }
            : {}
        )
      );
      this.identifySegment(
        id,
        Object.assign(
          { email },
          createdAt ? { createdAt: createdAtDate.toISOString() } : {}
        )
      );
    } else if (email && email.length) {
      this.identifyCustomerio(
        Object.assign(
          { id: email },
          props,
          createdAt
            ? { created_at: Math.floor(createdAtDate.getTime() / 1000) }
            : {}
        )
      );
    }
  }

  trackEvent(event, props = {}) {
    this.trackMixpanel(event, props);
    this.trackSegment(event, props);
  }

  trackQuizLoaded() {
    this.trackEvent("Quiz Loaded");
  }

  trackEmptyStripeToken(props = {}) {
    const event = "Empty Stripe Token";
    this.trackSegment(event, props);
    this.trackMixpanel(event, props);
  }

  trackCheckoutError(props) {
    const event = "Checkout Error";
    this.trackEvent(event, props);
    this.trackCustomerio(event, props);
  }

  trackQuizAnswer(props) {
    this.trackEvent("Quiz Answer Selected", props);
  }

  trackSignUp() {
    this.trackEvent("Sign Up");
  }

  viewedPlanPage() {
    this.trackEvent("Viewed Plan Page");
  }

  viewedWorkshopCheckout(props = {}) {
    this.trackEvent("Checkout viewed", {
      ...props,
    });
    this.trackGoogleCheckoutConversion();
  }

  trackWorkshopNotFound(props = {}) {
    this.trackEvent("Workshop not found", {
      ...props,
    });
  }

  workshopTrialStarted(props = {}) {
    this.trackEvent("Trial Started", { ...props });
    this.trackGoogleTrialConversion();
  }

  viewedCheckout() {
    this.trackEvent("Viewed Checkout");
    this.trackGoogleCheckoutConversion();
  }

  trialStarted(props = {}) {
    this.trackEvent("Trial Started", props);
    this.trackGoogleTrialConversion();
  }

  trackLastQuestionSkiped() {
    this.trackEvent("Last Question Skipped");
  }

  trackRegistrationComplete() {
    if (window._tfa) {
      _tfa.push({
        notify: "event",
        name: "complete_registration",
        id: 1407936,
      });
    }
  }
}

const tracker = new Tracker();
tracker.install = function () {
  Object.defineProperty(Vue.prototype, "$tracker", {
    get() {
      return tracker;
    },
  });
};

export default tracker;
