const scrollToErroMixin = {
  updated() {
    if (this.error) {
      this.scrollToAlert();
    }
  },
  // Add ref of alertSection to alert element
  methods: {
    scrollToAlert() {
      this.$nextTick(() => {
        let el = null;
        if (this.error) {
          el = this.$refs.alertSection;
        }
        if (el && el.$el) {
          el.$el.scrollIntoView({ behavior: "smooth", alignToTop: true });
        }
      });
    },
  },
};

export default scrollToErroMixin;
