<template>
  <section
    v-if="promoOpen"
    :class="{
      'promo-code-banner': true,
      pink: true,
      'allow-close': allowClose,
    }"
  >
    <button v-if="allowClose" class="close" @click="togglePromo">
      <img
        src="../assets/close_x.svg"
        width="11"
        height="11"
        alt="Close promo"
      />
    </button>
    <h2>
      <!-- eslint-disable -->
      <span v-html="promoMessage" />
      <!-- eslint-enable -->
      &nbsp;<span class="code">LOVE</span>
    </h2>
  </section>
</template>
<script>
import { getPlan } from "@/plans";
export default {
  name: "PromoCodeBanner",
  components: {},
  data() {
    let promoOpen = true;
    let allowClose = false;
    const { savingsPercentage } = getPlan();
    let promoMessage = `Special Offer: <span class="light">Get ${savingsPercentage}% off + 7 days free with code</span>`;

    return {
      promoOpen,
      promoMessage,
      allowClose,
    };
  },
  methods: {
    togglePromo() {
      this.promoOpen = !this.promoOpen;
      if (!this.promoOpen) {
        this.$emit("hide");
      }
    },
  },
};
</script>

<style lang="scss">
@import "../style/variables.scss";
@import "../style/variables.scss";
.promo-code-banner {
  background-color: var(--light-pink);
  display: flex;
  flex-direction: column;
  padding: 16px;

  .close {
    border: none;
    background-color: transparent;
    text-align: right;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  h2 {
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    margin: 0 15px;

    .code {
      font-weight: bold;
      color: var(--green);
    }

    .light {
      font-weight: 400;
    }
  }

  &.allow-close {
    padding: 22px 24px;

    h2 {
      margin-top: 6px;
    }
  }
}

@media only screen and (max-width: $xxs-breakpoint) {
  .promo-code-banner {
    padding-bottom: 15px;

    .promo-code-banner {
      h2 {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
</style>
