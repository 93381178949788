<template>
  <button type="button" @click="click" class="use-credit-card-btn">
    Continue with credit card
  </button>
</template>

<script>
export default {
  name: "UseCreditCardButton",
  methods: {
    click(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
@import "@/style/mixins.scss";

.use-credit-card-btn {
  border: 2px solid var(--orange);
  background-color: var(--white);
  border-radius: 3px;
  color: var(--orange);
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  width: 100%;
  margin: var(--form-spacing) 0 0;
  padding: 13px;

  &:hover {
    background-color: rgba(var(--orange-rgb), 0.05);
  }
}
</style>
