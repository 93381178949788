import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import PortalVue from "portal-vue";

import user from "@/user";
import tracker from "@/tracker";
import * as store from "@/store";
import router from "@/routes/router";

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(PortalVue);

import BlankLayout from "@/layout/Blank";
import BorderedPageLayout from "@/layout/BorderedPage";
Vue.component("BlankLayout", BlankLayout);
Vue.component("BorderedPageLayout", BorderedPageLayout);

store.setQuestionFile(process.env.VUE_APP_QUESTION_FILE);

Vue.use(store);
Vue.use(tracker);
Vue.use(user);
store.setCacheName("quiz");

new Vue({
  router,
  data: {
    store,
  },
  render: (h) => h(App),
}).$mount("#app");
