import VueRouter from "vue-router";

import user from "@/user";
import * as store from "@/store";
import { ROUTES as QUIZ_ROUTES, TEST_REDIRECT_FN } from "@/routes/quiz-routes";

let initialLoad = true;

const router = new VueRouter({
  props: { store: { type: Object, default: () => {} } },
  routes: [
    { path: "*", redirect: "/quiz" },
    {
      path: "/checkout",
      redirect: "/checkout/plan",
      name: "checkout",
      component: () => import("@/pages/FadingRouterView.vue"),
      meta: {
        quiz: true,
      },
      children: [
        {
          path: "plan",
          name: "checkoutPlan",
          meta: {
            nextRoute: { name: "checkoutPayment" },
            checkoutPrereq: ["noPayment"],
            hideBack: true,
            useInnerBlock: false,
            showPromoBanner: true,
          },
          component: () => import("@/pages/checkout/PlanView.vue"),
        },
        {
          path: "payment",
          name: "checkoutPayment",
          meta: {
            nextRoute: { name: "checkoutAccount" },
            checkoutPrereq: ["noPayment", "noExistingLogin"],
            hideBack: true,
            useInnerBlock: false,
          },
          component: () => import("@/pages/PaymentView.vue"),
        },
        {
          path: "user-payment",
          name: "checkoutUserPayment",
          meta: {
            nextRoute: { name: "checkoutInvitePartner" },
            checkoutPrereq: ["noPayment", "existingLogin"],
            hideBack: true,
            useInnerBlock: false,
          },
          component: () => import("@/pages/UserPaymentView.vue"),
        },
        {
          path: "account",
          name: "checkoutAccount",
          meta: {
            nextRoute: { name: "checkoutInvitePartner" },
            checkoutPrereq: ["payment", "loggedInAndPaid", "noExistingLogin"],
            hideBack: true,
          },
          props: () => {
            return {
              saveAnswers: false,
            };
          },
          component: () => import("@/pages/AccountView.vue"),
        },
        {
          path: "invite",
          name: "checkoutInvitePartner",
          meta: {
            nextRoute: { name: "checkoutBookWorkshop" },
            checkoutPrereq: ["payment", "loggedInAndPaid", "partnerNotInvited"],
            hideBack: true,
          },
          component: () => import("@/pages/InvitePartnerView.vue"),
        },
        {
          path: "workshops",
          name: "checkoutBookWorkshop",
          meta: {
            nextRoute: { name: "checkoutComplete" },
            checkoutPrereq: ["payment", "loggedInAndPaid"],
            hideBack: true,
          },
          component: () => import("@/pages/WorkshopsView.vue"),
        },
        {
          path: "complete",
          name: "checkoutComplete",
          meta: {
            hideBack: true,
          },
          component: () => import("@/pages/CompleteView.vue"),
        },
      ],
    },
    {
      path: "/sale-checkout*",
      redirect: "/checkout",
      name: "saleCheckout",
    },
  ].concat(QUIZ_ROUTES),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  let hasNavigated = false;
  if (initialLoad) {
    const currentEmail =
      store.state.meta.existingLogin || store.state.meta.email;
    if (to.query.login) {
      const newLogin = decodeURIComponent(to.query.login);
      if (newLogin != currentEmail) {
        store.clearData();
        store.setMeta({
          existingLogin: newLogin,
          email: null,
        });
      }
    } else if (to.query.email) {
      const newEmail = decodeURIComponent(to.query.email);
      if (newEmail !== currentEmail) {
        store.clearData();
        store.setMeta({
          existingLogin: null,
          email: newEmail,
        });
      }
    }
    initialLoad = false;
  }

  hasNavigated = TEST_REDIRECT_FN(to, from, next);

  if (!hasNavigated && to.meta.checkoutPrereq) {
    let done;
    for (const req of to.meta.checkoutPrereq) {
      switch (req) {
        case "noPayment":
          done = !store.state.meta.paymentComplete;
          if (!done) {
            hasNavigated = true;
            next({ name: "checkoutAccount" });
          }
          break;
        case "payment":
          done = store.state.meta.paymentComplete;
          if (!done) {
            hasNavigated = true;
            next({ name: "checkoutPayment" });
          }
          break;
        case "noExistingLogin":
          done = store.state.meta.existingLogin;
          if (done) {
            hasNavigated = true;
            if (!store.state.meta.paymentComplete) {
              next({ name: "checkoutUserPayment" });
            } else {
              next({ name: "checkoutInvitePartner" });
            }
          }
          break;
        case "existingLogin":
          done = store.state.meta.existingLogin;
          if (!done) {
            hasNavigated = true;
            next({ name: "checkoutPayment" });
          }
          break;
        case "loggedInAndPaid":
          done = user.isLoggedIn();
          if (!done) {
            store.state.meta.paymentComplete = false;
            hasNavigated = true;
            next({
              name: "checkoutPayment",
            });
          }
          break;
        case "partnerNotInvited":
          if (user.isLoggedIn() && !user.user) {
            hasNavigated = true;
            user.get().then(() => {
              done = !user.canInvitePartner();
              if (done) {
                next({ name: "checkoutBookWorkshop" });
              } else {
                next();
              }
            });
          } else {
            done = !user.canInvitePartner();
            if (done) {
              hasNavigated = true;
              next({ name: "checkoutBookWorkshop" });
            }
          }
          break;
      }
    }
  }
  if (!hasNavigated) {
    next();
  }
});

export default router;
