import dayjs from "dayjs";

export const EXISTING_ACCOUNT_ERROR =
  "That account already exists. Please log in with the correct password.";

export const GENERIC_FORM_ERROR =
  "There was a problem with your submission. Please correct the errors and try again.";

const submitPaymentMixin = {
  data() {
    return {
      error: null,
      isLoading: false,
      stripeKey: process.env.VUE_APP_STRIPE_KEY,
      email: null,
      password: null,
      creditCard: {
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
      },
      usingBrowserPay: false,
      browserPaySupported: false,
      browserPayLoading: true,
      browserPayEvent: null,
      creditCardChosen: false,
      requirePassword: false,
      stripeToken: null,
    };
  },
  computed: {
    showCreditCard() {
      return (
        !this.browserPayLoading &&
        (!this.browserPaySupported || this.creditCardChosen)
      );
    },
    showCreditCardHeader() {
      return !this.browserPaySupported;
    },
    showCreditCardToggle() {
      return (
        !this.browserPayLoading &&
        this.browserPaySupported &&
        !this.creditCardChosen
      );
    },
    showCreditCardOptionHeader() {
      return this.browserPaySupported && this.creditCardChosen;
    },
    showBrowserPay() {
      return this.browserPayLoading || this.browserPaySupported;
    },
    trialExpiration() {
      return dayjs().add(7, "day").format("MMMM D, YYYY");
    },
  },
  methods: {
    // Use when a field updates when directly using a v-model
    onFieldChange(field) {
      this.error = null;
      if (field) {
        field.$touch();
      }
    },
    // Use when a field updates when updating outside of a v-model
    modelFieldUpdated(field, val) {
      field.$model = val;
      this.onFieldChange(field);
    },
    onCardChange() {
      // field.$model = event;
      this.error = null;
    },
    onCardBlur() {
      // field.$touch();
    },
    onPaymentLoaded(payments) {
      this.browserPayLoading = false;
      this.browserPaySupported = !!(
        payments &&
        (payments.applePay || payments.googlePay)
      );
    },
    toggleCreditCard() {
      this.creditCardChosen = true;
      this.usingBrowserPay = false;
    },
    getStripeErrorMessage(model) {
      return (model && model.error && model.error.message) || null;
    },
    generatePassword(len = 6) {
      var p =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*";
      return [...Array(len)].reduce(
        (a) => a + p[~~(Math.random() * p.length)],
        ""
      );
    },
    navigateToNextPage() {
      this.goToNextRoute();
      return Promise.resolve();
    },
    onStripeTokenSet(event, location) {
      if (
        !this.stripeToken ||
        !this.stripeToken.id ||
        !this.stripeToken.id.length
      ) {
        this.$tracker.trackEmptyStripeToken({
          token: this.stripeToken,
          eventToken: event.token,
          paymentType: this.usingBrowserPay ? "browser pay" : "card",
          location,
          existingAccount: this.requirePassword,
        });
      }
    },
    accountCreated() {
      this.$tracker.trackSignUp();
      this.$store.setMeta({
        email: this.email,
        passwordSet: this.password && this.password.length,
      });
      return Promise.resolve();
    },
    createAccount() {
      // If the account creation already succeeded for this email, skip the account creation step.
      const isLoggedInAsEmail =
        this.$user.user &&
        this.$user.user.email &&
        this.$user.user.email === this.email;
      if (!this.$user.isLoggedIn() || !isLoggedInAsEmail) {
        return this.$user.createAccount(
          this.email,
          this.password || this.generatePassword()
        );
      }
      return Promise.resolve();
    },
    logIn() {
      return new Promise((resolve, reject) => {
        this.$user
          .logIn(this.email, this.password)
          .then(() => {
            this.$store.setMeta({ email: this.email });
            resolve();
          })
          .catch((err) => {
            if (err && err.response && err.response.status === 404) {
              reject({
                request: err.request,
                response: {
                  ...err.response,
                  data: {
                    errors: ["Incorrect email or password. Please try again."],
                  },
                },
              });
            } else {
              reject({
                request: err.request,
                response: {
                  ...err.response,
                  data: {
                    errors: [
                      "There was a problem logging you in. Please try again.",
                    ],
                  },
                },
              });
            }
          });
      });
    },
    subscribeToPlan() {
      return this.$user.subscribe(this.stripeToken, this.plan.id);
    },
    onStripeTokenCreated(data) {
      if (data.error) {
        throw data.error;
      }
      this.stripeToken = data.token;
      this.onStripeTokenSet(data, "submit Payment: onStripeTokenCreated");
      return Promise.resolve();
    },

    handleStripeError(err) {
      this.$tracker.trackCheckoutError({
        Source: "Stripe",
        Type: err.type,
        Code: err.code,
        Message: err.message,
      });
      return err.message;
    },
    onComplete() {
      return Promise.resolve();
    },
    startNewUserPayment() {
      if (!this.usingBrowserPay) {
        return this.createAccount()
          .then(this.accountCreated)
          .then(this.createToken)
          .then(this.onStripeTokenCreated)
          .then(this.subscribeToPlan)
          .then(this.subscriptionComplete)
          .then(this.onComplete)
          .catch(this.handleError);
      } else {
        return this.createAccount()
          .then(this.accountCreated)
          .then(this.subscribeToPlan)
          .then(this.subscriptionComplete)
          .then(this.onComplete)
          .catch(this.handleError);
      }
    },
    startExistingUserPayment() {
      if (!this.usingBrowserPay) {
        return this.logIn()
          .then(this.createToken)
          .then(this.onStripeTokenCreated)
          .then(this.subscribeToPlan)
          .then(this.subscriptionComplete)
          .then(this.submitAnswers)
          .then(this.onComplete)
          .catch(this.handleError);
      } else {
        return this.logIn()
          .then(this.subscribeToPlan)
          .then(this.subscriptionComplete)
          .then(this.submitAnswers)
          .then(this.onComplete)
          .catch(this.handleError);
      }
    },
    validateForm(paymentFn) {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.error = GENERIC_FORM_ERROR;
        return;
      }
      this.$store.setMeta({ email: this.email });
      this.usingBrowserPay = false;
      this.isLoading = true;
      this.error = null;
      paymentFn();
    },
    createToken() {
      return this.$refs.creditCardFields.$refs.elms.instance.createToken(
        this.$refs.creditCardFields.$refs.card.stripeElement
      );
    },
    subscriptionComplete() {
      let paymentMethod = "Credit Card";

      if (this.browserPayEvent) {
        this.browserPayEvent.complete("success");
        paymentMethod = this.browserPayEvent.methodName.includes("apple")
          ? "Apple Pay"
          : "Google Pay";
        this.browserPayEvent = null;
      }
      this.$tracker.trialStarted({ "Payment Method": paymentMethod });
      this.$store.setMeta({ paymentComplete: true });
      this.$user.markWebTrialStarted();
      return Promise.resolve();
    },
    submitAnswers() {
      const answers = this.$store.batchAnswers();
      if (answers && answers.length) {
        return this.$user.batchSubmit(answers);
      }
      return Promise.resolve();
    },
    onExistingAccountError() {
      return;
    },
    handleAPIError(err) {
      let response = err.response;
      let errors =
        response && response.data && response.data.errors
          ? response.data.errors
          : [];
      let errorMessage =
        "An error occurred submitting your payment. Please try again.";
      let trackingProps = { Message: "Unknown error" };

      if (errors.length) {
        if (
          JSON.stringify(errors[0]) ===
          JSON.stringify({
            source: { pointer: "/data/attributes/email" },
            detail: "has already been taken",
          })
        ) {
          trackingProps = {
            Status: response.status,
            Message: "Account already exists for email",
          };
          errorMessage = EXISTING_ACCOUNT_ERROR;
          this.requirePassword = true;
          this.onExistingAccountError();
        } else {
          const translatedErrMsg =
            typeof errors[0] === "object"
              ? JSON.stringify(errors[0])
              : errors[0];
          trackingProps = {
            Status: response.status,
            Message: translatedErrMsg,
          };
          errorMessage = translatedErrMsg;
        }
      } else {
        let jsonErr = {};
        try {
          jsonErr = err.toJSON();
          // eslint-disable-next-line no-empty
        } catch (e) {}
        if (jsonErr.message) {
          trackingProps = {
            Message: jsonErr.message,
          };
        }
      }

      if (response && response.status) {
        trackingProps.Status = response.status;
      }

      if (err && err.request && err.request.responseURL) {
        trackingProps.Url = err.request.responseURL;
      }

      this.$tracker.trackCheckoutError({
        Source: "API",
        ...trackingProps,
      });
      return errorMessage;
    },
    handleError(err) {
      this.isLoading = false;
      if (this.browserPayEvent) {
        this.browserPayEvent.complete("fail");
        this.browserPayEvent = null;
      }
      this.error =
        err && err.request
          ? this.handleAPIError(err)
          : this.handleStripeError(err);
    },
  },
};

export default submitPaymentMixin;
