<template>
  <section class="magic-link checkout">
    <div class="content">
      <loading-spinner v-if="!pageLoaded" />
      <template v-if="pageLoaded">
        <h4>Whooooooosh!!!!</h4>
        <img
          srcset="
            @/assets/envelope_magic_link.png,
            @/assets/envelope_magic_link@2x.png 2x
          "
          src="/assets/envelope_magic_link@2x.png"
          alt="Magic link sent"
        />
        <p>
          To make your life easy, we've emailed you a magic link to log in to
          the app.
        </p>

        <p class="b">The link will be active for 15 mins</p>
        <alert-section v-if="error" class="row alert">{{
          error
        }}</alert-section>
        <quiz-button
          v-if="!allowRetry"
          :solid="true"
          @click="goToNextRoute"
          class="next"
          >Next</quiz-button
        >
        <quiz-button
          v-if="allowRetry"
          @click="sendMagicLink"
          :loading="isLoading"
          >Send Magic Link</quiz-button
        >
      </template>
    </div>
  </section>
</template>

<script>
import QuizButton from "@/components/QuizButton";
import LoadingSpinner from "@/components/LoadingSpinner";
import AlertSection from "@/components/AlertSection";
import nextRouteMixin from "@/mixins/nextRoute";

export default {
  name: "MagicLinkView",
  mixins: [nextRouteMixin],
  components: { QuizButton, LoadingSpinner, AlertSection },
  data: function () {
    return {
      isLoading: false,
      pageLoaded: false,
      allowRetry: false,
      error: null,
    };
  },
  methods: {
    sendMagicLink() {
      this.isLoading = true;
      this.error = null;
      this.$user
        .sendMagicLink(this.$user.user.email)
        .then((response) => {
          if (response.data.sent) {
            if (this.allowRetry) {
              this.goToNextRoute();
            }
            this.allowRetry = false;
          } else {
            this.allowRetry = true;
          }
        })
        .catch(() => {
          this.allowRetry = true;
          this.error =
            "Something went wrong sending your magic link. Please try again.";
        })
        .finally(() => {
          this.isLoading = false;
          this.pageLoaded = true;
        });
    },
  },
  mounted() {
    this.isLoading = true;
    this.sendMagicLink();
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/mixins.scss";
@import "@/style/variables.scss";

.magic-link {
  .content {
    text-align: left;
    @include flex-column;
  }

  img {
    align-self: center;
    width: 275px;
    max-width: 100%;
  }

  p {
    line-height: 25px;
    margin-bottom: 15px;
  }

  .next {
    margin-top: 0;
    align-self: center;
  }
}

@media only screen and (max-width: $breakpoint) {
  .magic-link {
    .content {
      padding-top: 25px;
    }

    h4 {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 21px;
    }
  }
}
</style>
